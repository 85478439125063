.week-dispatch-page
  width: 1024px
  margin: 0 auto

.create-task-dispatch-modal
  justify-content: center
  align-items: center
  
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 4

  display: none

  background-color: rgba(242, 242, 242, 0.8)

.create-dispatch-task-modal__content
  width: 715px
  height: 630px
  padding: 15px

  background-color: #ffffff
  border-radius: 30px
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15)

.create-dispatch-task-modal__close-btn
  cursor: pointer
  display: inline-block

.week-dispatch-email__tasks-table
  border-collapse: separate
  border-spacing: 20px

.week-dispatch-email__tasks-table-td
  margin: 30px

.week-dispatch-email__template-id-wrapper
  display: flex
  flex-direction: column
  justify-content: center
  width: 300px
  margin: 10px

.week-dispatch-email__send-at-wrapper
  margin-top: 20px

.week-dispatch-email__create-task-btn
  margin-top: 10px

.week-dispatch-messenger__tasks-table
  border-collapse: separate
  border-spacing: 0 30px

.week-dispatch-email__campaign-table
  border-collapse: separate
  border-spacing: 0 30px
