.step_wrap
  display: flex
  justify-content: flex-end
  border-bottom: 1px solid #e4e4e4
  margin-top: -1px

  & .tab:first-child
    position: relative
    left: -4px

.form-rec-marg
  margin-left: -1px

.model_data_wrap.model_pair
  & .login_margin #login-form
    margin-top: -8px

.login_margin #login-form
  margin-top: -4px

.firsttab
  kv-file-content
    width: auto

.firsttab
  & .form-horizontal
    padding-top: 14px

.model_data_wrap
  display: flex
  justify-content: space-between

  .form-group
    .form-control.error
      border-bottom: 2px solid #db046b

      & + label
        color: #db046b

    & .help-block-error
      color: #db046b
      font-size: 13px
      font-family: PFDinTextPro, sans-serif
      font-weight: 400
      margin-top: -22px
      position: relative

    & .col-lg-12:first-child
      height: 57px

.name_second_model, .name_first_model
  color: #30173d
  font-size: 16px
  font-family: 'Montserrat', sans-serif
  font-weight: 600
  margin-bottom: 9px
  padding-left: 3px
  display: none

.firsttab .form-group
  top: 18px

.help-block
  margin-bottom: 5px

.checkbox_wrap
  display: flex
  margin-bottom: 20px
  justify-content: start
  flex-wrap: wrap
  position: relative

.checkbox-wrap__marg
  margin-top: 4px
  top: 13px
  height: 68px

.checkbox-title
  width: 100%
  display: block
  font-family: 'PFDinTextPro', sans-serif
  font-weight: 400
  color: rgba(47, 47, 47, 0.5)
  font-size: 13px
  padding-left: 3px

.single_box, .single_box-second-model, .single_box-pair
  width: 167px
  margin-top: 0
  margin-left: 4px

  &-span
    color: #30173d
    font-size: 16px
    font-family: 'PFDinTextPro', sans-serif
    font-weight: 400
    position: relative
    left: 3px
    cursor: pointer

  &:last-child
    width: 100px

.single_box-second-model
  width: 108px

.firsttab-text
  font-size: 14px
  color: #30173d
  font-family: 'Montserrat', sans-serif
  font-weight: 400
  margin-top: 13px
  padding-left: 4px
  line-height: 18px
  padding-right: 5px
  margin-bottom: 25px

.is_pair label, .manager-main__period-interval label
  margin-left: 8px
  width: 50px
  margin-top: 25px
  color: #30173d
  font-size: 16px
  font-family: 'PFDinTextPro', sans-serif
  font-weight: 400

.field-firsttabform-how_know
  position: relative !important
  top: 20px !important

  label
    position: relative
    top: -46px
    font-size: 13px

.field-firsttabform-city
  position: relative !important
  top: 8px !important

.firsttab-text_mar
  margin-bottom: 14px

.model_data_wrap.model_pair
  .name_second_model, .name_first_model
    display: block !important

  .checkbox_wrap
    top: 12px
    margin-bottom: 23px
    margin-top: 57px

  .checkbox_pair
    top: -160px
    left: 1px

  .single_box
    width: 108px

.second_model_data_wrap
  width: 100%
  margin-left: 6%

.firsttab-btn-next
  margin-bottom: 20px !important
  margin-top: -6px !important
  width: 475px

.field-firsttabform-first_model_born_data
  top: 3px

.name_second_model, .name_first_model
  color: #30173d
  font-size: 16px
  font-family: 'Montserrat', sans-serif
  font-weight: 600
  margin-bottom: 9px
  padding-left: 3px
  display: none

.model_data_wrap.model_pair
  .login_margin #login-form
    margin-top: -8px

  .name_second_model, .name_first_model
    display: block !important

  .checkbox_wrap
    top: 12px
    margin-bottom: 23px
    margin-top: 57px

  .checkbox_pair
    top: -160px
    left: 1px

  .single_box
    width: 108px


.modal-box
  max-height: 90vh
  overflow-y: auto


.select2.select2-container
  margin-bottom: 20px
  .select2-selection.select2-selection--single
    border: none
    border-bottom: 1px solid #000
    box-shadow: none
    border-radius: 0
    padding-left: 0
  .select2-selection__rendered
    color: #30173d
  .select2-selection__arrow
    border-left: none

.select2-container--open
  .select2-dropdown
    margin-top: 4px
    border: none
    border-radius: 0
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    .select2-search
      margin: 18px 10px
      & input
        border: none
        box-shadow: none
        border-radius: 0
        border-bottom: 1px solid black
    .select2-results
      .select2-results__option
        color: #30173d
      .select2-results__option--highlighted
        background-color: #e4e4e4

@supports (-ms-ime-align: auto)
  .model_data_wrap .checkbox-wrap__marg
    margin-top: 20px
    top: 6px
  .payment_block .title
    margin-bottom: 49px !important
  .outline-card + label
    background: #fff
    position: relative
  .payment_pad
    margin-bottom: 34px !important
