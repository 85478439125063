.public_offer_wrap
  width: auto!important
  flex-wrap: wrap
  column-gap: 8px
.field-loginform-rememberme label
  color: #30173d
  font-family: 'PFDinTextPro'
  font-weight: 400
.rememberMe_wrap
  display: -webkit-flex
  display: -moz-flex
  display: -ms-flex
  display: -o-flex
  display: flex
  justify-content: space-between
  margin-top: 9px
  .form-group
    height: auto!important
.login-button
  width: 223px
  margin-top: 25px
.sattelit-button
  width: 190px
  border-radius: 50px
  height: 50px
  margin-top: 40px
.go_register_button_wrap
  margin-left: 30px
.field-loginform-rememberme
  margin: 0!important
  .rememberMe
    padding: 0
    column-gap: 4px
    width: auto!important
    #loginform-rememberme-styler
      margin: 0!important
      top: 0
    label
      position: static
      margin: 0!important

.login-form
  .form-group
    height: auto!important
