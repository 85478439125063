.popup-help
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
  width: 263px
  height: auto
  border-radius: 5px
  background: #fff
  display: none
  position: absolute
  top: 0
  z-index: 14
  left: -265px
  padding: 16px
  font-family: Montserrat
  font-size: 14px
  color: #30173d
  font-weight: 400
  text-align: left
  &__img
    left: -348px
    width: 348px
    & img:first-child
      margin-right: 2.8%
    & img
      width: 48%
      display: inline-block
  & table
    & td
      padding-right: 13px
      padding-bottom: 8px


.main-page__webinar .popup-help__img
     left: 60px
