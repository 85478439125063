/* .help-information
  width: 1160px
  height: 732px
  border-radius: 5px
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
  border-radius: 5px
  margin: 30px auto 68px
  display: flex
  position: relative
  &__ol
    padding-left: 18px
  &__help-nav
    width: 22.672%
    background-color: rgba(228, 228, 228, 0.24)
    height: auto
    border-right: 1px solid #e4e4e4
  &__help-menu
    list-style-type: none
    padding: 0
    padding-top: 5px
  &__help-item
    height: 50px
    padding-left: 21px
    line-height: 50px
    font-family: 'Montserrat'
    font-size: 16px
    font-weight: 600
    color: #30173d
    cursor: pointer
    &:hover
      background: #ffffff
  &__help-item.active
    background-color: #fff
    padding-left: 19px
    border-left: 2px solid #db046b
    color: #db046b
    &:hover
      color: rgba(219, 4, 107, 0.7)
  &__inner
    padding: 48px 56px 56px
    width: 77.328%
    overflow: auto
    box-sizing: border-box
  &__title
    font-family: 'Montserrat'
    font-size: 36px
    font-weight: 700
    color: #30173d
    margin: 0
  &__top-wrap
    display: flex
    justify-content: space-between
    align-items: flex-end
    padding-bottom: 56px
  &__button
    height: 36px
    width: 151px
    line-height: 36px
    font-family: 'PFDinTextPro'
    font-weight: 600
    font-size: 14px
    letter-spacing: 1px
    text-align: center
    color: #db046b
    border: solid 1px #e4e4e4
    border-radius: 5px
    position: relative
    top: -6px
  &__content
    font-family: 'Montserrat'
    font-weight: 500
    font-size: 16px
    line-height: 24px
    font-color: rgba(48, 23, 61, 0.8)
  &__ol-item
    padding-bottom: 20px
    color: #30173d
    padding-left: 20px
    font-style: normal
    font-stretch: normal
  &__fill-content
    display: none
  &__table-fix
    position: absolute
    height: 56px
    width: 70%
    background: #fff
    z-index: 1
    bottom: 0
  &__table
    border: 1px solid #e4e4e4
    border-radius: 5px
    -webkit-border-radius: 5px
    -moz-border-radius: 5px
    -khtml-border-radius: 5px
    width: 100%
    display: block
    margin-bottom: 56px
    th
      background-color: rgba(228, 228, 228, 0.24)
      text-align: center
      height: 70px
      font-family: 'Montserrat'
      font-weight: 600
      color: #30173d
    td
      padding: 16px 25px
      text-align: center
      line-height: normal
      font-family: 'Montserrat'
      font-size: 14px
      color: #30173d
      vertical-align: top
      border-top: 1px solid #e4e4e4
      border-bottom: 1px solid #e4e4e4
  &__table-num
    width: 62px
  &__table-desk
    width: 596px
    text-align: left!important
    padding-left: 25px
  &__table-sum
    width: 107px
  &__td-center
    text-align: left !important
  &__drop-down-block
      border-radius: 5px
      border: solid 1px #e4e4e4
      margin: 16px 0
      padding: 15px 21px 16px
      font-family: 'Montserrat'
      font-size: 16px
      font-weight: 600
      color: #30173d
      svg g
        fill: #db046b
  &__drop-down-top
    display: flex
    justify-content: space-between
    cursor: pointer
  &__drop-down-text
    padding-top: 15px
    font-family: 'Montserrat'
    font-weight: 400
    font-size: 14px
    line-height: normal
    display: none
  &__increase-article
    display: flex
    justify-content: space-between
    padding-bottom: 48px
  &__increase-article-img
    width: 304px
    height: 245px
    background-color: #e4e4e4
  &__increase-article-text
    width: 431px
    color: rgba(48, 23, 61, 0.8)
    font-family: 'Montserrat'
    font-size: 16px
    font-weight: 500
    line-height: 1.5
  &__increase-article-text.stretch
    width: 100% !important
  &__secondary-title
      font-family: 'Montserrat'
      font-size: 24px
      font-weight: 600
      padding-bottom: 24px
  &__graph-wrap
    display: flex
    justify-content: flex-end
    position: absolute
    top: 0
    right: -30px
    display: none
    .legend
      position: relative
      width: 151.7px
      top: 0
  &__graph
    width: 380px
    height: 227px
    display: flex
    canvas
      margin: 0 !important
  &__pay-line-text
    color: rgba(48, 23, 61, 0.8)
    width: 333px
    font-family: 'Montserrat'
    font-size: 16px
    font-weight: 500
    line-height: 1.5
    padding-bottom: 48px
  &__pay-line-text.stretch
    width: 100% */

.help-information
  &__tabs-main
    border-radius: 5px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    background-color: #ffffff
    margin-top: 33px
    margin-bottom: 68px
    display: flex
  &__tabs
    overflow: auto
    width: 100%
  &__help-menu
    border-radius: 5px
    background-color: rgba(228, 228, 228, 0.24)
    border-right: 1px solid #e4e4e4
    width: 263px
    position: relative

    & ul
      display: flex
      flex-direction: column
      border-bottom: none
      width: 263px
      li
        padding: 4px 5px
        border-left: 2px solid transparent
        transition: 0.3s
        margin-right: 2px
        &:hover
          background: #ffffff
          border-left: 2px solid #db046b
          transition: 0.3s
          & a
            color: #db046b
            transition: 0.3s
            background: #fff
        &.ui-tabs-active
          background: #ffffff
          border-left: 2px solid #db046b
          transition: 0.3s
          & a
            color: #db046b
            transition: 0.3s
            background: #fff
      a
        border: none
        color: #30173d
        font-family: Montserrat
        font-size: 16px
        font-weight: 600
        transition: 0.3s
  &__fill-content
    padding: 45px 40px 48px 55px
    font-family: Montserrat
    font-size: 16px
    font-weight: 500
    color: rgba(48, 23, 61, 0.8)
    &-title
      font-family: Montserrat
      font-size: 36px
      font-weight: 700
      color: #30173d
      margin: 0 0 56px
    &-subtitle
      color: rgba(48, 23, 61, 0.8)
      font-family: Montserrat
      font-size: 24px
      font-weight: 600
    &-text
      margin-bottom: 48px
      margin-top: 24px
    strong
      display: flex
      flex-direction: column
    span
      display: flex
      flex-direction: column
    img
      max-width: 600px!important
      width: 100%!important
      height: auto!important
    video
      width: 100%!important
      height: auto!important
.block-with-dropdown
  border: 1px solid #e4e4e4
  border-radius: 5px
  padding: 15px 21px 5px
  position: relative

.block-with-dropdown h3:after 
  content: ''
  position: absolute
  width: 24px
  height: 24px
  background: url(../img/arrow-down-pink.svg)
  right: -32px
  top: 0
  cursor: pointer
  transform: rotate(0deg)

.block-with-dropdown div
  display: none

.block-with-dropdown h3
  text-align: left!important
  padding: 0 0 15px
  font-size: 16px !important
  position: relative
  width: calc(100% - 40px)
.table-style tbody tr td:first-child
  vertical-align: inherit !important
.table-style tbody tr td:last-child
  vertical-align: inherit !important
.close-mnu-mobile
  width: 25px
  height: 25px
  position: absolute
  right: 5px
.show_all_punkts
  padding-right: 42px
  color: white
  width: 200px
  background-color: #db046b
  font-family: PFDinTextPro
  border: none
  line-height: 30px
  font-size: 18px
  background-position-x: 167px
  background-position-y: 2px
  background-size: 30px
  background-repeat: no-repeat
  background-image: url(../img/button.png)
.mobile-menu-punkts
  position: fixed
  top: 0
  z-index: 16
  background-color: #e4e4e4
  height: 100vh
  overflow: auto

.help-information__model-study
    position: static!important
    bottom: 20px
    left: 0px
    font-family: Montserrat
    color: #30173d
    font-size: 16px
    font-weight: 500
    text-align: center
    outline: none
    cursor: default
    border: none!important
    padding: 20px!important
    &:hover
      border: none!important

    a
      color: #db046b!important
      font-size: 14px!important
      font-family: PFDinTextPro!important
      font-weight: 500!important
      width: 222px
      margin: 25px auto 0!important
      border: 1px solid #e4e4e4!important
      padding: 7px 15px 10px!important
      border-radius: 5px!important
      letter-spacing: 1px

    &:hover
      background: transparent!important
      border-left: 2px solid transparent!important

    & a:hover
      background: rgba(118, 66, 255, 0.06)!important
      color: #db046b

    &.ui-tabs-active
      background: transparent!important
      border-left: 2px solid transparent!important

      &:hover
        & a
          background: rgba(118, 66, 255, 0.06)!important
          color: #db046b
