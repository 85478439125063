.step_wrap
  display: flex
  justify-content: flex-end
  border-bottom: 1px solid #e4e4e4
  margin-top: -1px
  & .tab:first-child
    position: relative
    left: -4px

.second-step
  &__contact-title
    letter-spacing: 0.5px
    margin-top: -1px
    margin-bottom: 10px
  &__payment-system
    display: flex
    flex-wrap: wrap
    & img
      width: 223px
      height: 138px
      margin-left: -1px
      border-radius: 5px
      border: 2px solid transparent
      margin-top: -1px
      &:hover
        border: 2px solid rgba(219, 4, 107, 1)
    label
      padding: 0
      &:hover
        border: 1px solid rgba(219, 4, 107, 1)
    & .outline-card.checked+label
      border: 1px solid rgba(219, 4, 107, 1)
    & .active-pay
      border: 2px solid rgba(219, 4, 107, 1)!important
  &__back
    margin-top: 8px
    width: 210px
.messenger_wrap
  margin-top: 28px
  & .title
    width: 100%
    padding-left: 2px
    margin-bottom: -9px
  &__marg
    margin-bottom: 14px
    margin-top: 19px
#secondtabform-skype, #secondtabform-telegram, #secondtabform-whatsapp, #secondtabform-viber
  padding-left: 25px
  padding-top: 5px
  width: 442px

#secondtabform-skype
  background: url("../img/skype.png")
  background-repeat: no-repeat
  background-position: 0px 7px

#secondtabform-telegram
  background: url("../img/telegram.png")
  background-repeat: no-repeat
  background-position: 0px 7px

#secondtabform-whatsapp
  background: url("../img/whatsApp.png")
  background-repeat: no-repeat
  background-position: 0px 7px

#secondtabform-viber
  background: url("../img/viber.png")
  background-repeat: no-repeat
  background-position: 0px 7px

#telegram, #viber, #whatsapp, #skype
  display: none
  position: relative

#telegram.active, #viber.active, #whatsapp.active, #skype.active
  display: block

#skype
  margin-top: -9px

.payment_block
  .title
    margin-bottom: 17px
.select_messenger
  border-bottom: 1px solid #2f2f2f
  color: #30173d
  font-family: 'PFDinTextPro'
  font-weight: 400
  font-size: 16px
  height: 34px
  display: flex
  align-items: center
  cursor: pointer
  justify-content: space-between
  margin-left: 3px
  margin-top: 5px
  width: 442px

.select_messenger
  &.active
    border-bottom: 2px solid #db046b
    & .select_messenger-svg
      transform: rotate(180deg)
  & .select_messenger-svg:hover g, &.active .select_messenger-svg g
    fill: #2f2f2f
.dropdown_messenger
  background: #fff
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
  color: #30173d
  font-size: 16px
  font-family: 'PFDinTextPro'
  font-weight: 400
  z-index: 6
  width: 445px
  &__ul
    list-style: none
    padding-left: 0
  &__li
    padding: 6px 10px
    cursor: pointer
    transition: 0.3s
    &:hover
      background: #e4e4e4
      transition: 0.3s
#viber
  margin-top: 14px
  .delete-icon
    top: 13px
.messenger_wrap .jq-radio
  opacity: 0
  width: 0

.payment_block label
  cursor: pointer
  transition: 0.3s
  margin-left: -5px
  &:hover svg path
    fill: #2f2f2f
    transition: 0.3s

.pay_system
  width: 223px
  margin-left: 2px
  margin-right: 11px
  margin-bottom: -11px
  margin-top: -7px
  label
    &:hover, &.active
      border: 1px solid #db046b

.for_card p
  margin-top: 36px

.hide_pay
  display: none

.payment_pad
  margin-bottom: 0 !important

.mir
  position: absolute
  margin-top: 50px
  margin-left: -10px

.payment_system_wrap
  display: -webkit-flex
  display: -moz-flex
  display: -ms-flex
  display: -o-flex
  display: flex

.webMoney
  margin-left: -55px
  margin-top: 15px

.for_card, .for_wallet
  display: none
#secondtabform-payment_system_number + label
  top: -46px
  font-size: 13px
  color: rgba(47,47,47,.5)
.for_card, .for_wallet
  position: relative
  flex-wrap: wrap
  top: 15px
.for_card .title, .for_wallet .title
  top: 1px
.field-secondtabform-viber
  top: 6px !important
.field-secondtabform-payment_system_number
  top: 18px
  width: 100%
.contact_forms .button_wrap
  .login-button
    margin-top: 2px
    width: 210px
  .form-group
    height: auto
.block_select_messenger
  margin-top: 18px
  margin-bottom: 57px
.pay_system label
  margin-left: 0 !important
.dropdown_messenger
  display: none
  &.active
    display: block
.form-group__padding
  padding: 0 15px

.style-label input+ .active-label
  top: -13px!important
  font-size: 13px!important
  color: rgba(47,47,47,.5)
.payment_description 
  display: none

.secondtab .form-group
    top: 18px
    margin-right: 10px
