.workcab-wrap
  padding: 0
  display: grid
  grid-template-columns: repeat(4, 1fr)
  gap: 30px
  height: 100% !important
  margin-bottom: 214px
  margin-top: 40px
  &::before
    display: none
  &::after
    display: none

  .link
    display: block

  .workcab-block
    -webkit-box-sizing: border-box
    box-sizing: border-box
    -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    float: left

    &__img
      border-radius: 5px
      margin: 5px 5px 23px
      height: 142px
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      display: -webkit-flex
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center
      -webkit-box-pack: center
      -ms-flex-pack: center
      justify-content: center
      position: relative

      .help-block-example
        position: absolute
        top: 10px
        right: 10px

    &__img-1
      background: url("../img/model1@3x.png") no-repeat
      background-size: cover

    &__img-2
      background: url("../img/model2@3x.png") no-repeat
      background-size: cover

    &__img-3
      background: url("../img/model3@3x.png") no-repeat
      background-size: cover

    &__img-4
      background: url("../img/model4@3x.png") no-repeat
      background-size: cover

    &__img-5
      background: url("../img/model5@3x.png") no-repeat
      background-size: cover

    &__img-6
      background: url("../img/model6@3x.png") no-repeat
      background-size: cover

    &__site-name
      margin: 0
      font-family: 'Montserrat'
      font-weight: 700
      font-size: 72px
      color: #fff
      word-break: break-all
      width: 100%
      text-align: center

    &__links
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      display: -webkit-flex
      -ms-flex-wrap: wrap
      flex-wrap: wrap
      -webkit-box-pack: justify
      -ms-flex-pack: justify
      justify-content: space-between
      margin: -2px 25.5px 0

    &__links-one
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      display: -webkit-flex
      -ms-flex-wrap: wrap
      flex-wrap: wrap
      -webkit-box-pack: end
      -ms-flex-pack: end
      justify-content: flex-end
      margin: -2px 25.5px 0

    .btn-primary
      width: 100%

      display: -webkit-box
      display: -ms-flexbox
      display: flex
      display: -webkit-flex
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center
      height: 49px

.workcab-block-help
  text-align: start
  width: 263px
  float: left

  .link
    display: block
    padding-bottom: 8px

.step-text
  font-family: 'PFDinText'
  font-weight: 400
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  display: -webkit-flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  margin: 24px 0

.step-image
  width: 102.5%
  height: 377px
  background-color: #e4e4e4

.about-image
  width: 102.5%
  height: 188px
  background-color: #e4e4e4
  margin-bottom: 30px

.popup-about-text span
  display: block
  font-family: 'PFDinTextPro'
  font-weight: 400
  font-size: 16px
  line-height: 16px
  color: #30173d
  padding: 15px 0 30px

.popup-about-row
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-pack: justify
  -ms-flex-pack: justify
  justify-content: space-between

.popup-about-block
  width: 46.5%

  ul
    padding: 20px 0 0 25px
    font-family: 'PFDinTextPro'
    font-weight: 400

    li
      padding-left: 11px
      margin-top: 3px
      font-size: 16px
      line-height: 15px

.popup-programs
  .popup__content-wrap
    padding: 35px 20px 0 30px !important
    overflow-y: scroll
    height: 100%
    width: 100%
    -webkit-box-sizing: border-box
    box-sizing: border-box

  .popup__title
    padding-left: 15px
    padding-right: 15px


.popup-obs
  .popup__content-wrap
    padding: 35px 20px 0 30px !important
    overflow-y: scroll
    height: 100%
    width: 100%
    -webkit-box-sizing: border-box
    box-sizing: border-box

  .popup__title
    padding-left: 15px
    padding-right: 15px


.overlay-popup
  display: none
  position: absolute
  height: 97%
  width: 100%
  z-index: 5
  justify-content: center
  -webkit-box-align: flex-start
  -ms-flex-align: flex-start
  align-items: flex-start

.popup
  display: none
  -webkit-box-sizing: border-box
  box-sizing: border-box
  width: 750px
  height: auto
  min-height: 200px
  max-height: 674px
  background: #fff
  -webkit-box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  font-family: 'Montserrat'
  font-weight: 600
  color: #30173d
  overflow: hidden
  position: relative !important
  cursor: default
  margin-top: 7%
  img
    width: 100%!important
    object-fit: cover

  &__content-wrap
    padding: 0px 55px 0 40px !important
    overflow: auto
    height: 100%
    max-height: 674px
    max-width: 100vw
    -webkit-box-sizing: border-box
    box-sizing: border-box

  .js-close-popup
    position: absolute
    right: 15px
    top: 15px
    cursor: pointer
    z-index: 5

  .js-close-popup:hover g
    fill: #2f2f2f !important

  &__title
    font-family: 'Montserrat'
    font-weight: 700
    font-size: 20px
    margin: 0

  &__title-success
    font-family: 'Montserrat'
    font-weight: 700
    font-size: 20px
    margin: 0
    padding-left: 15px
    padding-top: 100px

  &__dsc
    font-family: 'Montserrat'
    font-weight: 600
    font-size: 16px

  .popup__dsc-sec
    font-family: 'PFDinTextPro'
    font-weight: 400
    font-size: 16px

  .popup-content-wrap h3, p
    margin: 0

  .steps-block
    padding-bottom: 15px

  .step-num
    font-size: 14px
    color: #fff
    border-radius: 100%
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    width: 20px
    height: 20px
    background-color: #db046b
    margin-right: 10px

.popup .program-block
  width: 102.5% !important
  display: -ms-flexbox
  display: flex
  display: -webkit-flex
  -ms-flex-wrap: wrap
  flex-wrap: wrap

  .program-item
    padding: 30px 0 20px
    width: 203px
    height: 265px
    box-sizing: border-box
    border: 1px solid #e4e4e4
    border-radius: 5px
    margin: 0 15px 30px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between

    img
      height: 60.7px

    .popup__dsc
      margin: 15.3px 0 0

    span
      display: block
      font-family: 'PFDinTextPro'
      font-weight: 400
      font-size: 16px
      text-align: center
      width: 163px
      line-height: 16px
      padding: 20px 0

.popup-comp-help
  width: 360px !important
  -webkit-box-sizing: border-box
  box-sizing: border-box
  overflow: visible

  &__hide-block
    display: none

  &__content-wrap
    padding: 35px 55px 0 40px !important
    width: 360px !important
    letter-spacing: -.51px
    padding-right: 38px !important
    overflow: visible

  .field-pchelpform-problem
    label
      top: -93px
      left: 16px

  .popup__title
    letter-spacing: .1px

  .popup__dsc
    font-family: 'Montserrat'
    font-weight: 600
    font-size: 16px
    letter-spacing: 0.1px

  .btn-primary
    height: 50px !important
    margin: 0 auto
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    display: -webkit-flex
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    padding: 0

  .link
    display: block
    width: 100%
    text-align: center
    padding: 30px 0
    letter-spacing: .1px

  .help-block-example
    height: 100%

    .popup-help
      left: -190px !important

  .form-horizontal
    margin-top: 27px !important

  .login-form
    width: 100% !important

  .textarea-wrap
    margin-top: 20px

  .btn
    width: 100%

  &__time-wrap
    display: flex
    justify-content: space-between !important
    margin-bottom: 35px

    .help-block-error
      position: absolute
      color: #db046b
      font-size: 13px
      display: none

    .col-lg-12
      box-sizing: border-box

    .form-control
      width: 127px
      display: flex
      justify-content: flex-end !important

  &__select-date, &__select-time
    width: 127px

.popup-apply-to-site
  width: 360px
  height: 232px !important

  .popup__content-wrap
    width: 100%
    overflow-y: auto

  .popup__dsc-sec
    font-family: 'Montserrat'
    font-size: 16px !important
    font-weight: 300 !important

    .link
      font-family: 'Montserrat'
      font-size: 16px !important
      font-weight: 300 !important

.fast-question
  margin-top: -50px

.popup-about-close
  background: url(../img/close-grey.svg)
  width: 24px
  height: 24px
  float: right
  top: 20px
  position: absolute
  right: 20px
  cursor: pointer

  &:hover
    background: url(../img/00-close.svg)

.anketa
  margin-left: -350px
  height: 700px !important
  width: 703px
  overflow: hidden
  -webkit-box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  -webkit-box-flex: 1
  -ms-flex-positive: 1
  flex-grow: 1
  display: flex
  -webkit-box-align: start
  align-items: flex-start
  margin-top: 97px
  flex-direction: column
  margin-bottom: 100px
  z-index: 5


.worckab__begin-modal
  display: none
  width: 360px
  min-height: 280px
  height: auto
  margin-left: -180px
  left: 50%
  top: 240px
  position: absolute
  box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  background: #ffffff
  align-items: center
  position: absolute
  justify-content: center
  flex-direction: column
  z-index: 20

  &-login, &-password, &-url, &-key
    display: flex
    font-family: Montserrat
    font-size: 16px
    font-weight: 600
    color: #30173d
    margin-bottom: 20px

    & span
      font-size: 18px
      top: -2px
      position: relative

    & b
      margin-right: 10px

  &-url
    flex-wrap: wrap
    margin-top: 20px

  & a
    font-size: 18px
    padding: 0 0 40px 0

.worckab__begin-modal-key
  margin-bottom: 45px

.work-with-sites__stream-key
  margin-left: 10px

.wrap_model_data
  padding: 40px 0 0 0

.popup-instruction
  width: 1139px
  min-height: 200px
  max-height: 674px
  height: auto

.popup-instruction .popup__content-wrap
  width: 100%
  overflow: auto

.block-p-pad0 td
  vertical-align: top

.popup-comp-help__label-select
  padding-bottom: 10px

.jq-checkbox__div
  background-size: contain !important

.work-with-sites__checkbox.workcab
  & .jq-checkbox
    width: 49px
    height: 49px
    top: 0

.user-workcab_obs-save
  width: 142px
  margin: 0 auto 25px auto
  height: 45px


.work-with-sites__checkbox.workcab.disabled
  & .jq-checkbox.checked
    border: 1px solid grey
  &:hover .popup-info
    display: block
  & .jq-checkbox.checked .jq-checkbox__div
    width: 100%
    height: 100%
    background: url(../img/check.svg) grey center center

.modal-box.document-check-modal
  width: 600px
  height: 100%
  max-height: 720px

.login-form.firsttab.fourthtab-window
  position: relative
  padding: 30px 0 110px !important

  .button_wrap
    position: absolute
    top: initial
    left: 50%
    bottom: 30px
    transform: translateX(-50%)

    .form-group > div
      margin-left: 0 !important

    .register-button.submit_button
      margin: 0 !important

.first_model_doc_photo_wrap.doc-photo
  display: grid
  grid-template-columns: 1fr 1fr
  justify-content: space-around
  place-items: center
  gap: 24px
  padding: 0 56px
  margin-bottom: 23px
  top: 0

  &.four-step__second-model
    margin-top: 95px

  .title
    grid-column: 1/3
    margin-bottom: 15px
    margin-left: 0 !important

  .single_photo_wrap
    margin: 0 !important

    .fancybox
      text-align: left

    .single_photo__img
      width: 215px
      height: 215px
      left: 0

//.models-wrapper

.partial-pic
  display: none

.workcab-pic-with-doc
  order: 5

.workcab-doc-pic4
  order: 3
  display: none

.workcab-doc-pic5
  order: 4
