.main
  height: auto
  .center
    -webkit-box-flex: 1
    -ms-flex-positive: 1
    flex-grow: 1
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-align: start
    -ms-flex-align: start
    align-items: flex-start
    margin-top: 97px
    flex-direction: column
    margin-bottom: 100px
    z-index: 5
    height: 100% !important
    .login-form
      width: 556px
      background-color: #ffffff
      -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3)
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3)
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      display: -webkit-flex
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -ms-flex-direction: column
      flex-direction: column
      padding: 40px
      -webkit-box-sizing: border-box
      box-sizing: border-box
      margin: 0 auto !important
      z-index: 2
      .lbl-head
        display: table-cell
        vertical-align: top
        padding-bottom: 51px
        font-family: PFDinTextCompPro
        font-size: 64px
        margin-top: -1px
        letter-spacing: -2px
        line-height: 58px
        height: 64px
        font-weight: bold
        font-style: normal
        font-stretch: normal
        color: #30173d
        text-transform: uppercase
      .lbl-desc
        font-family: Montserrat
        font-size: 20px
        min-height: 72px
        font-weight: normal
        font-style: normal
        font-stretch: normal
        line-height: normal
        letter-spacing: normal
        color: #30173d
        margin-top: 40px
  .group1
    font-family: PFDinTextProReg
    font-size: 1rem
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    display: -webkit-flex
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-pack: justify
    -ms-flex-pack: justify
    justify-content: space-between
    .lnk
      line-height: 38px
      margin-top: 3px
      font-family: PFDinTextProReg
  .group2
    margin-top: 20px
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    display: -webkit-flex
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-pack: justify
    -ms-flex-pack: justify
    justify-content: space-between
#login-form
  margin-top: 25px
  display: flex
  flex-direction: column
.button_wrap
  display: -webkit-flex
  display: -moz-flex
  display: -ms-flex
  display: -o-flex
  display: flex
  justify-content: space-between
  margin-top: 5px
  position: relative
  left: 3px
.lbl-retry
  text-align: center
  position: relative
  top: 127px
.register-button-main
  margin-top: 0!important
