.main_photo
  margin-top: 30px
  .title
    margin-top: 0

.form-third-rec-marg
  margin-left: -2px
.thirdtab-head-title
  position: relative
  top: -7px
.main .center .photo-form .lbl-head
  display: table-cell
  vertical-align: top
  padding-bottom: 40px
  font-family: PFDinTextCompPro
  font-size: 64px
  margin-top: -5px
  letter-spacing: -0.12rem
  line-height: 64px
  height: 64px
  font-weight: 700
  font-style: normal
  font-stretch: normal
  color: #30173d
.slide-block-img, .slide-block-dop-img
  width: 19px
  height: 19px
  border-radius: 50%
  margin-left: 3px
  display: block
  background: url("../img/hide.svg")
  cursor: pointer
  background-size: contain
  top: 1px
  position: relative

.slide-block-img.active, .slide-block-dop-img.active
  background: url("../img/show.png")
  background-size: contain

.slide-block
  display: flex
.main_photo .title
  margin-bottom: 15px
  padding-left: 2px
.main_photo_desk
  font-family: "Montserrat"
  font-weight: 400
  font-size: 14px
  margin-bottom: 18px
  padding-top: 3px
  padding-left: 1.5px
  line-height: 18px
.help-block-example
  &__img
    width: 19px !important
    height: 19px !important
    position: relative
    margin-left: 1px
    margin-right: 10px
  &__span
    color: #db046b
    font-size: 16px
    font-family: PFDinTextPro
    font-weight: 400
    position: relative
    top: 2px

.addit_photo
  margin-top: 55px
  .title
    margin-top: 0
.dop-photo-title
  position: relative
  top: -2px
  left: 2px
  .slide-block-dop-img
    top: -2px
    left: 9px
  .title
    position: relative
    top: -2px
    letter-spacing: 0 !important
.profile-dop-photo-block
  &__span
    display: block
    position: relative
    line-height: 1.3
    letter-spacing: 0px
    margin-bottom: 20px
    font-family: 'Montserrat'
    font-weight: 400
#addit_photo_3_wrap, #addit_photo_4_wrap, #addit_photo_5_wrap, #addit_photo_6_wrap, #addit_photo_7_wrap, #addit_photo_8_wrap, #addit_photo_9_wrap, #addit_photo_10_wrap, #addit_photo_11_wrap, #addit_photo_12_wrap
  display: none
.single_photo_wrap
  cursor: pointer
  .single_photo__img
    position: relative
    left: 5px
.button-wrap__marg
  margin-top: 26px
.back-wrap-thirdstep__marg
  margin-top: 1px
.btn-primary.reg-btn-action
  left: 15px
  margin-top: 10px !important
.help-block-example
  position: relative
  cursor: pointer
  height: 30px
  &:hover .popup-help
    display: block
.photo-form
  width: 556px
  background-color: #ffffff
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3)
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3)
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  display: -webkit-flex
  -webkit-box-orient: vertical
  -webkit-box-direction: normal
  -ms-flex-direction: column
  flex-direction: column
  padding: 40px
  -webkit-box-sizing: border-box
  box-sizing: border-box
  z-index: 2
.add-photo-block
  margin-top: 32px
.single_photo_flex_wrap .help-block-example
  margin-top: 32px