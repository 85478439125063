.outer
    position: relative
    overflow: auto
    max-height: calc(100vh - 130px)
.inner table
    width: 100%
.inner table tr
  border-bottom: 1px solid #e4e4e4
.inner table tr td:nth-child(1)
    background-color: #f2f2f2
    color: #fff
    position: sticky
    height: auto
    @media (min-width: 768px)
      left: 0

.tablesorter-header.tablesorter-headerUnSorted:focus
   outline: none 0

.manager-main__period-select
  border: solid 1px #e4e4e4
  border-radius: 5px
  padding: 13px 20px
  font-family: PFDinTextPro
  font-size: 16px
  font-weight: 400
  color: #30173d
  position: relative
  height: 81px
.manager-main__period
  &-select
    display: flex
    width: 360px
    padding: 19px 20px 13px
  & .title
    font-weight: 700
    margin-bottom: 10px
.manager-main__period-select .jq-selectbox__select
  padding: 0 18px 0 0px
.manager-main__period-select-date li, .manager-main__period-select-date-second li
  &:nth-child(4)
    display: block
  &:nth-child(5)
    display: none
.manager-main__period-select-year
  width: 60px
.manager-main__period-select-date, .second-period
  width: 120px
.manager-main__select-manager, .manager-main__select-tribe
  width: 100%
.manager-main__table-outer
  margin-top: 20px
  overflow: auto
  height: calc(100vh - 200px)
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
.manager-main
  &__select-line
    position: absolute
    height: 35px
    background: #fff
    bottom: 21px
    right: 38px
    max-width: 1117px
    display: block
    z-index: 2
    width: 100%
  &__table-info
    margin-top: 30px
    width: 100%
    padding-bottom: 33px
    &::-webkit-scrollbar
      width: 5px
      background: rgba(228, 228, 228, 0.5)
    &::-webkit-scrollbar-thumb
      background: rgba(47, 47, 47, 0.15)
      border-radius: 5px
    &::-webkit-scrollbar-track
      background: rgba(228, 228, 228, 0.5)
  &__table
    position: relative
    background: #fff
    border-radius: 5px
    color: #30173d
    font-family: Montserrat
    font-weight: 400
    font-size: 14px
    & .total-line th
      border: none
    & thead
      & tr
        position: sticky
        top: 0
      & tr th
        background: white
        padding: 10px 12px!important
        background-color: #f2f2f2
    & tfoot
      & tr th
        background: white
        padding: 10px 12px!important
        background-color: #f2f2f2
    td
      width: 115px
      text-align: left
      padding: 10px 12px!important
      & a
        color: #30173d
        font-family: Montserrat
        font-weight: 400
        font-size: 14px
    th
      font-family: 'Montserrat'
      font-weight: 600
      font-size: 16px
      width: 115px
      text-align: left
      height: 42px
      padding-top: 15px
    tr
      &.active
        background-color: #ffffff
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
      &:hover
        cursor: default
      td:first-child
        width: 216px
        text-align: left !important
        word-wrap: break-word !important
      th:first-child
        width: 216px
        text-align: left !important
        word-wrap: break-word !important
        position: sticky
        z-index: 1
        @media (min-width: 768px)
          left: 0
    & tbody
      & tr
        display: flex
        &:first-child
          margin-top: 14px
          & td
            border-top: 1px solid #e4e4e4
        &:last-child
          margin-bottom: 14px

.modelstream__table
  th
    min-width: 115px
  td:first-child
    min-width: 216px
  th:first-child
    min-width: 216px
  td:nth-child(8), th:nth-child(8),td:nth-child(9), th:nth-child(9),td:nth-child(7), th:nth-child(7),td:nth-child(6), th:nth-child(6),td:nth-child(5), th:nth-child(5),td:nth-child(4), th:nth-child(4)
    min-width: 150px
  td:nth-child(10), th:nth-child(10),td:nth-child(11), th:nth-child(11),td:nth-child(12), th:nth-child(12),td:nth-child(13), th:nth-child(13)
    min-width: 90px

.input-list-div
  & .div-models-list
    width: 208px
    position: absolute
    z-index: 20
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3)
    background-color: white
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    gap: 10px
    max-height: 324px
    overflow-y: scroll
    &::-webkit-scrollbar
      width: 5px
      background: rgba(228, 228, 228, 0.5)
    &::-webkit-scrollbar-thumb
      background: rgba(47, 47, 47, 0.15)
      border-radius: 5px
    &::-webkit-scrollbar-track
      background: rgba(228, 228, 228, 0.5)
    & div
      display: inline
      width: 80%
      color: black
      & div
        font-weight: 600
        & a
          font-size: 14px
          color: black
          font-family: Montserrat,sans-serif
          font-weight: 600
  & input
    height: 28px
    outline: none
    border-width: 0 0 1px 0
    border-style: solid
    border-color: black
    color: black

.manager-main
  &__top
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    gap: 20px
    margin-bottom: 20px
    @media (min-width: 768px)
      justify-content: center
    & .title p
      font-size: 20px
      font-family: 'Montserrat'
      font-weight: 700
  &__search
    border: solid 1px #e4e4e4
    border-radius: 5px
    padding: 20px
    display: flex
    flex-direction: row
    column-gap: 20px
    justify-content: space-around
    align-items: center
  &__search a
    margin-top: 8px
  &__period
    position: relative
    width: 130px
  &__period-date
    display: flex
    color: #30173d
    font-size: 16px
    font-family: 'Montserrat'
    font-weight: 600
    position: relative
    white-space: nowrap
    flex-wrap: wrap
  &__period-interval
    position: relative
    label
      margin: 0
      position: initial
      width: auto
  &__period-select
    justify-content: space-between
    padding: 20px
    height: auto!important
    gap: 20px
    align-items: center
    flex-direction: column
    width: 100%
    @media (min-width: 768px)
      flex-direction: row
      width: auto
    &-wrapper
      display: grid
      row-gap: 20px
    &-label
      font-size: 12px
      margin-left: 0
      position: initial
    &-first
      display: flex
      column-gap: 10px
      position: relative
      & > div
        position: relative
        display: grid
        z-index: 15
    &-second
      display: none
      column-gap: 10px
      position: relative
      & > div
        position: relative
        display: grid
        z-index: 15
  &__constructor
    & .btn-witdh-grey-border
      width: 134px
      margin-bottom: 8px
  &__plan
    & .btn-witdh-grey-border
      width: 134px
  &__table-info
    border-radius: 5px
    margin-top: -1px
    table
      box-shadow: none
      border-radius: 0
      td, th
        word-wrap: break-word
        text-align: center !important
      tr:last-child
        td:last-child:before
          display: none
        td:last-child
          font-family: Montserrat
          font-weight: 400
      td:nth-child(8), th:nth-child(8)
        width: 200px !important
      td:nth-child(9), th:nth-child(9), td:nth-child(10), th:nth-child(10), td:nth-child(11), th:nth-child(11), td:nth-child(13), th:nth-child(13)
        width: 150px
    &::-webkit-scrollbar
      height: 7px
      background: rgba(228, 228, 228, 0.5)
    &::-webkit-scrollbar-thumb
      background: rgba(47, 47, 47, 0.15)
      border-radius: 5px
    &::-webkit-scrollbar-track
      background: rgba(228, 228, 228, 0.5)
  &__table-stream
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    margin-top: -1px
    table
      box-shadow: none
      border-radius: 0
      th, td:first-child, th:first-child
        word-wrap: break-word
      td
        word-wrap: break-word
        & a.online
          color: #db046b
      tr:last-child
        td:last-child:before
          display: none
        td:last-child
          font-family: Montserrat
          font-weight: 400
      td:nth-child(8), th:nth-child(8),td:nth-child(9), th:nth-child(9),td:nth-child(7), th:nth-child(7),td:nth-child(6), th:nth-child(6),td:nth-child(5), th:nth-child(5),td:nth-child(4), th:nth-child(4)
        width: 150px
        margin-left: 0px !important
        padding-left: 10px
      td:nth-child(10), th:nth-child(10),td:nth-child(11), th:nth-child(11),td:nth-child(12), th:nth-child(12),td:nth-child(13), th:nth-child(13)
        width: 90px
        margin-left: 0px !important
        pdding-left: 10px
  &__constuctor-checkbox
    position: relative
    display: grid
    grid-template-columns: 18px 1fr
    column-gap: 4px
    label
      position: initial
      font-family: PFDinTextPro
      font-weight: 400
      font-size: 16px
      color: #30173d
      margin-left: 0
      margin-bottom: 0
  &__constructor-block
    display: none
    position: absolute
    row-gap: 10px
    top: 0
    left: -201px
    width: 195px
    background: #fff
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    padding: 24px
    z-index: 37
  &__constructor
    position: relative
  &__table-doc
    &.not-load
      color: rgba(47, 47, 47, 0.5)
    &.load
      color: #0ccd9d
  &__select-row
    position: absolute
    bottom: 7px
    right: 20px
    background: #fff
    width: 1134px
    height: 41px
    z-index: 10
.end-period
  display: none
.manager-main__period-select-date .jq-selectbox__dropdown ul
  height: 324px!important
  overflow-y: scroll
  &::-webkit-scrollbar
    width: 7px
    background: rgba(228, 228, 228, 0.5)
  &::-webkit-scrollbar-thumb
    background: rgba(47, 47, 47, 0.15)
    border-radius: 5px
  &::-webkit-scrollbar-track
    background: rgba(228, 228, 228, 0.5)
.constuctor-btn
  position: relative
.close-constructor-block
  width: 24px
  height: 29px
  position: absolute
  right: 10px
  top: 7px
  display: block!important
  .delete-icon
    top: 0
    z-index: 5
.site-color-info
  display: none
  position: absolute
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
  border-radius: 5px
  width: 260px
  height: 200px
  padding: 24px
  flex-wrap: wrap
  color: #30173d
  font-size: 16px
  font-family: PFDinTextPro
  font-weight: 400
  background: #fff
  top: -26px
  left: 35px
  div
    width: 100%
    text-align: left
    display: flex
    margin-bottom: 10px
.site1-th
  z-index: 14
  img
    width: 24px
    height: 24px
    top: -2px
    position: relative
    cursor: pointer
.site-info-block
  position: relative
  width: 24px
  display: inline-block
.site-info-block:hover .site-color-info
  display: flex
.grey-square, .violet-square, .yellow-square, .pink-square, .green-square
  width: 24px
  height: 24px
  border-radius: 5px
  display: inline-block
  margin-right: 9px
.grey-square
  background: #e4e4e4
.violet-square
  background: #7642ff
.yellow-square
  background: #f4bc00
.pink-square
  background: #db046b
.green-square
  background: #0ccd9d
.show-modal .modal-box, .modal-box-studia
  display: none
  margin-top: 200px
  z-index: 20
  background: #fff
  margin-left: -180px
  left: 50%
  height: 424px

.show-modal, .modal-box-studia
  position: fixed
  width: 100%
  z-index: 100
  left: 0
.manager-main__table-studio_home
  cursor: pointer
.modal-studia, .modal-manager, .model-tribe
  width: 100%
  height: 100%
  text-align: center
.modal-studia-changes
  display: flex
  justify-content: center
  margin: 20px 0 35px
.modal-studia-changes > div
  position: relative
  display: flex
  margin-right: 25px
.for-radio .jq-radio__div
  margin: 3px 0 0 8px!important
.modal-studia-model-name-line, .modal-manager-model-name-line, .modal-tribe-model-name-line
  font-size: 18px
.modal-plan
  width: 100%
  height: 100%
.plan-period
  width: 100%
  position: relative
  margin-top: 13px
.show-modal .modal-content
  padding: 35px 40px 40px
.modal-box-studia
  margin-top: 25px
  height: 340px
  width: 330px
  margin-left: 750px
.modal-box-manager
  position: fixed
  margin-top: 25px
  height: 280px
  width: 430px
  margin-left: 0
  z-index: 9999

.modal-box-tribe
  position: fixed
  margin-top: 25px
  height: 280px
  width: 430px
  margin-left: 0
  z-index: 9999

#period-select-date-plan-styler
  width: 100%
.modal-plan-title, .modal-studia-title, .modal-manager-title, .modal-tribe-title
  font-family: Montserrat
  font-weight: 700
  font-size: 20px
  color: #30173d
  margin-bottom: 45px
.modal-studia-title
  margin-bottom: 30px

.modal-manager-title
  margin-bottom: 0

.modal-manager-model-name-line
   margin: 20px 0 35px 0

.modal-tribe-model-name-line
   margin: 20px 0 35px 0

.current-plan-value
  color: #30173d
  font-size: 16px
  font-family: PFDinTextPro
  font-weight: 400
  span
    display: block
    color: rgba(47,47,47,0.5)
    font-size: 13px
    position: relative
.plan-value
  display: flex
  justify-content: space-between
  margin-bottom: 26px
.new-plan-value
  width: 130px
  top: 14px
.modal-plan-btn
  margin: 0 auto
  width: 280px
.total-line
  th
    position: relative!important
@-moz-document url-prefix()
  .total-line tr
    margin-bottom: 0px
    height: 84px
    vertical-align: top
    th
      padding-top: 15px
  .manager-main__select-row
    position: absolute
    bottom: 16px
    right: 31px
    background: #fff
    width: 1117px
    height: 41px
    z-index: 10
.blockForComment
  width: 100%
#photoWork
  justify-content: space-around
.smart_search
  background: #fff
  z-index: 73
  position: absolute
  width: 100%
  border: 1px solid #e4e4e4
  overflow-y: auto
  ul
    padding: 5px
    list-style: none
    li
      margin-bottom: 5px

#period-select-date-plan-singlemodel-page-styler
  width: 100%

.manager-main__table-plan
  cursor: pointer
.modal-note-btn
  width: 115px
  height: 50px
  margin: 10px 20px 10px
.modal-studia-btn, .modal-manager-btn, .model-tribe-btn
  width: 135px
  height: 50px
  margin: 0
.modal-studia-btns, .modal-manager-btns, .modal-note-btns, .modal-tribe-btns
  display: flex
  justify-content: space-between

.manager-main__table-skype
  width: auto !important
.manager-main__table-mail
  width: auto !important

.download-photo.manager
  margin: auto

.model_tribe
    color: #db046b
    font-size: 12px
    font-weight: 400
    cursor: pointer
    margin: -3px 10px 0 0

.active_tribe
    color: #db046b
    font-size: 12px
    font-weight: 400
    cursor: pointer
    margin: -3px 10px 0 0

.manager_tribe
    color: #db046b
    font-size: 12px
    font-weight: 400
    cursor: pointer
    margin: -3px 10px 0 0

.change_manager
    color: #db046b
    font-size: 12px
    font-family: PFDinTextPro
    font-weight: 400
    cursor: pointer
    margin: -3px 10px 0 0

.change_model_tribe
    color: #db046b
    font-size: 12px
    font-family: PFDinTextPro
    font-weight: 400
    cursor: pointer
    margin: -3px 10px 0 0

.change_note
  font-size: 12px
  cursor: pointer

.change_chat_operator_note
  font-size: 12px
  cursor: pointer

.change_quality_control_note
  font-size: 12px
  cursor: pointer

.sherlock_logo
    padding-right: 10px
    float: right

.sale_bot_logo
    padding-right: 10px
    float: right

.manager-main__table-head
  z-index: 2

.tickets__table
  width: 1860px!important
  margin: 0 auto
  tr
    display: table-row!important
    td:first-of-type, th:first-of-type
      width: 200px!important
    td:nth-of-type(5)
      white-space: nowrap
    td:nth-of-type(6)
      white-space: nowrap
    td:last-of-type
      display: grid
      row-gap: 10px
  .ticket-button
    margin: 0
