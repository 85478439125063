.field-sites-forbid_list, .field-sites-title_desc, .field-sites-description, .field-sites-solve_list, .field-sites-instructions
  margin-top: 19px
  height: 100%!important
  .control-label
    top: -32px
.sites-form
  margin-top: 41px
  .btn-primary
    max-width: 200px
    width: 100%
  .form-group-btns
    margin-bottom: 30px
.sites-update
  .field-sites-description, .field-sites-instructions
    min-height: 800px
  .cke_reset
    min-height: 800px
