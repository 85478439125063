/*checkbox, radio

.jq-checkbox
  vertical-align: -4px
  width: 18px
  height: 18px
  margin: 0 3px 0 0
  border: 1px solid rgba(47, 47, 47, 0.5)
  background: transparent
  cursor: pointer
  position: relative
  top: 2px

.jq-radio
  vertical-align: -4px
  width: 18px
  height: 18px
  margin: 0 3px 0 0
  border: 1px solid rgba(47, 47, 47, 0.5)
  background: transparent
  cursor: pointer
  position: relative
  top: 2px
  width: 20px
  height: 20px
  border: 2px solid rgba(47, 47, 47, 0.5)
  background: transparent
  &.focused
    border: 2px solid #db046b

.jq-checkbox.disabled, .jq-radio.disabled
  opacity: .55

.jq-checkbox
  border-radius: 3px
  &.checked
    .jq-checkbox__div
      width: 100%
      height: 100%
      background: url('../img/check.svg') #db046b center center
    border: none
    &:hover
      .jq-checkbox__div
        background: url('../img/check.svg') rgba(219, 4, 107, 0.8) center center


.jq-radio
  border-radius: 50%
  transition: 0.3s
  &:hover
    border: 2px solid #2f2f2f
    transition: 0.3s
  &.checked
    border: 2px solid #db046b
    .jq-radio__div
      width: 10px
      height: 10px
      margin: 3px 0 0 3px
      border-radius: 50%
      background: #db046b
  input:checked + label
    border: 2px solid #db046b

/*card

.outline-card
  + label
    border: 1px solid #e4e4e4
    display: inline-block
    pointer-events: auto
    position: static
    transition: 0.3s
  label:hover svg path
    fill: #2f2f2f
    transition: 0.3s
  &.checked + label
    &:hover
      border: 2px solid rgba(219, 4, 107, 0.7)
      svg path
        fill: rgba(219, 4, 107, 0.7)
        transition: 0.3s
    border: 2px solid rgba(219, 4, 107, 1)
    svg path
      fill: rgba(219, 4, 107, 1)
      transition: 0.3s
  + label
    display: inline-block
    padding: 4px 12px
    width: 223px
    border: 1px solid #e4e4e4
    border-radius: 5px
    height: 138px
    margin-left: 10px
    border-color: #ddd
    position: static
    pointer-events: auto
    padding-left: 75px
    padding-top: 30px
    margin-bottom: 20px

/*tabs

.tab
  font-size: 16px
  font-family: 'PFDinTextPro'
  font-weight: 700
  padding-left: 35px
  padding-right: 35px
  padding-bottom: 11px
  color: rgba(47, 47, 47, 0.5)
  a
    cursor: pointer
    color: rgba(47, 47, 47, 0.5)
    font-size: 16px
    font-family: 'PFDinTextPro'
    font-weight: 700
  span
    cursor: pointer
    color: rgba(47, 47, 47, 0.5)
    font-size: 16px
    font-family: 'PFDinTextPro'
    font-weight: 700
    cursor: default
  &:hover
    color: #2f2f2f
    a
      color: #2f2f2f
  &.disabled
    color: rgba(47, 47, 47, 0.3)
  &.active
    a, span
      color: #db046b
    color: #db046b
    border-bottom: 2px solid #db046b
    &:hover, a:hover
      color: rgba(219, 4, 107, 0.7)
    &:hover
      border-bottom: 2px solid rgba(219, 4, 107, 0.7)