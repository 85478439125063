.modal-info-update
  margin-top: 51px
.control-label
  color: rgba(47,47,47,.5)
  font-size: 13px
  font-weight: 400
  position: absolute
  pointer-events: none
  top: -17px
  transition: .2s ease all!important
  -moz-transition: .2s ease all!important
  -webkit-transition: .2s ease all!important
  margin-left: 0!important

.field-modalinfo-description
  margin-top: 19px
  min-height: 600px
  height: 100%!important
  .control-label
    top: -32px
.modal-info-update
  .form-group-btn
    margin-bottom: 30px
  .btn-success
    max-width: 200px
    width: 100%
    margin-bottom: 20px
  .cke_contents
    min-height: 600px