.sattelit-home__big_article_wrap
  width: 100%
  height: 100%
  margin-top: 40px
  background-image: url(/public/img/sattelit_big_article.jpg)
  padding-top: 1px
  padding-left: 65px
  border-radius: 25px
  padding-bottom: 53px
  background-size: cover
  background-repeat: no-repeat
  .sattelit-home__big_arcticle_desc_wrapp
    max-width: 35%
    h3
      font-family: Montserrat
      font-size: 48px
      font-weight: 800
      color: #fff
    p
      font-family: Montserrat
      font-size: 16px
      font-weight: 500
      color: #fff
      margin-top: -21px
      height: 155px
    a
      color: #fff
.sattelit-home__button-read
  height: 55px
  border-radius: 40px
  background: linear-gradient(45deg, #ff2f88, #692d8c)
  margin-top: 61px
  border: none
  color: #fff
  font-family: Montserrat
  font-size: 16px
  font-weight: bold
  width: 110px
.sattelit-home__button-read-white
  border-radius: 40px
  color: #db046b
  border: none
  background: #fff
  font-family: Montserrat
  font-size: 16px
  font-weight: bold
  width: 110px
  height: 55px
  border: solid 1px rgba(17, 15, 38, 0.12)
.sattelit-home__four_news_wrap
  width: 100%
  display: flex
  margin-top: 28px
  justify-content: space-between
  flex-wrap: wrap
  .sattelit-home__single_news.article-page
    width: 30%
  .sattelit-home__single_news
    width: 23.5%
    box-shadow: 0 8px 8px -4px rgba(17, 15, 38, 0.12), 0 2px 6px -1px rgba(17, 15, 38, 0.12), 0 0 1px 0 rgba(17, 15, 38, 0.06)
    border-radius: 20px
    padding-left: 5px
    padding-top: 5px
    margin-bottom: 20px
    img
      border-radius: 20px
      width: 98%
      height: 170px
    h4
      font-family: Montserrat
      font-size: 20px
      font-weight: 600
      color: rgba(17, 15, 38, 0.8)
      width: 80%
      margin-left: 24px
    p
      width: 80%
      margin-left: 24px
      font-family: Montserrat
      font-size: 14px
      font-weight: 500
      height: 125px
    .sattelit-home__button_wrapp
      display: flex
      margin-bottom: 20px
    .sattelit-home__date-news
      margin-top: 14px
      font-family: Montserrat
      font-size: 14px
      margin-left: 64px
      color: rgba(17, 15, 38, 0.6)
    button
      margin-left: 24px
      margin-top: -4px
      a
        color: #db046b
.sattelit-home__single_news.hide_article
  display: none
.sattelit-home__more-news-wrapp
  text-align: center
  margin-top: 47px
  .sattelit-home__more-news-count
    width: 20px
    height: 20px
    position: absolute
    background-color: #1af5a9
    border-radius: 10px
    left: 42.5%
    color: #fff
    border: 1px solid #fff
.sattelit-home__title-vebinar
  font-family: Montserrat
  font-size: 48px
  font-weight: 800
  color: rgba(17, 15, 38, 0.8)
  margin-top: 73px
  margin-bottom: 65px
.sattelit-footer
  position: inherit
  margin-top: 156px
  height: 135px
  border-top: 1px solid #ddd
.sattelit-home__made-by
  position: absolute
  left: 40%
  margin-top: -5px
  font-family: Montserrat
  font-size: 16px
  font-weight: 500
  color: rgba(17, 15, 38, 0.8)
.sattelit-home__button_wrapp-vebinar-card
  margin-bottom: 10px
.sattelit-home__button-read-white.vebinar
  width: 200px
  margin-top: 20px !important

