.superuser-managers__period-select
  border: solid 1px #e4e4e4
  border-radius: 5px
  padding: 13px 20px
  font-family: PFDinTextPro
  font-size: 16px
  font-weight: 400
  color: #30173d
  margin-right: 30px
  position: relative
  height: 81px
  margin-top: -3px
.superuser-managers__period
  &-select
    display: flex
    width: 360px
    padding: 19px 20px 13px
  & .title
    font-weight: 700
    margin-bottom: 10px
.superuser-managers__period-select .jq-selectbox__select
  padding: 0 18px 0 0px
.superuser-managers__period-select-date li, .superuser-managers__period-select-date-second li
  &:nth-child(4)
    display: block
  &:nth-child(5)
    display: none
.superuser-managers__period-select-year
  width: 60px
.superuser-managers__period-select-date, .second-period
  width: 120px
.superuser-managers
  &__select-line
    position: absolute
    height: 35px
    background: #fff
    bottom: 21px
    right: 38px
    max-width: 1117px
    display: block
    z-index: 2
    width: 100%
  &__table-info
    margin-top: 30px
    width: 100%
    padding-bottom: 33px
    &::-webkit-scrollbar
      width: 5px
      background-color: rgba(228, 228, 228, 0.5)
    &::-webkit-scrollbar-thumb
      background: rgba(47, 47, 47, 0.15)
      border-radius: 5px
    &::-webkit-scrollbar-track
      background: rgba(228, 228, 228, 0.5)
  &__table
    position: relative
    background: #fff
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    color: #30173d
    font-family: Montserrat
    font-weight: 400
    font-size: 14px
    width: 100%
    & thead
      & tr th
        position: sticky
        top: 0
        background: white
    td
      width: 180px
      text-align: left
      border-bottom: 1px solid #e4e4e4
      padding: 13px 0
    th
      font-family: 'Montserrat'
      font-weight: 600
      font-size: 16px
      width: 180px
      text-align: left
      height: 42px
      padding-top: 15px
    tr
      &.active
        background-color: #ffffff
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
      &:hover
        cursor: pointer
      td:first-child,
      th:first-child
        width: 306px
        text-align: left
        padding-left: 25px
      td:nth-child(2), th:nth-child(2)
        text-align: left
      td:last-child,
      th:last-child
        width: 205px
        text-align: left
        padding-right: 25px
    & tbody
      & tr
        display: flex
        &:first-child
          margin-top: 14px
          & td
            border-top: 1px solid #e4e4e4
        &:last-child
          margin-bottom: 14px
.superuser-managers
  &__constructor
    & .btn-witdh-grey-border
      width: 134px
      margin-bottom: 8px
  &__constuctor-checkbox
    position: relative
    display: grid
    grid-template-columns: auto 1fr
    column-gap: 20px
    label
      position: relative
      font-family: PFDinTextPro
      font-weight: 400
      font-size: 16px
      color: #30173d
      top: 0
  &__constructor-block
    display: none
    position: absolute
    top: 0
    left: -201px
    width: 195px
    background: #fff
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    padding: 24px
    z-index: 4
  &__constructor
    position: relative
  &__top
    display: flex
    justify-content: space-between
    height: 140px
    padding: 30px 0
    & .title p
      font-size: 20px
      font-family: 'Montserrat'
      font-weight: 700
  &__period
    min-width: 300px
    position: relative
    top: 11px
  &__period-date
    display: block
    color: #30173d
    font-size: 16px
    top: -7px
    font-family: 'Montserrat'
    font-weight: 600
    position: relative
  &__period-interval
    position: relative
    width: 110px
    left: -11px
    top: 15px
    label
      margin-top: 27px
      margin-left: 9px
      color: #30173d
  &__period-select
    width: 480px
    justify-content: space-between
    margin-right: 165px
    padding: 18px 20px 13px
    a
      margin-top: 17px
    &-first
      display: flex
      column-gap: 10px
      position: relative
      & > div
        position: relative
        display: grid
        z-index: 15
    &-second
      display: none
      column-gap: 10px
      position: relative
      & > div
        position: relative
        display: grid
        z-index: 15
  &__plan
    & .btn-witdh-grey-border
      width: 134px
  &__table-info
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    overflow-x: scroll
    border-radius: 5px
    margin-top: -1px
    table
      box-shadow: none
      border-radius: 0
      td, th, td:first-child, th:first-child
        word-wrap: break-word
      tr:last-child
        td:last-child:before
          display: none
        td:last-child
          font-family: Montserrat
          font-weight: 400
      td:nth-child(7), th:nth-child(7)
        width: 210px
      .total-line
        th
          border: none!important
    &::-webkit-scrollbar
      height: 7px
      background-color: rgba(228, 228, 228, 0.5)
    &::-webkit-scrollbar-thumb
      background: rgba(47, 47, 47, 0.15)
      border-radius: 5px
    &::-webkit-scrollbar-track
      background: rgba(228, 228, 228, 0.5)
  &__table-doc
    display: none
    &.not-load
      color: rgba(47, 47, 47, 0.5)
    &.load
      color: #0ccd9d
  &__select-row
    position: absolute
    bottom: 7px
    right: 20px
    background: #fff
    width: 1134px
    height: 41px
    z-index: 10
  &__constuctor-radio
    position: relative
    width: 120px
  &__constructor-radio-container
    display: grid
    width: 100%
    grid-template-columns: repeat(4, 1fr)
    grid-auto-flow: row
    row-gap: 40px
    &__constuctor-radio
      width: 170px
  &__create
    & button
      width: 100%
.end-period
  display: none
.superuser-managers__period-select-date .jq-selectbox__dropdown ul
  height: 324px!important
  overflow-y: scroll
  &::-webkit-scrollbar
    width: 7px
    background-color: rgba(228, 228, 228, 0.5)
  &::-webkit-scrollbar-thumb
    background: rgba(47, 47, 47, 0.15)
    border-radius: 5px
  &::-webkit-scrollbar-track
    background: rgba(228, 228, 228, 0.5)
.constuctor-btn
  position: relative
.close-constructor-block
  width: 24px
  height: 29px
  position: absolute
  right: 10px
  top: 7px
  display: block!important
  .delete-icon
    top: 0
    z-index: 5
.site-color-info
  display: none
  position: absolute
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
  border-radius: 5px
  width: 260px
  height: 200px
  padding: 24px
  flex-wrap: wrap
  color: #30173d
  font-size: 16px
  font-family: PFDinTextPro
  font-weight: 400
  background: #fff
  top: -26px
  left: 35px
  div
    width: 100%
    text-align: left
    display: flex
    margin-bottom: 10px
.site1-th
  z-index: 10
  img
    width: 24px
    height: 24px
    top: -2px
    position: relative
    cursor: pointer
.site-info-block
  position: relative
  width: 24px
  display: inline-block
.site-info-block:hover .site-color-info
  display: flex
.grey-square, .violet-square, .yellow-square, .pink-square, .green-square
  width: 24px
  height: 24px
  border-radius: 5px
  display: inline-block
  margin-right: 9px
.grey-square
  background: #e4e4e4
.violet-square
  background: #7642ff
.yellow-square
  background: #f4bc00
.pink-square
  background: #db046b
.green-square
  background: #0ccd9d
.show-modal .modal-box
  display: none
  margin-top: 200px
  z-index: 20
  background: #fff
  height: 424px
.modal-plan
  width: 100%
  height: 100%
.plan-period
  width: 100%
  position: relative
  margin-top: 3px
  margin-bottom: 26px
.show-modal .modal-content
  padding: 35px 40px 40px
#period-select-date-plan-styler
  width: 100%
.modal-plan-title
  font-family: Montserrat
  font-weight: 700
  font-size: 20px
  color: #30173d
  margin-bottom: 25px
.current-plan-value
  color: #30173d
  font-size: 16px
  font-family: PFDinTextPro
  font-weight: 400
  span
    display: block
    color: rgba(47,47,47,0.5)
    font-size: 13px
    position: relative
.plan-value
  display: flex
  justify-content: space-between
  margin-bottom: 26px
.new-plan-value
  width: 130px
  top: 14px
.modal-plan-btn
  margin: 0 auto
  width: 280px
.total-line
  th
    position: relative!important
@-moz-document url-prefix()
  .total-line tr
    margin-bottom: 0px
    height: 84px
    vertical-align: top
    th
      padding-top: 15px
  .superuser-managers__select-row
    position: absolute
    bottom: 16px
    right: 31px
    background: #fff
    width: 1117px
    height: 41px
    z-index: 10
.superuser-managers__modal
  display: none
  background: #ffffff
  box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  width: 1050px
  height: 800px
  overflow: scroll
  padding: 38px 40px
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  color: #30173d
  z-index: 28
  &-title
    font-family: Montserrat
    font-size: 20px
    font-weight: 700
    margin-bottom: 37px
  &-topblock
    display: flex
    justify-content: space-between
    &-left
      & img
        width: 138px
        height: 138px
      & a
        display: block
    &-center
      min-width: 235px
      margin-top: 8px
      margin-left: 30px
    &-right
      min-width: 235px
      margin-top: 8px
      margin-left: 30px
  &-radio
    display: flex
    position: relative
    margin-top: 23px
    & label
      top: 3px
      margin-left: 11px
      color: #30173d
      font-family: PFDinTextPro
      font-size: 16px
      font-weight: 400
    &-title
      font-family: PFDinTextPro
      font-size: 13px
      color: rgba(47, 47, 47, 0.5)
      position: absolute
      top: -27px
      left: 0
  &-bottomblock
    display: flex
    justify-content: space-between
    margin-top: 41px
    margin-bottom: 11px
    & div
      width: 235px
  &-btns
    display: flex
    justify-content: flex-end
    & button
      width: 203px
      margin-left: 30px
  & .close-modal
    position: absolute
    width: 24px
    height: 24px
    background: url(../img/close-grey.svg) no-repeat
    background-size: 100%
    right: 16px
    top: 19px
    cursor: pointer
    transition: 0.3s
    &:hover
      background: url(../img/00-close.svg) no-repeat
      background-size: 100%
      transition: 0.3s
.superuser-managers__period-select-date.opened, .superuser-managers__period-select-year.opened
  z-index: 28!important

.jq-file .create_manager_photo
  width: 92px
  opacity: 0
  height: 25px

.jq-file.update_manager_photo
  width: 92px
  opacity: 0
  height: 25px
.update_manager_currency .jq-selectbox__dropdown, .update_manager_payment_system .jq-selectbox__dropdown
  width: 140px
.update_manager_currency, .update_manager_payment_system
  padding-top: 11px
.managers-curency-block
  margin-top: 0px
.form-group
  margin-right: 30px
.update_manager_currency
  .jq-selectbox__select
    width: 138px
.update_manager_payment_system
  width: 235px
  .jq-selectbox__select
    width: 138px
.kpi_wrap
  display: flex
  margin-top: 5px

.manager_model
  margin-top: -5px

.manager_model a
  font-size: 12px

.superuser-managers__table-name div
  white-space: nowrap
  div
    display: inline-block
  div.iswork
    font-weight: bold
