.sattelit-articles__title-wrap
  margin-top: 110px
  display: flex
.sattelit-articles__title
  font-family: Montserrat
  font-size: 16px
  color: rgba(17, 15, 38, 0.6)
  font-weight: 500
  background-image: url(/public/img/arrow-right.png)
  background-repeat: no-repeat
  background-position-x: 65px
  background-position-y: 5px
  width: 85px
.sattelit-articles__all-articles-wrap
  width: 75%
  margin-top: 32px
.sattelit-articles__single-article
  display: flex
  box-shadow: 0 8px 8px -4px rgba(17, 15, 38, 0.12), 0 2px 4px -1px rgba(17, 15, 38, 0.12), 0 0 1px 0 rgba(17, 15, 38, 0.06)
  border-radius: 20px
.sattelit-articles__single-article
  margin-top: 35px
  img
    width: 254px
    height: 254px
    border-radius: 20px
  .sattelit-articles__single-article-content-wrap
    margin-left: 53px
    width: 60%
    h3
      font-family: Montserrat
      font-size: 20px
      font-weight: 600
      color: rgba(17, 15, 38, 0.8)
      margin-top: 35px
    p
      font-family: Montserrat
      font-size: 14px
      font-weight: 500
      color: rgba(17, 15, 38, 0.6)
      margin-top: 23px
    button
      height: 37px
      margin-top: 9px
      width: 84px
      a
        color: #db046b
.sattelit-articles__single-article-date
  font-family: Montserrat
  font-size: 14px
  color: rgba(17, 15, 38, 0.6)
  font-weight: 500
  float: right
  margin-top: 20px
.sattelit-articles__filter_wrap
  box-shadow: 0 8px 8px -4px rgba(17, 15, 38, 0.12), 0 2px 4px -1px rgba(17, 15, 38, 0.12), 0 0 1px 0 rgba(17, 15, 38, 0.06)
  border-radius: 20px
  width: 250px
  padding-top: 20px
  padding-bottom: 20px
  margin-top: 9px
.sattelit-articles__single-filter
  line-height: 70px
  padding-left: 20px
  cursor: pointer
  font-family: Montserrat
  font-size: 18px
  font-weight: 600
  color: rgba(17, 15, 38, 0.8)
  &:hover
    background-color: rgba(255, 47, 136, 0.06)
    border-left: 1px solid rgba(234, 8, 70, 0.8)
    color: #ff2f88
.sattelit-articles__mobile-filter-button
  float: right
  width: 50px
  height: 50px
  border-radius: 25px
  box-shadow: 0 7px 8px -4px rgba(255, 47, 136, 0.12), 0 10px 30px 0 rgba(255, 47, 136, 0.12), 0 2px 5px -1px rgba(43, 9, 80, 0.12), 0 0 1px 0 rgba(255, 47, 136, 0.03)
  margin-right: 10px
  margin-top: -35px
  background-image: url(/public/img/button-filter.png)
  background-repeat: no-repeat
  background-position-x: 8px
  background-position-y: 15px
  cursor: pointer
.sattelit-articles__mobile-filter-button.close-filter
  background-image: url(/public/img/close-filter.png)
  margin-right: 0px
  background-position-x: 10px
  background-position-y: 13px