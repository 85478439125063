.sattelit-article__read-more
	h3
		font-family: Montserrat
		font-size: 36px
		font-weight: bold
		color: rgba(17, 15, 38, 0.8)

.sattelit-article__stop-banner
	display: none
	width: 100%
	height: 300px
	background-color: rgba(17, 15, 38, 0.02)
	padding-top: 20px
	h3
		font-family: Montserrat
		font-size: 24px
		color: rgba(17, 15, 38, 0.8)
		text-align: center
	button.register
		width: 250px
		height: 50px
		margin-left: 25%
		a
			color: #fff
			font-family: Montserrat
			font-size: 16px
			font-weight: bold
	button.login
		height: 50px
		a
			color: #db046b
			font-family: Montserrat
			font-size: 16px
			font-weight: bold
