.manager-model__single
  &-note-wrapper
    display: grid
    flex-direction: column
    gap: 20px
    align-items: center
    padding: 20px
    @media (min-width: 768px)
      display: flex
      & > div > div
        display: grid
        width: 600px
    textarea
      padding: 20px!important
      margin: 0!important
    label
      width: auto!important
      margin: 0!important
      position: initial!important
      top: 0!important
      left: 0!important
  &-tickets
    padding: 20px
  &-wrapper
    width: 100%
    margin-top: 5px
    display: grid
  &-info
    margin-top: -4px
    width: 100%
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    margin-bottom: 30px
    overflow-x: auto
    &-table
      position: relative
      background: #fff
      border-radius: 5px
      color: #30173d
      font-family: Montserrat
      font-weight: 400
      font-size: 14px
      width: 100%
      & thead
        th
          font-family: 'Montserrat'
          font-weight: 600
          font-size: 16px
          width: 52px
          text-align: center
          height: 70px
          border-bottom: 1px solid #e4e4e4
          padding: 0 5px
          &:first-child
            text-align: left
            padding-left: 25px
            width: 200px
          &:last-child
            text-align: right
            padding-right: 25px
      & td
        width: 52px
        text-align: center
        height: 50px
        border-bottom: 1px solid #e4e4e4
        padding: 0 5px
        &:first-child
          padding-left: 25px
          text-align: left
          width: 200px
        &:last-child
          padding-right: 25px
          text-align: right
      tr
        & .manager-model__single-info-table-td:first-child,
        & .manager-model__single-info-table-th:first-child
          width: 259px
          text-align: left
          padding-left: 25px
        & .manager-model__single-info-table-td:last-child,
        & .manager-model__single-info-table-th:last-child
          width: 101px
          text-align: right
          padding-right: 25px
      &-bold
        font-weight: 600
    &-top
      padding: 25px 0 20px
      text-align: center
      font-family: Montserrat
      color: #30173d
      position: relative
    &-title
      font-size: 20px
      font-weight: 700
      padding: 0 20px
      &-status
        color: red !important
        font-weight: bold
    &-back
      width: 70px
      display: flex
      justify-content: space-between
      &-svg
        transform: rotate(90deg)
        position: relative
        top: 7px
      &:hover .manager-model__single-info-back-svg
        & g
          fill: rgba(219, 4, 107, 0.7)
    &-bottom
      display: flex
      flex-wrap: wrap
      padding: 16px 25px
      width: 100%
      & > div
        width: 317px
    &-pay, &-total, &-pay-hour, &-total-with-bonus
      display: flex
      width: 317px
      justify-content: space-between
      font-family: Montserrat
      color: #30173d
      font-size: 14px
      &-span
        font-weight: 600
        font-size: 16px
    &-pay
      margin-bottom: 0
    &-sort
      cursor: pointer
      float: right
      margin-right: -25px
      position: relative
      top: 1px
      &:hover g
        fill: #2f2f2f
  &-constuctor-checkbox
    position: relative
    margin-bottom: 9px
    label
      font-family: PFDinTextPro
      font-weight: 400
      font-size: 16px
      color: #30173d
      left: 45px
      top: 0
  &-constructor-block
    display: none
    position: absolute
    top: 0
    left: -201px
    width: 195px
    background: #fff
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    padding: 24px
    z-index: 4
  &-btns-right
    margin-left: 10px
    margin-top: -3px
  &-title
    margin-bottom: 17px!important
  &-ticket-btn
    width: 134px
    margin-bottom: 10px
    position: relative
    &:before
      content: ''
      position: absolute
      color: #db046b
      font-size: 30px
      font-weight: 400
      font-family: PFDinTextPro
      left: 9px
      top: -6px
  &-stream-btn
    width: 134px
    margin-bottom: 8px
    position: relative
    &:before
      content: ''
      position: absolute
      color: #db046b
      font-size: 30px
      font-weight: 400
      font-family: PFDinTextPro
      left: 9px
      top: -6px
  &-payment-btn
    width: 134px
  &-stream-add-btn
    width: 134px
    margin-bottom: 8px
    position: relative
    &:before
      content: ''
      position: absolute
      color: #db046b
      font-size: 30px
      font-weight: 400
      font-family: PFDinTextPro
      left: 9px
      top: -6px
  &-chat-btn
    width: 134px
  &-top-right
    margin-top: -2px
  &-work
    border-radius: 5px
    background-color: #ffffff
    margin-bottom: 100px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    & .nav
      display: grid
      @media (min-width: 576px)
        grid-template-columns: repeat(2, 1fr)
      @media (min-width: 992px)
        grid-template-columns: repeat(3, 1fr)
      @media (min-width: 1200px)
        grid-template-columns: repeat(4, 1fr)
      @media (min-width: 1600px)
        display: flex
        overflow-x: auto
      &:before
        content: none
    &-block-photo
      width: 242px
      height: 224px
      border-radius: 5px
      border: solid 1px #e4e4e4
      overflow: hidden
      background: #fff
      padding: 5px
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      position: relative
      margin-bottom: 25px
      &-svg
        position: relative
        top: 6px

    &-photo
      width: 100%
      height: 155px
      overflow: hidden
      img
        width: 100%
        height: auto
      & .approve, & .no-approve
        & a
          width: 100%
          height: 100%
      & .toggle-btns
        top: 99px
  &-tab
    text-transform: uppercase
    margin-bottom: 0!important
    text-align: center
    cursor: pointer
    border-bottom: 2px solid transparent
    &:hover, &.ui-tabs-active
      border-bottom: 2px solid #db046b
      .manager-model__single-tab-a
        color: #db046b
    &-a
      display: flex!important
      justify-content: center!important
      align-items: center!important
      height: 100%
      color: rgba(47, 47, 47, 0.5)
      font-family: PFDinTextPro
      font-size: 16px
      font-weight: 700
      border: none!important
      padding: 15px!important
      &:hover, &:focus
        color: #db046b!important
        background: #fff!important
    &-content
      display: flex
      justify-content: space-between
      padding: 40px
      flex-wrap: wrap
      row-gap: 20px
  &-download-photo
    &:hover g
      fill: rgba(219, 4, 107, 0.7)
  &-comment
    width: 544px
    margin: 30px auto
    height: 105px!important
    label
      width: auto!important
      margin: 0!important
      position: initial!important
      top: 0!important
      left: 0!important
    textarea
      padding: 20px
    &-lasttab
      padding: 40px
      & #commentForModel
        height: auto
        min-height: 105px
        margin: 0 auto -4px
      & label
        width: 514px
        left: 50%
        margin-left: -257px
      &-save
        margin: 0 auto
  &-approve-block-btn
    width: 100%
  &-approve-btn
    width: 242px
    margin: 0 auto
  &-download-photo
    margin-right: 13px
    margin-top: -4px
  &-delete-img
    width: 32px
    height: 32px
    border-radius: 50%
    position: absolute
    background: #fff
    right: 10px
    top: 10px
    cursor: pointer
    &:hover .manager-model__single-delete-img-svg g
      fill: rgba(47,47,47,1)
    &-svg
      position: relative
      left: 4px
      top: 3px
  &-chat
    bottom: 230px
    right: 18px
    position: absolute
    height: 40px
    width: 40px
    & .chat-messenger-block
      top: -415px
      position: relative
      right: 289px
      z-index: 6
      background: #fff
  &-constructor-block
    & .jq-checkbox.disabled
      pointer-events: none

.modal-box.history-stream
     width: 600px
     & .modal-content
        max-height: 670px
        min-height: 200px
        overflow-y: auto

.history-stream .modal-title
    font-size: 20px
    font-family: Montserrat
    font-weight: 700
    text-align: left
    width: 100%
    margin-bottom: 20px

.add-stream .modal-title
    font-size: 20px
    font-family: Montserrat
    font-weight: 700
    text-align: left
    width: 100%
    margin-bottom: 20px

.add-for-registrar .modal-title
    font-size: 20px
    font-family: Montserrat
    font-weight: 700
    text-align: left
    width: 100%
    margin-bottom: 20px

.history-stream-wrapper
    overflow: auto
    width: 100%
    & .history-stream-line
       width: 100%
       display: flex
       justify-content: flex-start
       margin-bottom: 10px
       font-family: Montserrat
       font-weight: 400
       color: #30173d
       margin-top: 5px
       min-height: 30px

.manager-main__stream-site
    position: relative
    width: 60px
    left: -11px
    top: 15px
    label
        margin-left: -30px

.manager-main__site-for-registrar
    position: relative
    width: 60px
    left: -11px
    top: 15px
    label
        margin-left: -30px

#select-current-day-styler .jq-selectbox__dropdown
  width: 130px !important

  & ul
    max-height: 200px

.make-big-photo
  margin-left: 15px
  margin-top: -3px
  &:hover g
    fill: rgba(219, 4, 107, 0.7)
.disabled
  pointer-events: none
  cursor: default
.approve.disabled, .np-approve.disabled
  cursor: default
  pointer-events: none
.approve.active, .no-approve.active
  g
    fill: rgba(47,47,47,0.3)
.work-with-sites
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  font-family: PFDinTextPro
  font-weight: 400
  color: #30173d
  font-size: 16px
  flex-direction: column
  &__left
    display: flex
    justify-content: space-between
    width: auto!important
    gap: 20px
  &__middle
    display: flex
    flex-direction: column
    width: auto!important
    margin-left: 0!important
  &__right
    display: flex
    flex-direction: column
    width: auto!important
    justify-content: space-between
  &__data
    display: flex
    flex-direction: column
    & .form-group
      width: 328px
      margin-top: 7px
      margin-bottom: -7px
  &__checkbox
    display: flex
    position: relative
    margin-bottom: 19px
    .jq-checkbox
      margin: 0
    &-label
      margin: 0 9px
  &__download-date
    color: rgba(47, 47, 47, 0.3)
    position: relative
    top: 2px
  &__save
    width: 234px
    position: relative
    top: -25px
    right: -10px
  &__lk
    font-weight: 500
    position: relative
    left: 0px
    text-align: right
    top: -5px
    &-status
      margin-left: 9px
      font-weight: 400
    &-noactive
      margin-left: 28px
    &-activation
      margin-top: 8px
.work-with-site
  border-bottom: 1px solid #e4e4e4
  padding: 40px 40px 14px
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  width: 100%
  &:last-child
    border: none
.fancybox-overlay-fixed
  position: absolue!important
.plan-name-model
  min-height: 59px
  color: #30173d
  font-size: 18px
  font-family: PFDinTextPro
  font-weight: 400
.work-with-sites__download-date
  display: none
.singlemodel__all
  td:first-child
    font-weight: 600
.plan
  &-date
    display: flex
    justify-content: space-between
  &-year
    flex-basis: 40%
    position: relative
    margin-top: 3px
  &-period
    flex-basis: 55%
.manager-model__single-top-btns
  width: 278px
  display: flex

#singlemodel .show-modal .modal-box
    margin-left: -180px
    left: 50%

.manager-main__holiday
  & span
     font-family: PFDinTextPro
     font-size: 16px
     font-weight: 400
     color: #30173d

.assist-operator
  &-checkbox
    width: 15px
    height: 15px
  &-text
    font-weight: bold

.model-data-container
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 20px
  &:nth-child(odd)
    grid-column: 1
  &:nth-child(even)
    grid-column: 2

.priority
  width: 130px
  margin-bottom: 0
  &-box
    display: flex
    flex-direction: column
    border: 1px solid rgba(47,47,47,.5)
    border-radius: 3px
    &-text
      text-align: center
      font-family: Montserrat,sans-serif
      color: rgba(47,47,47,.5)
      font-size: 16px
      font-weight: 400
      line-height: 1.42857143
    &-checkboxes
      display: flex
      justify-content: center
      margin-bottom: 5px

#singlemodel-documents, #singlemodel-document-photos
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 20px

.coaching-assign-title
  margin-bottom: 20px
  font-size: 20px

.btn-assign-coaching-wrapper
  margin-top: 8px

.add-coaching-assign-wrapper
  min-height: 200px
  display: flex
  flex-direction: column
  gap: 20px

.manager-model__single-reconciliation-btn
  width: 100%
  margin-top: 8px

.new-model-coaching
  font-size: 20px

.new-model-coaching__start-date
  font-weight: bold
  color: #30173d

.new-model-coaching__btn-change
  font-size: 14px
  color: #db046b
  background: none
  border: none
  padding: 0
  cursor: pointer
  outline: inherit

.singlemodel-page__education-title
  margin: 0
  font-size: 12px