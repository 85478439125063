html
  background: #fff
  height: 100%
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-orient: vertical
  -webkit-box-direction: normal
  -ms-flex-direction: column
  flex-direction: column
  position: relative

body
  margin: 0
  -webkit-box-orient: vertical
  -webkit-box-direction: normal
  -ms-flex-direction: column
  flex-direction: column
  -webkit-box-flex: 1
  -ms-flex: auto
  height: auto
  position: relative
/* colours
.grey
  color: #30173d
.white
  color: #fff
.pink
  color: #db046b
  &:hover
    color: rgba(219, 4, 107, 0.7)



/* MAIN BLOCK
.main
  width: 100%
  min-height: 100vh
  height: 100%
  min-width: 980px
  display: -webkit-box
  display: -ms-flexbox
  -webkit-box-orient: horizontal
  -webkit-box-direction: normal
  -ms-flex-direction: row
  flex-direction: row
  display: flex
  -webkit-box-flex: 1
  -ms-flex: auto
  flex: auto
  justify-content: space-between
  -webkit-box-sizing: border-box
  position: relative
  overflow: hidden
  padding-bottom: 180px
  .mobile-menu
    display: none
  .input
    border: none
    font-family: PFDinTextPro
    font-size: 1rem
    border-bottom: solid 1px #000
    background-color: transparent
    &:focus
      outline: none
    &::-webkit-input-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &::placeholder
      color: rgba(47, 47, 47, 0.5)
    &.pwd
      margin-top: 40px
.input-group
  position: relative
  margin-bottom: 35px
  font-family: PFDinTextProReg
  font-weight: 400
.text-field
  font-size: 1rem
  padding: 10px 10px 5px 5px
  display: block
  width: 461px
  border: none
  color: #30173d
  border-bottom: 1px solid #30173d
  &:focus
    outline: none
.text-field
  &:hover ~ label
    color: #30173d
  &:focus.text-field:invalid ~ label
    top: -20px
    font-size: 12px
    color: rgba(47, 47, 47, 0.5)
  &:invalid
    -webkit-box-shadow: none
    box-shadow: none
    border-bottom: 1px solid #30173d
  &:valid ~ label
    top: -20px
    font-size: 12px
    color: rgba(47, 47, 47, 0.5)
.bar
  position: relative
  display: block
  width: 476px
  &:before, &:after
    content: ''
    height: 2px
    width: 0
    bottom: 0px
    position: absolute
    background: #db046b
    transition: 0.2s ease all
    -moz-transition: 0.2s ease all
    -webkit-transition: 0.2s ease all
  &:before
    left: 50%
  &:after
    right: 50%
.text-field:focus ~ .bar
  &:before, &:after
    width: 50%
.highlight
  position: absolute
  height: 60%
  width: 100px
  top: 25%
  left: 0
  pointer-events: none
  opacity: 0.5
.text-field:focus ~ .highlight
  -webkit-animation: inputHighlighter 0.3s ease
  animation: inputHighlighter 0.3s ease
.reg-btn-action
  margin-top: 16px !important
  left: 3px
  position: relative
  line-height: 3.5
@-webkit-keyframes inputHighlighter
  from
    background: #db046b
  to
    width: 0
    background: transparent
@keyframes inputHighlighter
  from
    background: #db046b
  to
    width: 0
    background: transparent
/* mobile

.register-block
  a
    float: right
    clear: both
    margin-bottom: 15px
  position: relative
  margin-top: -120px
  width: 250px
  margin-left: 225px

.register-button
  margin-top: 10px

.alert
  &.alert-danger.alert-dismissible, &.alert-success.alert-dismissible
    width: 555px
    position: absolute
    margin-top: -571px
.ajaxpwd
  display: none

.g-recaptcha
  margin-top: 27px

.btn-modal-height
  height: 50px !important
  margin-top: 14px
  margin-bottom: 23px !important
.for_big_text
  overflow-y: scroll
  padding: 0 32px 0 17px !important

#file_load label
  position: static

.form-group__posit
  top: 16px !important

@media (max-width: 1600px)
  .center
    padding-left: 0px
.form-rec-marg
  margin-left: -1px


.close-block
  z-index: 5
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: none
.login_margin #login-form
  margin-top: -4px
.firsttab-text_mar
  margin-bottom: 14px
.firsttab .form-horizontal
  padding-top: 14px
.enter_text
  margin-bottom: 5px !important
.btn_wrap_enter_page
  .form-group
    margin-bottom: 15px
.register-button_password
  margin-top: 14px
.register-password-desc
  margin-top: 40px !important
.field-registerform-publicoffer
  height: 37px !important
.field-registerform-privacy
  height: 37px !important
  margin-bottom: 6px
.register-button-password
  margin-top: 33px
.no_center
  justify-content: unset !important
.modal-content img
  margin-top: 10px
  margin-bottom: 40px
  height: auto
  max-width: 500px
  width: auto
  max-height: 500px
.field-recoverform-username
  position: relative
  top: 4px
.step-wrap__marg
  margin-top: 11px
.field-doctypeform-doc_type
  width: 281px
.inner-page
  margin-top: 30px
  -webkit-box-flex: 1
  -ms-flex-positive: 1
  flex-grow: 1
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center
  -webkit-box-align: start
  -ms-flex-align: start
  align-items: flex-start
  z-index: 5
  flex-wrap: wrap
  margin-bottom: 100px

.button_wrap
  &.js-doc-identification
    top: 43px
  &.js-doc-identification-pair
    top: 129px
@-moz-document url-prefix()
  .lbl-head
    letter-spacing: -3px!important
  .model_data_wrap .checkbox-wrap__marg
    margin-top: 9px
    top: 6px
  .checkbox-title
    font-size: 12px
    color: rgba(47, 47, 47, 0.5) !important
.user-wrap
  flex-wrap: wrap
  align-items: unset
  height: auto
.bgc-girls:before
  content: ''
  width: 609px
  height: 100%
  background-image: radial-gradient(circle at 49% 23%, rgba(255, 255, 255, 0), #ffffff, #ffffff)
  background-image: url("../img/left-girl.png")
  background-repeat: no-repeat
  position: absolute
  left: 0
  top: 0
.bgc-girls:after
  content: ''
  width: 609px
  height: 100%
  background-image: radial-gradient(circle at 49% 23%, rgba(255, 255, 255, 0), #ffffff, #ffffff)
  background-image: url("../img/right-girl.png")
  background-repeat: no-repeat
  position: absolute
  right: 0 !important
  top: 0
.form-group__login-form
  margin-top: 35px
  top: 0
.preloader
  position: fixed
  left: 0px
  top: 0px
  width: 100%
  height: 100%
  z-index: 999999
  background: url("../img/DoubleRing.svg") center no-repeat rgba(255, 255, 255, 0.5)
  text-align: center
  display: none

.top5-models-index,.top5-models-view, .top5-models-create
  margin: 0 auto

.top5-models-update
  min-width: 300px
  margin: 40px auto

.top5-models-view a
  text-transform: uppercase
.buttons-wrapper
  display: flex
  justify-content: flex-start !important
  margin-bottom: 40px !important
  .btn
    width: 200px !important
    margin: 0
    margin-right: 20px
.news-index
  margin: 0 auto
  width: 97%
.news-view
  margin: 0 auto
  .btn-danger
    color: #d43f3a
  .btn-success
    border-color: #db046b
    color: #db046b
.news-create
  margin: 0 auto
  .news-form
    margin-top: 40px
  .field-news-title
    height: 140px !important
  .field-news-description
    height: 350px !important
.news-update
  margin: 20px auto
  h1
    margin: 40px 0 20px
  .news-form__input-file
    height: 200px
    display: flex
    align-items: center
    justify-content: flex-start
    img
      max-height: 200px
      margin-left: 40px
  .field-news-title
    height: 140px !important
    margin-top: 20px
  .field-news-description
    height: 680px !important
    .cke_contents
      height: 500px !important
.promotions-bonuses-index
  margin: 0 auto
  width: 97%
.promotions-bonuses-create
  margin: 0 auto
.promotions-bonuses-form
  margin-top: 40px
  .field-promotionsbonuses-title
    height: 140px !important
    margin-top: 20px
  .field-promotionsbonuses-description
    height: 680px !important
    .cke_contents
      height: 500px !important
  .promotions-bonuses-form__input-file
    height: 200px
    display: flex
    align-items: center
    justify-content: flex-start
    img
      max-height: 200px
      margin-left: 40px
.promotions-bonuses-update
  margin: 0 auto
.promotions-bonuses-view
  margin: 0 auto


.heading
  padding: 35px 40px 40px
  color: #30173d
  font-size: 20px
  font-weight: 700
  font-family: Montserrat, sans-serif

.heading-small
  padding: 0px 40px 20px
  color: #30173d
  font-size: 16px
  font-weight: 600
  font-family: montserrat, sans-serif
  margin: 0

.heading24
  padding: 0px 40px 24px 40px
  color: #30173d
  font-size: 24px
  font-weight: 600
  font-family: montserrat, sans-serif
  margin: 0

.heading36
  padding: 0px 40px 56px 40px
  color: #30173d
  font-size: 36px
  font-weight: 700
  font-family: montserrat, sans-serif
  margin: 0

.heading-pad0
  padding: 0px 0 40px
  color: #30173d
  font-size: 20px
  font-weight: 700
  font-family: Montserrat, sans-serif

.heading-small-pad0
  padding: 0px 0 20px
  color: #30173d
  font-size: 16px
  font-weight: 600
  font-family: montserrat, sans-serif
  margin: 0

.heading24-pad0
  padding: 0px 0 30px 0
  color: #30173d
  font-size: 24px
  font-weight: 600
  font-family: montserrat, sans-serif
  margin: 0

.heading36-pad0
  padding: 0px 0 56px 0
  color: #30173d
  font-size: 36px
  font-weight: 700
  font-family: montserrat, sans-serif
  margin: 0

.news-text-pad0 
  padding: 0px 0 0px 0
  color: #30173d
  font-family: PFDinTextPro
  font-weight: 400
  font-size: 16px
  line-height: 1
	
.news-text 
  padding: 0px 40px 0px 40px
  color: #30173d
  font-family: PFDinTextPro
  font-weight: 400
  font-size: 16px
  line-height: 1
  
.about-site-list 
  padding: 0px 0 0px 0
  color: #30173d
  font-family: PFDinTextPro
  font-weight: 400
  font-size: 16px
  line-height: 1


.article-title-h1,
.article-title-h1 span
  font-size: 36px
  font-weight: 700
  line-height: 26px
  font-family: Montserrat, sans-serif !important


.article-subtitle-h1,
.article-subtitle-h1 span
  line-height: 60px
  font-weight: 600
  font-size: 24px
  font-family: Montserrat, sans-serif !important
  color: rgba(17,15,38,.8)

.article-description-text,
.article-description-text span
  font-size: 16px
  line-height: 20px
  font-family: Montserrat, sans-serif !important

ul.article-ul
 list-style: none
 margin-left: -20px

ul.article-ul li::before
  content: "•"
  color: #ff2f88
  display: inline-block
  width: 15px
  margin-left: -15px
  font-size: 30px


table.article-table
  border: 1px solid #e4e4e4
  border-radius: 5px
  width: 100%
  table-layout: fixed

table.article-table td 
  border-bottom: 1px solid #e4e4e4
  border-top-style: none
  border-left-style: none
  width: 100%


table.article-table tr td:last-child
  border-right-style: none


table.article-table tr:last-child td
  border-bottom-style: none


.block-p
  font-family: Montserrat
  font-weight: 400
  font-size: 14px
  line-height: normal
  padding: 0 0 13px 0

.block-p-medium
  color: #30173d
  font-family: Montserrat, sans-serif
  font-size: 16px
  padding: 0 40px 40px
  font-weight: 500

.block-p-pad0
  color: #30173d
  font-family: PFDinTextPro, sans-serif
  font-size: 16px
  padding: 0 0 20px 0

.block-p-medium-pad0
  color: #30173d
  font-family: Montserrat, sans-serif
  font-size: 16px
  padding: 0 0 40px 0
  font-weight: 500

.table-style tr
  border: 1px solid #e4e4e4 !important

.table-style tr:first-child td
  border: none
  color: #30173d
  font-size: 14px
  font-family: Montserrat, sans-serif!important
  font-weight: 600
  padding: 25px 25px 15px
  background-color: rgba(228, 228, 228, 0.24)!important
  border-top: 1px solid #e4e4e4!important

.table-style td
  border: none
  color: #30173d
  font-size: 14px
  font-family: Montserrat, sans-serif!important
  font-weight: 400
  padding: 15px 25px 5px

.table-style td span
  font-size: 14px
  font-family: Montserrat, sans-serif!important
  font-weight: 400

.small-img-left
  float: left
  padding: 0 40px 30px
  max-width: 222px
  height: auto

.small-img-right
  float: right
  padding: 0 40px 30px
  max-width: 222px
  height: auto

.list-number
  font-size: 16px
  color: rgba(48, 23, 61, 0.8)
  font-family: Montserrat, sans-serif
  font-weight: 500
  padding: 0 40px

.list-number li
  margin-bottom: 30px

img.right
  border: 1px solid #ccc
  float: right
  margin-left: 15px
  padding: 5px


img.left
  border: 1px solid red
  float: left
  margin-right: 15px
  padding: 5px

img.zoomPhoto
  cursor: pointer
.sticky 
  position: sticky
  position: -webkit-sticky
  top: 0
  min-height: 2em
  background: white
  z-index: 9
