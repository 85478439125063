.footer
  min-height: 140px
  max-height: 180px
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  -ms-flex-wrap: wrap
  flex-wrap: wrap
  background: #30173d
  width: 100%
  min-width: 980px
  color: #eee
  padding: 30px 25px 20px 25px
  -webkit-box-sizing: border-box
  box-sizing: border-box
  bottom: 0 !important
  &.open
    display: none
  a
    font-family: "PFDinTextPro"
    font-weight: 300
    font-style: normal
  .footer-container
    width: 1140px
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-pack: justify
    -ms-flex-pack: justify
    justify-content: space-between
    .item
      font-family: PFDinTextPro
      font-weight: 300
      font-style: normal
      font-stretch: normal
      letter-spacing: normal
      -webkit-box-sizing: border-box
      box-sizing: border-box
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      display: -webkit-flex
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -ms-flex-direction: column
      flex-direction: column
      -webkit-box-pack: justify
      -ms-flex-pack: justify
      justify-content: space-between
      .sub-item
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        display: -webkit-flex
        margin-bottom: 15px
      .lnk
        color: #fff
      .btn
        width: 263px
        border: none
    .sub-item
      line-height: 16px
    .item1
      width: 182px
      height: 80px
      padding-left: 5px
      position: relative
      top: -1px
      .lbl
        line-height: 16px
        display: block
    .item2
      width: 121px
      height: 92px
      margin-left: -58px
      font-size: 16px
      position: relative
      top: -1px
      left: 2px
    .item3
      width: 140px
      height: 90px
      margin-right: -33px
      font-size: 16px
      position: relative
      top: -1px
    .item4
      width: 215px
      height: 80px
      font-size: 16px
      right: -11px
      position: relative
      top: -1px
    .item5
      width: 263px
      position: relative
      left: 8px
    .lbl
      font-family: PFDinTextPro
      font-size: 16px
      font-weight: 300
      font-style: normal
      font-stretch: normal
      line-height: normal
      letter-spacing: normal
      color: #ffffff
      padding-left: 5px
.modal__footer-modal
  display: none
  background-color: white
  -webkit-box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  width: 750px !important
  width: 100%
  left: 50%
  margin-top: auto
  margin-left: -375px !important
  top: 40px
  max-height: 538px
  position: absolute
  padding: 40px
  overflow: auto
  .modal-close__footer-modal
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    position: absolute
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center
    top: 20px
    right: 20px
    width: 16px
    height: 16px
    opacity: 0.3
    cursor: pointer
    &:hover
      opacity: 1
    &:before, &:after
      position: absolute
      content: ' '
      height: 16px
      width: 2px
      background-color: #333
    &:before
      -webkit-transform: rotate(45deg)
      transform: rotate(45deg)
    &:after
      -webkit-transform: rotate(-45deg)
      transform: rotate(-45deg)