.technical_specialist_note-button
  font-size: 12px
  cursor: pointer
  color: grey

.tickets-menu-wrapper
  display: flex
  gap: 15px

.tickets-page .manager-main__top
  justify-content: center
  margin-bottom: 0

.tickets-page .manager-main__constructor-block
  top: 44px
  left: 0


@media (max-device-width: 640px)
  .tickets-page .jq-checkbox
    top: 0
    margin-left: 0
