.modal-exclude-title
  font-family: Montserrat, serif
  font-weight: 700
  font-size: 20px
  color: #30173d
  margin-bottom: 45px

.modal-exclude-btn
  width: 280px
  margin: 0 auto

.modal-exclude
  width: 100%
  height: 100%

.exclude-reason-box
  padding-left: 5px !important
  padding-right: 0 !important
  width: 510px
  height: auto

.show-exclusion-window__texarea-wrapper
  margin: 0 auto

.show-exclusion-window__texarea-wrapper textarea
  height: 130px !important
  width: 100%

.error_exclusion_message
  color: #db046b
  font-size: 12px
  margin-top: 7px

.field-monitoringfilterform-condition
  height: 40px !important

.field-monitoringfilterform-tribe_id
  height: 40px !important

.monitoring__description
  padding: 10px 35px
  max-width: 700px

.monitoring__description-title
  width: 180px
  padding: 3px
  cursor: pointer
  border: 1px solid #42a5ff
  border-radius: 5px

.save-comment-btn
  height: 24px
  line-height: 1
  padding: 2px 5px !important
  font-size: 12px !important

.comment-input
  height: 24px
  padding: 2px 5px
  font-size: 12px
