@media (max-width: 1200px)
  #workcab
    .workcab-wrap
      @media (max-width: 1200px)
        grid-template-columns: repeat(3, 1fr)
      @media (max-width: 992px)
        grid-template-columns: repeat(2, 1fr)
      @media (max-width: 580px)
        grid-template-columns: initial

@media (max-width: 1199px)
  .header-user-wrap
    display: none
  .header
    .logo
      display: flex
      align-items: center
      font-size: 30px !important
  .nav
    order: 1
  .main
    .center
      margin-top: 60px !important
      left: 26.8333% !important
  .bgc-girls:after
    width: 500px !important

@media (max-width: 1139px)
  .user-wrap
    width: 98% !important
  .finance
    &__pay
      & .pay-info
        min-width: 100% !important

  .footer
    .footer-container
      .item5
        width: 225px !important

        .btn
          width: 225px !important

      .item
        margin-left: 0 !important
        margin-right: 0 !important

@media (max-width: 1020px)
  .main
    overflow-x: hidden
    overflow-y: auto
    min-width: 100%
  .footer
    min-width: 100% !important
  .logo-user
    display: none !important
  .nav-user
    display: none !important
  .notifications-user
    display: none !important
  .main
    .center
      left: 21.6327% !important
  .bgc-girls:before, .bgc-girls:after
    width: 391px !important
    background-size: contain
  .header
    padding: 16px 20px 16px 20px
    height: 70px
    min-width: 100% !important

    .header-user-wrap
      display: none

    .logo
      display: none !important
      order: 0

    .mobile-logo
      display: block !important
      order: 0
  .mobile-logo
    font-size: 16px !important
  header.header
    padding: 16px 20px 16px 20px
  header.header
    a.logo
      font-size: 1rem

    .nav-mobile
      display: block
      padding: 7px

    .close
      display: none
      -webkit-box-pack: center
      -ms-flex-pack: center
      justify-content: center
      width: 24px
      height: 24px

      &:hover
        opacity: 1

      &:before, &:after
        position: absolute
        content: ' '
        height: 24px
        width: 1px
        background-color: #333

      &:before
        -webkit-transform: rotate(45deg)
        transform: rotate(45deg)

      &:after
        -webkit-transform: rotate(-45deg)
        transform: rotate(-45deg)

    .burger
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      display: -webkit-flex
      -webkit-box-pack: justify
      -ms-flex-pack: justify
      justify-content: space-between
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -ms-flex-direction: column
      flex-direction: column
      width: 28px
      height: 21px
      cursor: pointer

      .burger-line
        background-color: black
        width: 28px
        height: 1px

@media (max-width: 992px)
  .header
    .logo
      font-size: 25px !important

@media (max-width: 900px)
  .main
    .center
      left: 17% !important

@media (max-width: 800px)
  .main
    .center
      left: 14% !important

@media (max-width: 768px)
  .header
    .logo
      font-size: 20px !important
  .main
    .center
      left: 11% !important

@media (max-width: 768px)
  .main-page__middle
    max-width: calc(100vw - 16px)!important
    width: 100%
    margin: 0 auto!important
  .finance__bunner
    margin-left: 0 !important

@media (max-width: 700px)
  .main .center
    left: 5% !important
  .no-boxshadow-mobile
    box-shadow: none !important

@media (max-width: 650px)
  .header
    .nav
      .lnk
        margin: 0!important
  .main
    min-width: 100%
    padding-bottom: 0

    .center
      width: 100% !important
      left: 0 !important
      margin: 0 !important
      display: block

      .login-form
        width: 100% !important
  .lnk.pink
    margin-top: -35px !important
  .button_wrap
    flex-direction: column
  .login-button, .btn.btn-secondary
    width: 100% !important
  .go_register_button_wrap
    margin-left: 0 !important
  .login-form.recover-form
    height: 100% !important
  footer.footer
    display: none
  main.main
    .left, .right
      display: none
  main.main .center .login-form .lbl-desc
    font-size: 16px
    margin: 40px 0px 35px 0px
    min-height: initial
    width: 320px
  .bgc-girls:before, .bgc-girls:after
    display: none
  .center .login-form
    min-height: 89.0625vh !important

    box-sizing: border-box

@media (max-width: 600px)
  .lnk.pink
    margin-top: -35px !important
  .button_wrap
    flex-direction: column
  .login-button, .btn.btn-secondary
    width: 100% !important
  .go_register_button_wrap
    margin-left: 0 !important
  footer.footer
    display: none
  main.main
    .left, .right
      display: none
  main.main .center .login-form .lbl-desc
    font-size: 16px
    margin: 40px 0px 35px 0px
    min-height: initial
    width: 320px

@media (max-width: 550px)
  .lnk.pink
    margin-top: -35px !important
  .button_wrap
    flex-direction: column
  .login-button, .btn.btn-secondary
    width: 100% !important
  .go_register_button_wrap
    margin-left: 0 !important
  .register-button-main
    width: 100% !important
  footer.footer
    display: none
  main.main
    .left, .right
      display: none
  main.main .center .login-form .lbl-desc
    font-size: 16px
    margin: 40px 0px 35px 0px
    min-height: initial
    width: 320px
  .login-page
    height: 80vh
  main .model-contacts__manager-img
    margin: 0 auto
  main .model-contacts__manager-info
    flex-basis: unset
    text-align: center
    justify-content: center
  .show-parse-window
    max-width: calc(100vw - 16px)!important

@media (max-width: 1060px)
  //h3.heading-pad0 ~ h3.heading-pad0
  //  display: none

  #modelHome
    div.popup-help
      left: 0

    .lvl-up-money
      div.popup-help
        right: 0
        left: unset
        top: unset
        bottom: 31px

  #modelfinance
    div.popup-help
      right: 0
      left: unset
      top: 20px

    .finance__pay
      div.popup-help
        right: unset

  main .model-contacts
    &__manager
      flex-basis: unset
      margin-bottom: 16px

    &__forum
      flex-basis: unset

      div.help-block-example div.popup-help:last-child
        left: 0

    &__our-departaments-main
      flex-wrap: wrap

    &__departaments
      flex-basis: unset
      width: 100%

      div.help-block-example div.popup-help:last-child
        left: 0

    &__question
      width: 100%
      flex-basis: unset

  #select-departament-styler
    width: 100%

    .jq-selectbox__select
      width: unset

  footer.footer div.footer-container div.item
    display: none

@media (max-width: 500px)
  #sattelit-articles .sattelit-articles__filter_wrap
    display: none
  #sattelit-articles .sattelit-articles__all-articles-wrap
    width: auto
  #sattelit-articles .sattelit-articles__all-articles-wrap .sattelit-articles__single-article
    width: 100%
    display: flex
    flex-direction: column
  #sattelit-articles .sattelit-articles__all-articles-wrap .sattelit-articles__single-article img
    width: 100%
  #sattelit-articles .sattelit-articles__all-articles-wrap .sattelit-articles__single-article .sattelit-articles__single-article-content-wrap
    width: 90%
    margin-left: 20px
    margin-bottom: 20px
  main .sattelit-home__big_article_wrap
    padding-left: 25px
  main .sattelit-home__big_article_wrap .sattelit-home__big_arcticle_desc_wrapp h3
    font-size: 36px
  main .sattelit-home__big_article_wrap .sattelit-home__big_arcticle_desc_wrapp
    max-width: 100%
  main .sattelit-home__four_news_wrap
    .sattelit-home__single_news
      width: 100%
  .public_offer_wrap
    flex-direction: column
  .register-button, .register-button-main
    width: 100% !important
  .main .center .login-form
    width: 500px
  .lnk.pink
    margin-top: -35px !important
  .button_wrap
    flex-direction: column
  .login-button, .btn.btn-secondary
    width: 100% !important
  .go_register_button_wrap
    margin-left: 0 !important
  footer.footer
    display: none
  main.main
    .left, .right
      display: none
  main.main .center .login-form .lbl-desc
    font-size: 16px
    margin: 40px 0px 35px 0px
    min-height: initial
    width: 320px
  .main
    &.login-page .lbl-head
      font-size: 45px !important
      letter-spacing: -1.9px !important
      padding-bottom: 24px !important
  .login-form.recover-form
    padding: 24px 16px 16px !important
  .recover-form .lbl-desc
    margin: 0 0 40px !important
  .center.recover
    height: 100% !important
  .login-form.recover-form
    min-height: 100% !important
    position: relative !important

    .form-rec
      height: 100%

      .form-horizontal
        display: flex
        flex-direction: column
        justify-content: space-between

      .btn-primary
        width: 100%
  .center.no_center
    height: 100% !important

    .login-form
      height: 100% !important
  .privacy_cond_modal, .public_offer_modal
    max-height: calc(100vh - 200px)!important
    height: 100%!important
    max-width: calc(100vw - 16px)!important
    width: 100% !important
    bottom: 0 !important
    margin-top: 6px !important
  .privacy_cond_modal .modal-big-text, .public_offer_modal .modal-big-text
    padding: 0 7px 0 4px !important
    font-size: 14px !important
  .btn-modal-height
    margin-bottom: 87px !important
  .checkbox-wrap__marg .single_box
    width: 100px
  .form-group .firsttab-btn-next
    width: 340px
  .form-rec-marg .step_wrap
    justify-content: flex-start
  .block_select_messenger .select_messenger
    width: 340px
  .doc_type_wrap .confirm-age__help-block
    margin-left: 0
    margin-top: 0
  .first_model_doc_photo_wrap .title
    text-align: center
  .doc_type_wrap .button_wrap .form-group .register-button
    margin-left: 0
  .doc_type_wrap .button_wrap
    top: 730px !important
    width: 100%
  .fourthtab .fourthtab-window.firsttab
    height: 1300px !important

@media (max-width: 450px)
  .field-registerform-publicoffer
    margin-bottom: 20px
  .privacy_cond
    margin-left: 25px !important
    margin-top: 0 !important
  .register-button, .register-button-main
    width: 100%
  .main .center .login-form
    width: 450px
  .lnk.pink
    margin-top: -35px !important
  .button_wrap
    flex-direction: column
  .login-button, .btn.btn-secondary
    width: 100% !important
  .go_register_button_wrap
    margin-left: 0 !important
  footer.footer
    display: none
  main.main
    .left, .right
      display: none
  main.main .center .login-form .lbl-desc
    font-size: 16px
    margin: 40px 0px 35px 0px
    min-height: initial
    width: 320px

@media (max-width: 414px)
  .field-registerform-publicoffer
    margin-bottom: 20px
  .privacy_cond
    margin-left: 25px !important
    margin-top: 0 !important
  .register-button, .register-button-main
    width: 100%
  .lnk.pink
    margin-top: -35px !important
  .button_wrap
    flex-direction: column
  .login-button, .btn.btn-secondary
    width: 100% !important
  .go_register_button_wrap
    margin-left: 0 !important
  footer.footer
    display: none
  main.main
    .left, .right
      display: none
  main.main .center .login-form .lbl-desc
    font-size: 16px
    margin: 0 0 35px 0px
    min-height: initial
    width: 320px
  .lbl-head
    height: initial !important
    line-height: 45px !important

@media (max-width: 380px)
  #modelfinance #modal_window_change_pay .login-form
    height: 1300px
  #modelHome #mainPageSliderMobile .slick-info
    max-width: 315px
  .information-with-sidebar .login-page img
    width: 100% !important
    height: auto !important
  #modelHome .show-parse-window .modal-content #reason_not_work
    width: 140px !important
  #modelHome .help-block-example .model_home
    width: 295px
  #modelHome .main-page__block-scale
    margin-left: -10px
  #modelContacts .model-contacts__departament .help-block-example:hover .popup-help
    left: 40px
  #lesson32
    width: 65%
  .information-with-sidebar .block-with-dropdown
    width: 100%
    padding: 15px 0px 5px
  .information-with-sidebar .block-with-dropdown h3
    width: calc(100% - 40px)

  div.help-information__help-menu
    width: 100%
    .nav
      width: 100%
      display: grid
      li
        width: 100%
  .help-information__help-menu svg
    position: absolute
    right: 8px
    top: 8px
    margin-right: 0 !important
  .help-information__tabs h3
    font-size: 16px !important
  .help-information__tabs table
    width: auto
  .help-information__tabs
    img
      width: 100%!important
  .help-information__tabs h3 strong span
    font-size: 16px !important
  #modelfinance .finance__pay-popup
    left: 0
    flex-direction: column
  #modelfinance .user-wrap .prepayment
    order: 1
  #modelfinance .user-wrap .finance__bunner
    order: 2
  #modelfinance .user-wrap .finance__settings
    margin-bottom: -25px
  #modelfinance .user-wrap .finance__pay .finance__pay-title
    display: flex
  .workcab .popup-instruction
    width: 320px
  .workcab .popup-instruction .popup__manual-content-wrap-
    width: 320px
  .workcab .popup-instruction img
    max-width: 250px
  .workcab .popup-about
    margin-top: 100px
    width: 320px
  .workcab .popup-about table
    width: 267px !important
    margin-left: -15px
  .workcab .popup-about .popup-about-text
    width: 250px
  .workcab .popup-about .popup-about-text img
    width: 230px !important
    height: 67.5px !important

  #modelHome .main-page__block-scale
    flex-direction: column
  #questionnaire .help-block-example .popup-help
    left: -155px

  #modelContacts .model-contacts__our-departaments-main
    flex-direction: column
    width: 325px
    margin-left: -20px
  #modelContacts .login-page
    height: auto !important
  #modelContacts .model-contacts__top-blocks
    flex-direction: column
    width: calc(100vw - 30px)
    margin-left: 0
  #modelContacts .model-contacts__our-departaments
    width: 380px
    margin-left: -15px
  .information-with-sidebar .login-page #tabsReference
    flex-direction: column
  #modelHome .login-page .main-page__middle
    display: block
  #modelHome .login-page
    height: auto !important
  #lquestionnaire-form .language-info-wrap .row-select
    width: 100%
  #lquestionnaire-form .language-info-wrap .row-select .block-select-lng
    width: 45% !important
  #lquestionnaire-form .language-info-wrap .row-select .block-select-lng-lvl
    width: 45% !important
  #lquestionnaire-form .language-info-wrap .row-select .block-select-lng__selector
    width: auto !important
  #lquestionnaire-form .language-info-wrap .row-select .block-select-lng-lvl__selector
    width: auto !important
  #lquestionnaire-form .language-info-wrap .lng-container__row
    width: 100%
  #lquestionnaire-form .language-info-wrap .lng-container__row .block-select-lng
    width: 45% !important
  #lquestionnaire-form .language-info-wrap .lng-container__row .block-select-lng-lvl
    width: 45% !important
  #lquestionnaire-form .language-info-wrap .lng-container__row .block-select-lng__selector
    width: auto !important
  #lquestionnaire-form .language-info-wrap .lng-container__row .block-select-lng-lvl__selector
    width: auto !important
  .worksheet .figure-type-wrap .jq-radio
    margin-top: 40px
  .step_wrap .tab
    padding-right: 0
  .help-block-example .popup-help.adaptive_fourthtab_photo
    left: -190px
    top: 40px
  .help-block-example .popup-help.adaptive_fourthtab
    left: 10px
    top: 40px
  .help-block-example .popup-help
    top: 40px
  .payment_block .pay_system
    margin-left: 53px
  .messenger_wrap .jq-radio
    margin-left: 53px
  .field-registerform-publicoffer
    margin-bottom: 20px
  .privacy_cond
    margin-left: 25px !important
    margin-top: 0 !important
  .register-button, .register-button-main
    width: 100%
  .main .center .login-form
    width: 375px
  .lnk.pink
    margin-top: -35px !important
  .button_wrap
    flex-direction: column
  .login-button, .btn.btn-secondary
    width: 100% !important
  .go_register_button_wrap
    margin-left: 0 !important
  footer.footer
    display: none
  main.main
    .left, .right
      display: none
  main.main .center .login-form .lbl-desc
    font-size: 16px
    min-height: initial
    width: 320px
  .main
    height: 1350px
  .lbl-head
    font-size: 4.5rem !important
    padding-bottom: 24px !important
  .main.login-page .lbl-head
    font-size: 40px !important
  .login-form
    padding: 16px !important
  .modal-box
    top: 16px
  .recover-form
    .lbl-desc
      margin-bottom: 0 !important
  .login-page
    height: 100vh !important
  .wrap-one-btn
    margin-top: 100px !important
    width: 100% !important
    position: absolute !important
  .register-password-desc
    margin-top: 14px !important

@media (max-width: 320px)
  header.header
    a
      .logo
        font-size: 0.8rem

    .nav
      display: none

    .nav-mobile
      display: block
      padding: 7px


@media (max-width: 320px)
  #modelHome .main-page__block-scale .main-page__block-scale-inner
    width: 311px
  #modelHome .main-page__block-scale
    margin-left: -20px
  #modelHome #mainPageSlider
    width: 320px
  .information-with-sidebar #helpInfoRules25
    margin-left: -125px
  .information-with-sidebar #helpInfoRules27
    margin-left: -125px
  #modelContacts .model-contacts__departament
    height: 65px
  .information-with-sidebar .help-information__fill-content
    margin-left: -125px
  #helpInfoRules58
    margin-left: 0px !important
  #helpInfoRules56
    margin-left: 0px !important
  #helpInfoRules28
    margin-left: 0px !important
    width: 320px
  .information-with-sidebar .block-with-dropdown
    width: 285px
    margin-left: 80px
    padding: 15px 0px 5px
  #information .block-with-dropdown h3
    width: 80%
  .mobile-menu-punkts
    width: 320px !important
  #modelContacts .model-contacts__our-departaments-main
    width: 265px
  #modelContacts .model-contacts__our-departaments
    width: 320px
    margin-left: -15px
  .help-block-example .popup-help.adaptive_fourthtab_photo
    left: -190px
    top: 40px
  .help-block-example .popup-help.adaptive_fourthtab
    left: 10px
    top: 40px
  .help-block-example .popup-help
    left: -115px
    top: 40px
  .payment_block .pay_system
    margin-left: 26px
  .messenger_wrap .jq-radio
    margin-left: 26px
  .block_select_messenger .select_messenger
    width: 285px
  .checkbox_wrap .single_box
    width: 90px
  .form-group .firsttab-btn-next
    width: 285px
  .field-registerform-publicoffer
    margin-bottom: 20px
  .privacy_cond
    margin-left: 24px !important
    margin-top: 0 !important
  .register-button, .register-button-main
    width: 100%
  #login-form
    width: 290px
  .lnk.pink
    margin-top: -35px !important
  .button_wrap
    flex-direction: column
  .login-button, .btn.btn-secondary
    width: 100% !important
  .go_register_button_wrap
    margin-left: 0 !important
  main.main
    max-width: 375px
    min-width: 300px

    .left, .right
      display: none

    .center
      padding-top: 0px
      -webkit-box-pack: start
      -ms-flex-pack: start
      justify-content: flex-start
      -webkit-box-align: start
      -ms-flex-align: start
      align-items: flex-start

      .login-form
        width: 320px
        min-height: 570px !important

        .lbl-head
          font-size: 2.5rem
          height: 32px

        .lbl-desc
          font-size: 16px
          margin: 0 0 35px 0
          min-height: initial
          width: 300px

        form
          .input-group
            margin-bottom: 45px

            .bar
              width: 343px

            .text-field
              width: 328px

          .bottom-group
            margin-bottom: 40px

          .group1
            margin-top: 40px

            .checkbox-contaiher
              top: auto

          .group2
            margin-top: 40px
            height: 156px
            -webkit-box-orient: vertical
            -webkit-box-direction: normal
            -ms-flex-direction: column
            flex-direction: column

            .btn
              width: 343px
  footer.footer
    display: none
  .main.login-page .lbl-head
    font-size: 35px !important

// Главная
#modelHome
  .container.login-page
    @media (max-width: 1200px)
      padding: 0

  .home-top
    @media (max-width: 1200px)
      grid-template-columns: initial

  .main-page
    @media (max-width: 650px)
      &__news-modal
        left: 8px!important
      &__bonus-modal, &__news-modal
        max-width: calc(100vw - 16px)
        margin-left: 0
        left: initial
        max-height: calc(100vh - 200px)
        .bonus-content, .modal-content
          width: 100%
          text-align: start

    &__news
      @media (max-width: 1200px)
        width: 970px
      @media (max-width: 992px)
        width: 750px
      @media (max-width: 768px)
        max-width: calc(100vw - 16px)
    &__middle
      @media (max-width: 1200px)
        grid-template-columns: initial

    &__finance
      @media (max-width: 1200px)
        margin-left: 0
        display: block
        max-width: 100vw
        top: 0
        position: static
        max-height: 100%
      @media (max-width: 768px)
        width: calc(100vw - 16px)

    &__stock
      @media (max-width: 1200px)
        width: 970px
      @media (max-width: 992px)
        width: 750px
      @media (max-width: 768px)
        width: calc(100vw - 16px)
        .slick.slick-slide img
          width: 100%
        .bonus-slider__bg
          width: 100%
        .slick-dots
          list-style: none
          position: absolute
          top: 4px
          right: 20px

    &__block-scale
      @media (max-width: 1200px)
        display: grid
        grid-template-columns: initial
      @media (max-width: 768px)
        width: calc(100vw - 16px)

        .model-home__scale
          display: none

        &-inner
          width: 100%

// Финансы
#modelfinance
  .secondtab
    @media (max-width: 750px)
      width: 100%!important
  .modal_window_more_money
    @media (max-width: 750px)
      max-width: calc(100vw - 16px)
      width: 100%!important
  @media (max-width: 1200px)
    .style-data
      display: none

    .finance__settings
      flex-wrap: wrap

      > div
        width: 100%
        margin-right: 0

        &:not(:last-child)
          margin-bottom: 16px

    .finance__pay, .prepayment
      width: calc(50% - 15px)


    .finance__chart
      display: none

    .finance__chart-pay
      margin: 16px auto 0

  @media(max-width: 768px)
    & .course-money
      width: 100%
      margin-right: 0

    & .prepayment
      width: 100%
      height: auto
      margin-right: 0
      .news-text
        padding: 0
        text-align: start

    & .finance__pay
      width: 100%

.mobile-menu-punkts
  @media (max-width: 1200px)
    margin-top: 0
    width: calc(100% - 30px)

.show_all_punkts_wrapper
  @media (max-width: 1200px)
    display: none
    position: sticky
    top: 0
    z-index: 1
    @media (max-width: 768px)
      display: block
      @media (max-width: 380px)
        width: 100vw


.information-with-sidebar
  div.help-information__fill-content
    table td
      padding: 10px 12px
      p
        margin: 0
    @media (max-width: 1200px)
      padding: 30px
      & > h3
        font-size: 32px
      @media (max-width: 768px)
        margin-left: 0
        & > h3
          text-align: center !important
          font-size: 24px
        table td *
          font-size: 12px !important
      @media (max-width: 380px)
        padding: 20px

@media (max-width: 721px)
  #modelfinance
    & .modal-box
      width: 90vw

@media (min-width: 750px)
  #modelfinance
    & .modal-box
      width: 750px

#register_second_step
  @media (max-width: 650px)
    .center
      .modal-box
        max-width: calc(100vw - 16px)
        width: 100%
  @media (max-width: 768px)
    .lbl-retry
      a
        font-size: 24px

#secondtab
  @media (max-width: 1200px)
    .contact_wrap.for_wallet
      flex-wrap: wrap

      .form-group.field-secondtabform-payment_system_number
        width: 100%
  @media (max-width: 768px)
    .popup-help
      left: 50%
      transform: translateX(-50%)
      top: unset
      bottom: 100%