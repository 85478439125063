.superuser-sites__period-select
  border: solid 1px #e4e4e4
  border-radius: 5px
  padding: 13px 20px
  font-family: PFDinTextPro
  font-size: 16px
  font-weight: 400
  color: #30173d
  margin-right: 30px
  position: relative
  height: 81px
  margin-top: -3px
.superuser-sites__period
  &-select
    display: flex
    width: 360px
    padding: 19px 20px 13px
  & .title
    font-weight: 700
    margin-bottom: 14px
.superuser-sites__period-select .jq-selectbox__select
  padding: 0 18px 0 0px
.superuser-sites__period-select-date li, .superuser-sites__period-select-date-second li
  &:nth-child(4)
    display: block
  &:nth-child(5)
    display: none
.superuser-sites__period-select-year
  width: 60px
.superuser-sites__period-select-date, .second-period
  width: 120px
.superuser-sites
  &__table-info
    margin-top: 30px
    width: 100%
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    overflow-x: auto
    margin-bottom: 20px
  &__table-table
    position: relative
    background: #fff
    color: #30173d
    font-family: Montserrat
    font-weight: 400
    font-size: 14px
    width: 100%
    td
      padding: 15px
      text-align: center
      height: 50px
      border-bottom: 1px solid #e4e4e4
      padding-top: 13px
    th
      font-family: 'Montserrat'
      font-weight: 600
      font-size: 16px
      text-align: center
      height: 70px
      outline: none
      padding: 15px
      border-bottom: 1px solid #e4e4e4
      position: relative
    tr
      td:first-child,
      th:first-child
        width: 200px
        text-align: left
        padding-left: 25px
    & tbody
      & tr
        &:first-child
          margin-top: 14px
          & td
            border-top: 1px solid #e4e4e4
        &:last-child
          margin-bottom: 14px
.superuser-sites
  &__select-tr
    display: table-footer-group!important
    td
      position: sticky
      bottom: 0
      background: white
      border-bottom: none
  &__top
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    gap: 20px
    height: auto!important
    @media (min-width: 768px)
      justify-content: center
    &-right
      display: flex
      align-items: center
      border-radius: 5px
      border: solid 1px #e4e4e4
      justify-content: space-around
      font-size: 16px
      font-family: 'Montserrat'
      font-weight: 600
      width: auto!important
      height: auto!important
      margin: 0!important
      flex-direction: column
      row-gap: 20px
      button
        width: auto!important
      & > div
        display: flex
      & .help-block-example
        margin-left: 5px
        height: 21px
        & img
          margin-top: -4px
          margin-left: 0
          margin-right: 0
          width: 24px
          height: 24px
    & .title p
      font-size: 20px
      font-family: 'Montserrat'
      font-weight: 700
  &__period
    min-width: 236px
    position: relative
    top: 14px
  &__csv
    width: 60px
  &__course
    width: 137px
  &__lvl-pay
    width: 205px
  &__add-pay
    width: 205px
    & button
      width: 97%!important
  &__period-date
    display: block
    color: #30173d
    font-size: 16px
    top: -7px
    font-family: 'Montserrat'
    font-weight: 600
    position: relative
  &__period-interval
    position: relative
    width: auto!important
    left: 0!important
    top: 0!important
    label
      margin: 0!important
      left: 0!important
      position: initial!important
      color: #30173d
  &__period-select
    justify-content: space-between
    padding: 20px
    height: auto!important
    gap: 20px
    align-items: center
    flex-direction: column
    width: auto!important
    margin: 0!important
    @media (min-width: 768px)
      flex-direction: row
      width: auto
    a
      margin: 0!important
    &-first
      top: 0!important
      display: flex
      column-gap: 10px
      position: relative
      width: auto!important
      & > div
        position: relative
        display: grid
        z-index: 15
    &-second
      margin: 0!important
      display: none
      column-gap: 10px
      position: relative
      & > div
        position: relative
        display: grid
        z-index: 15
  &__constructor
    & .btn-witdh-grey-border
      width: 134px
      margin-bottom: 8px
  &__plan
    width: 205px
    & button
      width: 97%!important
  &__table-info
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    margin-top: -1px
    table
      box-shadow: none
      border-radius: 5px
    &::-webkit-scrollbar
      height: 7px
      background-color: rgba(228, 228, 228, 0.5)
    &::-webkit-scrollbar-thumb
      background: rgba(47, 47, 47, 0.15)
      border-radius: 5px
    &::-webkit-scrollbar-track
      background: rgba(228, 228, 228, 0.5)
  &__constuctor-checkbox
    position: relative
    margin-bottom: 9px
    label
      font-family: PFDinTextPro
      font-weight: 400
      font-size: 16px
      color: #30173d
      left: 45px
      top: 0
  &__constructor-block
    display: none
    position: absolute
    top: 0
    left: -201px
    width: 195px
    background: #fff
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    padding: 24px
    z-index: 4
  &__constructor
    position: relative
  &__table-doc
    display: none
    &.not-load
      color: rgba(47, 47, 47, 0.5)
    &.load
      color: #0ccd9d
  &__quantity-line
    position: absolute!important
    right: 8px
    bottom: 13px
    width: 99px
.show-csv
  &.modal-box
    width: 360px
    height: 238px
    margin-left: -198px
    display: none
    margin-top: 135px
    & .modal-content
      padding: 35px 40px
    & .superuser-sites__period-date
      top: -24px
    & .modal-plan-title
      margin-bottom: 61px
.modal-csv-btns
  display: flex
  justify-content: space-between
  & .btn
    width: 130px
    margin: 0
.show-modal.modal-box
  display: none
.superuser-sites__select-line
  position: absolute
  height: 35px
  background: #ffffff
  bottom: 7px
  right: 23px
  width: 1132px
  display: block
  z-index: 2
.year-label, .period-label
  position: absolute
  top: -17px
  font-size: 12px
  color: rgba(47,47,47,.5)!important
  left: 15px
.show-course
  width: 360px
  height: 262px
  margin-left: -198px
  display: none
  margin-top: 135px
  & .modal-content
    padding: 35px 40px
  & .modal-course
    &-content
      top: -24px
      position: relative
      display: flex
      justify-content: space-between
      color: #30173d
      font-size: 16px
      font-weight: 600
      font-family: Montserrat
      margin-bottom: 20px
      & .form-group
        width: 128px
    &-btns
      width: 100%
      position: relative
      bottom: 31px
    &-now
      position: relative
      top: 8px
  & .modal-plan-title
    margin-bottom: 50px
.show-pay-add
  width: 360px
  height: 338px
  margin-left: -198px
  display: none
  margin-top: 135px
  & .modal-content
    padding: 35px 40px
  & .modal-pay-add
    &-content
      position: relative
      display: flex
      justify-content: space-between
      flex-direction: column
      color: #30173d
      font-size: 16px
      font-weight: 600
      font-family: Montserrat
      margin-bottom: 28px
      & .form-group
        width: 100%
    &-btns
      width: 100%
      position: relative
      bottom: 15px
    & .modal-plan-title
      margin-bottom: 35px
.show-pay-add-name
  margin-bottom: 20px
.total-line
  & tr
    display: table-footer-group!important
    &:hover
      cursor: default
    & th
      bottom: 0
      font-weight: 400
      height: 50px
      font-size: 14px
      &:first-child
        font-weight: 600
        font-size: 16px
.show-pay-add
  display: none
.modal-box.lvl-pay
  width: 750px
  height: auto
  margin-left: -375px
  display: none
  margin-top: 134px
  & .modal-content
    padding: 35px 0
  .modal-plan-title
    margin-bottom: 7px
    padding: 0 40px
.lvl-pay__table
  width: 100%
  height: auto
  tr
    th
      text-align: right
      padding: 19px 0
      font-weight: 600
      color: #30173d
      font-size: 16px
      border-bottom: 1px solid #e4e4e4
      font-family: Montserrat
      min-width: 95px
      &:first-child
        text-align: left
        padding: 19px 0 19px 40px
      &:last-child
        padding: 19px 40px 19px 0px
    td
      text-align: right
      padding: 16px 0
      font-weight: 400
      color: #30173d
      font-size: 14px
      border-bottom: 1px solid #e4e4e4
      font-family: Montserrat
      min-width: 95px
      height: 53px
      &:first-child
        text-align: left
        padding: 16px 0 16px 40px
      &:last-child
        padding: 16px 40px 16px 0px
.modal-lvl-pay-btns
  display: flex
  justify-content: space-between
  margin-top: 42px
  padding: 0 40px
  & .btn
    width: 150px
.modal-lvl-pay-delete.active
  background-image: linear-gradient(rgba(219,4,107,.8),rgba(219,4,107,.6)),linear-gradient(to bottom,rgba(219,4,107,.6),rgba(219,4,107,.7))
  outline: none!important
  transition: 0.3s!important
.show-plan
  .close-modal
    top: 20px!important
    right: 20px!important
.superuser-sites__help
  position: absolute!important
  top: 23px
  right: -14px
.superuser-sites__button-container
  display: grid
  width: auto
  height: auto
  justify-content: flex-end
  margin-top: 0!important
  row-gap: 10px
.superuser-sites__modal-button-container
  padding: 25px 0
  display: flex
  justify-content: center
