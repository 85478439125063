#modelfinance .user-wrap
  max-width: 1140px

#modal_window_change_pay
  position: fixed
  left: 50%!important
  transform: translateX(-50%)!important
  max-height: calc(100vh - 200px)
  label
    left: 0

.calendar
  display: flex
  width: 360px
  padding: 19px 20px 13px

  & > div
    position: relative

.finance__calendar-a
  margin-top: 17px
  left: -9px
  position: relative

.course-money, .style-data, .calendar, .pay-info
  border: solid 1px #e4e4e4
  border-radius: 5px
  padding: 13px 20px
  font-family: PFDinTextPro
  font-size: 16px
  font-weight: 400
  color: #30173d
  margin-right: 30px
  position: relative
  margin-top: -3px

.legendChartLines ul
  display: flex
  margin-left: -198px

.course-money, .pay-info
  .courseStyle
    font-size: 20px
    font-family: Montserrat
    font-weight: 400
    display: block
    margin-top: 5px

    span
      font-size: 16px
  min-width: 263px

.style-data
  min-width: 366px
  padding: 13px 13px
  display: flex
  flex-wrap: wrap

  &__span
    position: relative
    left: 5px

.prepayment
  .news-text
    padding: 0
  .btn
    top: 3px
    position: relative

.prepayment, .finance__pay, .finance__chart-pay
  .title
    padding-top: 2px

.finance
  &__settings
    display: flex
    width: 100%

    & .year-label, .period-label
      position: absolute !important
      top: 0

  &__pay
    width: 340px
    background: #fff
    padding: 25px 0
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    margin-top: 30px
    border-radius: 5px
    height: 365px
    display: flex
    justify-content: space-between
    flex-direction: column

    &-head
      height: 68px
      flex-basis: 100%
      justify-content: space-between
      align-items: center
      border-bottom: 1px solid #e4e4e4
      display: flex
      padding: 0 20px 20px

      &-title
        font-size: 16px
        font-family: Montserrat
        font-weight: 600
        color: #30173d

    &-pays-date
      font-size: 16px !important

    &-projected
      border: 1px solid #e4e4e4
      padding: 10px 15px
      display: flex
      justify-content: space-between
      font-family: PFDinTextPro
      font-weight: 400
      color: #30173d
      margin: 29px 25px 5px
      border-radius: 5px
      font-size: 16px

      & span
        font-size: 20px

      & > div
        position: relative

      & .help-block-example__span
        color: #30173d
        font-size: 16px

    &-title
      position: relative

      & .help-block-example
        float: right

      &-width
        width: 115px

    & .pay-info
      min-width: 212px

    &-a
      position: relative
      top: 7px
      margin-left: 25px

  &__line
    display: flex
    justify-content: space-between
    margin-left: 25px
    margin-top: 13px
    padding-bottom: 10px
    border-bottom: 1px solid #e4e4e4
    padding-right: 18px
    font-family: PFDinTextPro
    font-weight: 400
    color: #30173d
    font-size: 16px


  &__chart-pay
    width: 458px
    background: #fff
    padding: 25px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    margin-top: 30px
    border-radius: 5px
    height: 342px
    position: relative

    & .title
      &:before
        content: ''
        position: absolute
        width: 20px
        height: 20px
        background: #f4bc00
        border-radius: 50%
        top: 3px
        right: -28px

  &__bunner
    background-color: #573b7a
    background-image: url('../img/bunner-girl.png')
    background-position: right
    background-size: cover
    width: 280px
    padding: 25px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    margin-top: 30px
    margin-left: 30px
    border-radius: 5px
    height: 365px
    position: relative
    @media (max-width: 1200px)
      margin-left: 0
      width: 100%

    .title
      color: #fff
      font-family: 'Montserrat'
      font-weight: 700
      font-size: 34px
      padding-bottom: 16px

      &::before
        display: none !important

    .description
      color: #fff
      font-family: 'Montserrat'
      font-weight: 500
      font-size: 14px
      line-height: 20px
      letter-spacing: 0px

    .btn
      height: 50px
      width: 200px
      position: absolute
      bottom: 32px

  &__chart
    width: 100%
    background: #fff
    padding: 25px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    margin-top: 30px
    border-radius: 5px
    height: 375px
    position: relative
    display: block

    &-top
      display: flex
      justify-content: space-between

    & .title
      margin-left: -1px

.calendar .jq-selectbox__select
  padding: 0 18px 0 0px

.finance-period li
  &:nth-child(4)
    display: block

  &:nth-child(5)
    display: none

.year-label, .period-label
  position: relative
  top: -46px
  font-size: 12px
  color: rgba(47, 47, 47, 0.5) !important
  left: 15px

.style-data__schedule
  margin-bottom: 5px
  width: 50%

.courseRubStyle
  font-size: 16px

.finance-year, .finance-period
  margin-top: 20px
  margin-right: 20px

.finance-year
  width: 60px

.finance-period
  width: 120px

.finance__level
  margin-top: 30px
  width: 100%
  background: #fff
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
  border-radius: 5px
  max-height: 371px
  overflow-y: auto
  position: relative
  display: none

  &-level
    position: relative
    color: #30173d
    font-family: Montserrat
    font-weight: 400
    font-size: 18px
    width: 100%

    & thead tr th
      position: sticky
      top: 0
      background: white

    td
      width: 52px
      height: 50px
      border-bottom: 1px solid #e4e4e4

    th
      font-family: 'Montserrat'
      font-weight: 600
      font-size: 16px
      width: 52px
      height: 52px
      border-bottom: 1px solid #e4e4e4
      outline: none

    tr
      td:first-child,
      th:first-child
        text-align: left
        padding-left: 25px

      td:last-child,
      th:last-child
        padding-right: 25px

    tr:last-child
      td:last-child
        font-family: 'Montserrat'
        font-weight: 400
        position: relative

    & tfoot
      tr:last-child
        td:last-child
          font-family: 'Montserrat'
          font-weight: 600
          position: relative


.finance__table
  margin-top: 30px
  width: 100%
  background: #fff
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
  border-radius: 5px
  max-height: 371px
  overflow-y: auto
  position: relative
  display: none

  &::-webkit-scrollbar
    width: 5px

    background: rgba(228, 228, 228, 0.5)

  &::-webkit-scrollbar-thumb
    background: rgba(47, 47, 47, 0.15)
    border-radius: 5px

  &::-webkit-scrollbar-track
    background: rgba(228, 228, 228, 0.5)

  &-table
    position: relative
    color: #30173d
    font-family: Montserrat
    font-weight: 400
    font-size: 14px
    width: 100%

    & thead tr th
      position: sticky
      top: 0
      background: white

    td
      width: 52px
      text-align: center
      height: 50px
      border-bottom: 1px solid #e4e4e4

    th
      font-family: 'Montserrat'
      font-weight: 600
      font-size: 16px
      width: 52px
      text-align: center
      height: 52px
      border-bottom: 1px solid #e4e4e4
      outline: none

    tr
      td:first-child,
      th:first-child
        width: 259px
        text-align: left
        padding-left: 25px

      td:last-child,
      th:last-child
        width: 101px
        text-align: right
        padding-right: 25px

    tr:last-child
      td:last-child
        font-family: 'Montserrat'
        font-weight: 400
        position: relative

    & tfoot
      tr:last-child
        td:last-child
          font-family: 'Montserrat'
          font-weight: 600
          position: relative

    & .help-block-example
      float: right
      margin-right: -18px
      margin-left: 5px
      margin-top: -2px


.level-info
   display: inline-block

   & .help-block-example
       float: right
       padding-left: 10px

       & .popup-help
          top: -50px

.legend
  width: 120px
  position: absolute
  top: 90px
  right: 35px
  color: #30173d
  font-size: 16px
  font-family: PFDinTextPro
  font-weight: 400

.legend ul
  padding-left: 0

.legend ul li
  list-style: none
  position: relative
  margin-bottom: 14px
  display: flex
  flex-direction: column-reverse

  & div
    font-family: PFDinTextPro
    font-size: 16px
    font-weight: 500

  & .model_lvl_1
    color: #30173d

  & .model_lvl_2
    color: #f4bc00

  & .model_lvl_3
    color: #db046b

  & .model_lvl_4
    color: #43e2e2

  & .model_lvl_5
    color: #f16604

.legend ul li span
  width: 20px
  height: 20px
  position: absolute
  left: -30px
  top: 0
  margin-right: 5px
  border-radius: 50%
  background: transparent
  border: 3px

.legend ul li:before
  position: absolute
  width: 10px
  height: 10px
  top: 5px
  left: -25px
  background: #fff
  border-radius: 50%
  content: ''
  z-index: 3

.legendChartLines
  width: 300px
  position: absolute
  top: 20px
  right: 50%
  margin-right: 30px
  color: #30173d
  font-size: 16px
  font-family: PFDinTextPro
  font-weight: 400

.legendChartLines ul
  padding-left: 0
  margin-top: 30px

.legendChartLines ul li
  list-style: none
  position: relative
  display: inline-block
  margin: 7px 50px 25px 2px

.legendChartLines ul li span
  width: 15px
  height: 2px
  position: absolute
  left: -25px
  top: 11px
  margin-right: 5px
  background: transparent
  border: 3px

#densityChart
  position: relative
  z-index: 4
  margin-left: -100px
  margin-top: 34px

.ct-series-a .ct-bar
  stroke-width: 50px

#lineChart
  width: 100%
  height: 264px !important
  margin-top: 46px
  margin-left: -14px

.chart-total
  color: #30173d
  font-size: 16px
  font-family: PFDinTextPro
  font-weight: 400

  & .help-block-example__img
    margin-left: 10px

  &__span
    font-family: 'Montserrat'
    font-weight: 600
    position: relative
    font-size: 16px
    color: #30173d

  & .help-block-example
    float: right
    margin-top: -2px
    margin-right: -18px

.prepayment
  width: 420px
  background: #fff
  padding: 25px
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
  margin-top: 30px
  margin-right: 30px
  border-radius: 5px
  height: 365px

  .form-group, .btn
    width: 100%

  &-hide
    display: flex
    justify-content: space-between
    flex-direction: column
    height: 100%

.history-pay
  display: none
  z-index: 6 !important
  max-height: calc(100vh - 200px) !important
  overflow-y: scroll !important

  &::-webkit-scrollbar
    width: 5px

    background: rgba(228, 228, 228, 0.5)

  &::-webkit-scrollbar-thumb
    background: rgba(47, 47, 47, 0.15)
    border-radius: 5px

  &::-webkit-scrollbar-track
    background: rgba(228, 228, 228, 0.5)

  &-line
    width: 100%
    display: flex
    justify-content: flex-start
    margin-bottom: 10px
    font-family: Montserrat
    font-weight: 400
    color: #30173d
    margin-top: 5px
    min-height: 30px

  & .modal-content
    border: none
    box-shadow: none
    padding: 20px 0 0 20px
    flex-direction: column
    justify-content: flex-start
    flex-wrap: inherit

  & .modal-title
    font-size: 20px
    font-family: Montserrat
    font-weight: 700
    text-align: left
    width: 100%
    margin-bottom: 20px

.finance__table-table
  & .popup-help
    top: -111px

.table-total-info
  position: sticky
  height: 0px
  z-index: 20
  padding-right: 69px
  display: flex
  justify-content: flex-end
  width: 100%
  top: 0px

  & .help-block-example
    top: 13px

.finance__pay-title
  .help-block-example
    img
      margin: 0

.finance__pay-title.violet:before
  background: #30173d !important

.finance__pay-title.yellow:before
  background: #f4bc00 !important

.finance__pay-title.pink-color:before
  background: #db046b !important

.finance__pay-title.green:before
  background: #43e2e2 !important

.finance__pay-title.orange:before
  background: #f16604 !important

.modelfinance-paysystem-btn-wrapper
  margin-top: 55px
  display: flex
  justify-content: center

.model-finance__close-modal
  background: url(/web/public/img/close-grey.svg)
  width: 24px
  height: 24px
  position: absolute
  right: 20px
  top: 20px
  z-index: 40
  cursor: pointer

  &:hover
    background: url(/web/public/img/00-close.svg)

.history-pay-line-date
  margin-right: 20px
  margin-left: 20px
  overflow: hidden
  text-overflow: ellipsis

.history-pay-wrapper
  overflow: auto
  width: 100%

.history-pay-line-date-first
  margin-right: 0px !important
  margin-left: 0px !important

.modal_window_more_money
  max-height: calc(100vh - 200px)
  width: 703px
  overflow: hidden
  -webkit-box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  -webkit-box-flex: 1
  -ms-flex-positive: 1
  flex-grow: 1
  display: flex
  -webkit-box-align: start
  align-items: flex-start
  margin-top: 40px
  flex-direction: column
  margin-bottom: 100px
  z-index: 5
  transform: translateX(-50%)

  .secondtab
    height: 100% !important
    width: 715px !important
    background-color: #fff
    overflow-y: auto
    padding: 50px 20px !important

  .form-rec
    background-color: #fff

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
  .user-wrap
    margin: 0 !important


.bonus-modal-title
  margin-bottom: 24px
  color: #30173D

.bonus-list
  display: grid
  gap: 24px
  padding: 0
  margin-bottom: 24px

.bonus-item-header
  font-family: "Druk Wide Super"
  font-weight: 1000
  font-size: 20px
  line-height: 28px

.bonus-item
  color: #30173D
  padding: 12px
  border: 1px solid #30173D
  border-radius: 12px
  margin: 0
  display: grid
  gap: 8px

.bonus-item-description
  font-weight: 400
  font-size: 20px
  line-height: 28px

.bonus-item-status
  color: #DB046B
  font-weight: 700
  font-size: 20px
  line-height: 28px
