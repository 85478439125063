/* HEADER
.mobile-menu
    display: none

.mobile-logo
    font-family: "Druk Wide Super"
    font-size: 36px
    color: #30173d
    display: none

.nav-mobile
    display: none

.header
    width: 100%
    height: 70px
    margin: 0 0 3px
    padding: 10px
    min-width: 980px
    -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14)
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14)
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -ms-flex-wrap: wrap
    flex-wrap: wrap
    -webkit-box-pack: justify
    -ms-flex-pack: justify
    justify-content: space-between
    -webkit-box-sizing: border-box
    box-sizing: border-box
    z-index: 15
    background: #fff
    position: relative
    top: 0
    .nav
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        .btn
            width: 92px
            height: 50px
            font-family: PFDinTextPro
            font-weight: 700
            line-height: 35px
            font-size: 16px
        .lnk
            font-family: PFDinTextPro
            font-weight: 400
            margin: 0px 70px 0 0
            display: -webkit-box
            display: -ms-flexbox
            display: flex
            -webkit-box-pack: center
            -ms-flex-pack: center
            justify-content: center
            -webkit-box-align: center
            -ms-flex-align: center
            align-items: center
    .logo
        font-family: "Druk Wide Super"
        font-size: 36px
        color: #30173d

.sattelit-header
    height: 80px
/* HEADER USER
.header-user-wrap, .user-wrap
    width: 100%
    padding: 0 20px
    display: flex
    display: -ms-flexbox
    -webkit-box-pack: space-between
    -ms-flex-pack: space-between
    justify-content: space-between
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    margin: 0 auto
    z-index: 2
.header
    .logo-user
        margin-top: -10px
        font-family: "Druk Wide Super"
        font-size: 36px
        letter-spacing: 0.18em
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        color: #30173d
        & span
            font-family: LucidaGrande
            font-weight: 700
            font-size: 24px
            position: relative
            top: 0
    .sattelit-logo
        font-size: 24px
        width: 112%
        font-weight: bold
        letter-spacing: normal
        font-family: Montserrat
        margin-left: 9px
    .nav-user
        margin-top: -10px
        padding-top: 2px
        & > ul
            list-style: none
            margin: 0
            padding: 0
            height: 100%
            display: -webkit-box
            display: -ms-flexbox
            display: flex
            justify-content: space-around
            li
                height: 68px
                display: -webkit-box
                display: -ms-flexbox
                display: flex
                display: -webkit-flex
                -webkit-box-align: center
                -ms-flex-align: center
                align-items: center
                padding: 0 10px
                position: relative
            a
                &:hover
                    color: rgba(47, 47, 47, 1) !important
        a
            margin-top: 3px
        .current-page
            border-bottom: 2px solid #db046b
            a
                color: #db046b
                &:hover
                    color: #db046b !important
        a
            font-family: "PFDinTextPro"
            font-weight: 700
            font-style: normal
            color: rgba(47, 47, 47, 0.5)
            text-decoration: none
            display: block
        &:hover
            color: #2f2f2f
    .sattelit-nav
        margin-top: -14px
        margin-left: -25px
        a
            font-family: Montserrat
            font-size: 16px
            font-weight: 500
            color: rgba(17, 15, 38, 0.8)
    .nav-user_disable
        ul
            a
                color: rgba(47, 47, 47, 0.3) !important
                cursor: default
                &:hover
                    color: rgba(47, 47, 47, 0.3) !important
                    cursor: default
.header
    .notifications-user
        margin-top: -10px
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        display: -webkit-flex
        -webkit-box-pack: justify
        -ms-flex-pack: justify
        justify-content: flex-end
    .surface
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        display: -webkit-flex
        -webkit-box-pack: center
        -ms-flex-pack: center
        justify-content: center
        -webkit-box-align: center
        -ms-flex-align: center
        align-items: center
        position: relative
        margin-left: 15px
        .notifications-quantity
            position: absolute
            top: 0
            right: 0
            color: #fff
            font-family: "PFDinTextPro"
            font-size: 0.813em
            font-weight: 400
            padding: 0 4px
            text-align: center
            border-radius: 8px
            background-color: #db046b
            opacity: 1
            z-index: 1
        a
            font-style: normal
            text-decoration: none
            display: block
            &:hover
                font-style: normal
                text-decoration: none
                display: block
#burger-menu
    width: 28px
    height: 25px
    position: relative
    margin: 0px auto
    -webkit-transform: rotate(0deg)
    -moz-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)
    -webkit-transition: .5s ease-in-out
    -moz-transition: .5s ease-in-out
    -o-transition: .5s ease-in-out
    transition: .5s ease-in-out
    cursor: pointer
    .burger-menu__span
        display: block
        position: absolute
        height: 1px
        width: 14px
        background: #30173d
        opacity: 1
        -webkit-transform: rotate(0deg)
        -moz-transform: rotate(0deg)
        -o-transform: rotate(0deg)
        transform: rotate(0deg)
        -webkit-transition: .25s ease-in-out
        -moz-transition: .25s ease-in-out
        -o-transition: .25s ease-in-out
        transition: .25s ease-in-out
        &:nth-child(even)
            left: 50%
        &:nth-child(odd)
            left: 0px
        &:nth-child(1),
        &:nth-child(2)
            top: 2px
        &:nth-child(3),
        &:nth-child(4)
            top: 12px
        &:nth-child(5),
        &:nth-child(6)
            top: 23px
    &.open
        .burger-menu__span
            &:nth-child(1),
            &:nth-child(6)
                -webkit-transform: rotate(45deg)
                -moz-transform: rotate(45deg)
                -o-transform: rotate(45deg)
                transform: rotate(45deg)
            &:nth-child(2),
            &:nth-child(5)
                -webkit-transform: rotate(-45deg)
                -moz-transform: rotate(-45deg)
                -o-transform: rotate(-45deg)
                transform: rotate(-45deg)
            &:nth-child(1)
                left: -1px
                top: 9px
            &:nth-child(2)
                left: calc(50% - 5px)
                top: 9px
            &:nth-child(3)
                left: -50%
                opacity: 0
            &:nth-child(4)
                left: 100%
                opacity: 0
            &:nth-child(5)
                left: -1px
                top: 19px
            &:nth-child(6)
                left: calc(50% - 5px)
                top: 19px
.nav-mobile__menu
    height: 90vh
    width: 100%
    background: #fff
    z-index: -1
    position: absolute
    opacity: 0
    top: 76px
    left: 0
    display: none
    flex-direction: column
    justify-content: space-between
    &-ul
        list-style: none
        padding-left: 0
    &-li
        height: 71px
        border-bottom: 1px solid rgba(228, 228, 228, 0.5)
        padding: 26px 21px
    &-a
        color: #30173d
    &.open
        display: flex
        opacity: 1
        z-index: 10
.mobile-enter-btn
    margin-bottom: 50px
.main.open
    display: none
.more-menu
    position: relative
    &::before
        position: absolute
        top: -1px
        right: -24px
        width: 24px
        height: 24px
        background: url(../img/arrow-down.svg) no-repeat
        background-size: cover
        content: ''
    &:hover
        &:before
            background: url(../img/arrow-down-dark.svg) no-repeat
    &.active
        &:before
            transform: rotate(180deg)
            background: url(../img/arrow-down-dark.svg) no-repeat
            background-size: cover
.header-menu__dropmenu
    display: none
    position: absolute
    top: 49px
    background: #fff
    border-radius: 5px
    right: 0
    padding-left: 0
    z-index: 40
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    & li
        height: 40px!important
.header-menu__dropmenu.active
    display: block!important
.header-model-notification-block
    width: 161px!important

.no-full-access-workcab-link, .no-full-access-workcab-link-mobile
    position: relative
    color: #db046b !important
    opacity: 1
    animation: workcab-link 4s infinite

.no-full-access-workcab-link:hover
    opacity: 0.8

@keyframes workcab-link
    0%
        opacity: 1
        text-shadow: 0 0 7px #db046b
    50%
        opacity: 0.5
        text-shadow: 0 0 12px #db046b
    100%
        opacity: 1
        text-shadow: 0 0 7px #db046b

.no-full-access-workcab-link-arrow
    position: absolute
    bottom: 0
    left: 0
    transform: rotate(220deg) translateY(-40px)

.no-full-access-burger-arrow
    position: absolute
    bottom: 0
    left: 0
    transform: rotate(220deg) translateY(-70px)

.no-full-access-workcab-link-mobile-arrow
    position: absolute
    right: 100%
    top: 12px
    rotate: 90deg
    transform: translateY(-100px)

.no-full-access-workcab-link-arrow span, .no-full-access-burger-arrow span, .no-full-access-workcab-link-mobile span
    display: block
    width: 12px
    height: 12px
    border-bottom: 2px solid #db046b
    border-right: 2px solid #db046b
    margin: -10px
    animation: animate 2s infinite

.no-full-access-burger-menu > span
    background: #db046b !important
    box-shadow: 0 0 12px #db046b

.no-full-access-workcab-link-arrow span:nth-child(2), .no-full-access-burger-arrow span:nth-child(2), .no-full-access-workcab-link-mobile span:nth-child(2)
    animation-delay: -0.2s

.no-full-access-workcab-link-arrow span:nth-child(3), .no-full-access-burger-arrow span:nth-child(3), .no-full-access-workcab-link-mobile span:nth-child(3)
    animation-delay: -0.4s

@keyframes animate
    0%
        opacity: 0
        transform: rotate(45deg) translate(-20px, -20px)
    50%
        opacity: 1
    100%
        opacity: 0
        transform: rotate(45deg) translate(20px, 20px)
