.affiliate-page-common .main
  padding-top: 0
  max-width: 1140px
  margin: 0 auto

.affiliate-page-common .logo-user
  margin-top: 2px
  margin-left: 100px

.affiliate-page-common .balance
  border: none

.affiliate-page-common .referral-link
  margin: 10px auto
  text-align: center

.referral-link__text
  overflow-wrap: break-word

.affiliate-page-common .field-tablemodel-period
  margin-right: 30px

.affiliate__login-title
  text-align: center

.navbar-nav
  padding: 0 !important

.header .nav .btn
  width: auto
  height: 50px
  font-family: PFDinTextPro, sans-serif
  font-weight: 700
  line-height: 35px
  font-size: 16px

.form-control
  padding: 10px
  background-color: rgb(233, 239, 254)
  margin-top: 0

textarea.form-control
  padding: 10px

.form-control:focus
  border-bottom: 2px solid !important
  border-color: red !important

.field-loginform-rememberme
  height: auto !important

.field-rememberMe
  height: 50px !important

.field-currency,
.field-cardNumber,
.field-cardName,
.field-wallet
  display: none

.affiliate-page-common
  .jq-selectbox
    width: 100%
    height: 34px !important
    display: inline-block !important

.text-center
  text-align: center
  font-family: PFDinTextPro, sans-serif
  opacity: 0.7

.col-form-label
  margin-bottom: -10px

div.table
  margin-top: 15px
  overflow: auto

.periods
  margin-top: 30px
  margin-bottom: 20px

  .btn
    width: auto
    height: auto
    font-family: PFDinTextPro, sans-serif
    line-height: 35px
    font-size: 22px
    margin-top: -50px !important

  .table tr
    th, td
      text-align: center

#period-form
  text-align: center


.table
  thead
    text-wrap: nowrap

.period
  height: 48px
  width: 300px
  z-index: 10

  ul
    margin-left: -10px !important
    width: calc(100% + 10px) !important
    padding: 0

    li
      margin-left: 0 !important
      padding-left: 25px !important
      text-align: left

  .jq-selectbox__dropdown
    width: calc(100% - 10px) !important
    bottom: auto !important

  label
    text-align: left

  .periods .table table tfoot tr td
    font-weight: 600

  .type-changer
    display: none

  .hidden-start
    display: none

  .affiliate-link-choose__group:last
    display: block

  .affiliate-link-choose__radio-group-item
    display: inline-block

  .platform-changer
    display: initial

  .platform-changer 
    .affiliate-link-choose__radio-group-item
      margin-left: 1rem

  .footer
    position: relative !important

  .help-information__fill-content span
    display: initial

  #modelAffiliate 
    #referal-link
      color: #333
      background-color: #e6e6e6
      border-color: #cccccc

    #referal-link:focus
      color: #333
      background-color: #e6e6e6
      border-color: #cccccc

    #referal-link:hover
      color: #333
      background-color: #e6e6e6
      border-color: #cccccc

  .heading24-pad0
    padding: 0 0 30px 0
    color: #30173d
    font-size: 20px
    font-weight: 600
    font-family: montserrat, sans-serif
    margin: 0

  .block-p-medium-pad0
    text-indent: 1em
    text-align: justify

  .affiliate-link-choose
    width: 70%

/* Баланс блок */
.affiliate-report-balance
  justify-content: center
  padding-bottom: 30px
  border-bottom: 1px solid #e4e4e4

.affiliate-report-balance__items-block
  margin: 0 auto
  padding: 0
  list-style: none

  display: flex
  justify-content: center
  gap: 20px

.affiliate-report-balance__balance-view
  vertical-align: sub

.affiliate-report-balance__item-report-button
  width: auto
  height: auto
  margin: 0
  padding: 11px 12px
  font-family: PFDinTextPro, sans-serif
  font-size: 22px

.affiliate-report-balance__label
  font-family: PFDinTextPro, sans-serif
  font-weight: 500
  font-size: 24px
  color: rgba(17, 15, 38, 0.8)

.affiliate-report-balance__count
  color: #30173d
  font-family: 'Montserrat', sans-serif
  font-weight: 600

/* --------------end-------------- */

/*----------- postback-------- */
.postback-title
    margin-bottom: 20px
    text-align: center
    font-size: 26px
    font-weight: 700
    text-transform: uppercase

.postback__wrapper
    display: flex
    flex-direction: column

.affiliate-page-common label.postback__input-label
    margin-bottom: 20px
    font-size: 20px

.postback__url-input
    margin-bottom: 30px
    padding: 10px 20px
    font-size: 18px
    border: 1px solid #286090
    border-radius: 10px
    box-shadow: 0 0 10px #204d74

.postback__btn-url-submit
    width: 200px
    margin-bottom: 30px
    padding: 10px 20px
    background-color: #286090
    color: white
    border: none
    cursor: pointer
    box-shadow: 0 0 5px #204d74
    border-radius: 3px

.macros-description__item
    font-size: 20px

/* --------------end-------------- */

#tablemodel-period
  background-color: rgb(233, 239, 254)
  width: 100%

.period
  select
    background-color: rgb(233, 239, 254)
    width: 100%

    option
      background-color: rgb(233, 239, 254)
      width: 100%

  ul
    background-color: rgb(233, 239, 254)

    li
      background-color: rgb(233, 239, 254)
      width: 100%

      &.sel:enabled
        width: calc(100% + 40px) !important

.hr label
  font-size: 18px

ul
  &.hr li
    display: inline-block
    padding: 3px

    &.period ul li, &.year ul li
      display: block

  &.buttons
    padding-left: 0

    li
      list-style-type: none
      display: inline-block
      margin-right: 10px

.affiliate-table-header-th
  color: #db046b

/* Блок выбора ссылок  */

.affiliate-page-common label
  position: static
  margin: 0
  color: black

.affiliate-link-choose__wrapper
  margin: 10px auto
  min-width: 300px
  display: flex
  justify-content: center
  gap: 40px

.affiliate-link-choose__group
  display: flex
  flex-direction: column
  gap: 10px

@media only screen
  ul.hr li:first-child
    margin-right: 6px

@media (max-device-width: 640px)
  #tablemodel-period-styler
    /*margin-top: -25px*/

  .balance
    margin-top: 15vw

    .submit
      z-index: 999999

  ul.hr li:first-child
    margin-right: auto
    margin-left: auto

  .header
    height: fit-content
    margin-bottom: auto
    min-height: 65px
    padding-bottom: 6px

.balanceModalWindow table.table thead tr th:last-child
  opacity: 0.75

.balance ul.hr li:last-child
  vertical-align: middle

  button
    height: 50px

.field-resetform-email
  margin: 0
  padding: 0
  height: 0 !important

.btn-primary
  width: auto
  min-width: 138px

.profile
  width: auto !important
  min-width: 138px !important
  margin-top: 0
  margin-right: 20px

.btn-secondary
  width: auto
  min-width: 223px

.clipboardModalBackground
  display: none
  background: rgba(0, 0, 0, 0.8)
  position: fixed
  width: 100%
  height: 100%
  cursor: pointer
  z-index: 20

.clipboardModalActive
  position: absolute
  top: calc(50% - 250px)
  left: calc(50% - 175px)
  border-radius: 10px
  background-color: rgb(255, 255, 255)
  cursor: default
  padding: 40px 20px

.clipboardModalWindow
  position: relative

.balanceModalBackground
  display: none
  position: fixed !important
  top: 0
  left: 0
  margin: 0
  padding: 0
  background: rgba(0, 0, 0, 0.8)
  width: 100%
  height: 100%
  cursor: pointer
  z-index: 99991

.balanceModalActive
  position: absolute
  height: 595px
  width: 600px
  top: calc(50% - 300px)
  left: calc(50% - 175px)
  border-radius: 10px
  background-color: rgb(255, 255, 255)
  cursor: default
  padding: 40px 20px
  z-index: 999

.balanceModalClose
  position: absolute
  right: 5px
  top: 5px
  width: 30px
  height: 30px
  cursor: pointer

  svg
    margin: 3px
    width: 18px
    height: 18px

    &:hover
      margin-top: 2px
      margin-right: 5px
      width: 20px
      height: 20px

.balanceModalWindow
  position: relative
  height: 100%
  width: 100%
  overflow-y: auto

  table
    thead tr th
      text-align: center

    tbody tr td
      width: 100px
      text-align: center

      &:first-child
        width: 200px

      &:last-child
        width: auto
        text-align: left

    tfoot tr td
      font-weight: 600

.balanceModalClose svg path
  color: red
  stroke: currentcolor
  transition: .2s
  stroke-width: 0.5

@media (max-device-width: 640px)
  .main
    text-align: center

  .balanceModalClose
    position: absolute
    right: 40px
    top: 0
    cursor: pointer

    svg
      margin: 3px
      width: 40px
      height: 40px

  .balanceModalActive
    width: fit-content !important

  .balanceModalWindow .table
    font-size: 12px

.referal-link
  text-align: center
  margin-left: 60px
  margin-right: auto
  margin-top: 15px

  span
    font-weight: 600 !important
    margin-right: 5px

.site-reg .field-messenger ul li:last-child,
.site-profile .field-messenger ul li:last-child
  display: none

.site-reg .jq-selectbox__dropdown,
.site-profile .jq-selectbox__dropdown
  bottom: auto !important
  margin-left: -10px

@media (max-device-width: 640px)
  .referal-link
    margin-left: auto
    margin-right: auto
    margin-top: 40px

  .nav
    display: block !important
    right: auto

    a
      display: inline

  .referal-link
    span
      display: block
      width: 100%
      text-align: center

    a
      font-size: small

  .periods .submit
    display: none

  .jq-checkbox
    display: inline-block !important
    top: -26px
    margin-left: 150px

  .site-profile
    margin-top: 20vw

  .site-login
    width: 80vw

    ul.buttons li:last-child
      margin-top: 10vw

  ul.buttons li
    list-style-type: none
    display: inline-block
    margin-right: 0

  .alert-warning
    margin-top: 18vw
    margin-bottom: -18vw

@media (max-device-width: 460px)
  .affiliate-report-balance__items-block
    flex-direction: column
    align-items: center
