.service-page
  max-width: 1080px
  margin: 0 auto
  padding: 0 10px

.service-page .active-tribes-choose
  margin: 0 auto
  width: 300px
  justify-content: center

.active-tribes-choose__title
  color: #30173d
  font-weight: 600
  font-size: 16px

.active-tribes-choose__wrapper
  display: flex
  justify-content: space-around
  border-radius: 5px
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
  width: 300px
  padding: 25px
  column-gap: 20px

.active-tribes-choose__checkbox-wrapper
  width: 20px
  position: relative
  display: flex

.service-page .active-tribes-choose__label
  top: 2px
  font-family: PFDinTextPro, serif
  font-weight: 400
  font-size: 16px
  color: #30173d

@media (max-device-width: 640px)
.service-page .active-tribes-choose .jq-checkbox
  display: inline-block !important
  top: 0
  margin-left: 0
