$verification-button-color-bg: #c6538c
$verification-button-color: white
$font: 'PFDinTextPro', sans-serif
$message-color: #00008B

.verification-page .visually-hidden
  position: absolute

  width: 1px
  height: 1px
  margin: -1px
  border: 0
  padding: 0

  white-space: nowrap

  clip-path: inset(100%)

  clip: rect(0 0 0 0)
  overflow: hidden

.verification-page .loader
  border: 2px solid
  border-color: transparent #6ebeff
  width: 48px
  height: 48px
  border-radius: 50%
  display: inline-block
  position: relative
  box-sizing: border-box
  animation: rotation 2s linear infinite

.verification-page .loader::after
  content: ''
  box-sizing: border-box
  position: absolute
  left: 50%
  top: 50%
  border: 24px solid
  border-color: transparent rgba(51, 122, 183, 0.15)
  border-radius: 50%
  transform: translate(-50%, -50%)


@keyframes rotation
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)


/* стили для страницы верификации */
.verification-page
  max-width: 480px
  margin: 0 auto
  text-align: center

.verification-page__title
  font-family: $font
  text-transform: uppercase
  font-weight: 700

.verification-page__description
  margin-bottom: 24px
  font-family: $font
  font-size: 16px

.verification-page__getting-link-message
  margin-bottom: 24px
  font-family: $font
  font-size: 16px
  color: $message-color

.verification-page__link-timer
  font-size: 27px
  font-weight: bold

.verification-page__get-link-button
  padding: 11px 12px
  display: inline-block

  color: $verification-button-color
  background-color: $verification-button-color-bg
  border: none
  border-radius: 5px

  font-family: inherit
  text-align: center
  text-transform: uppercase
  text-decoration: none

