.single_photo_flex_wrap
  display: flex
  margin-bottom: -19px
  flex-wrap: wrap
.single_main_photo_wrap .jq-file
  width: 70px
  border-radius: 4px
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
  opacity: 0
  height: 19px
.single_main_photo_wrap
  width: 138px
  margin-right: 20px
  margin-top: 32px
.single_photo
  margin-bottom: 7px
  &__img
    width: 138px
    height: 138px
    object-fit: cover
    cursor: pointer
.photo_desk
  font-family: 'PFDinTextPro'
  font-weight: 400
  font-size: 16px
  left: 3px
  position: relative
  margin-bottom: 6px
#ajax_send_document_photo-styler
  opacity: 0
    width: 73px
    cursor: pointer
    z-index: 2
    font-size: 0
    height: 20px
  &:before
    content: 'Загрузить дополнительные фотографии'
    position: absolute
    color: #db046b
    top: -2px
    left: 3px
    font-size: 16px
    cursor: pointer
    font-family: 'PFDinTextPro'
    font-weight: 400
    &:hover:before
      color: rgba(219, 4, 107, 0.7)
#ajax_send_document-styler
  opacity: 0
    width: 73px
    cursor: pointer
    z-index: 2
    font-size: 0
    height: 20px
  &:before
    content: 'Загрузить дополнительные фотографии'
    position: absolute
    color: #db046b
    top: -2px
    left: 3px
    font-size: 16px
    cursor: pointer
    font-family: 'PFDinTextPro'
    font-weight: 400
    &:hover:before
      color: rgba(219, 4, 107, 0.7)
.download-photo
  position: relative
  width: 75px
  .ajax_send_photo
    opacity: 0
    width: 73px
    cursor: pointer
    z-index: 2
    font-size: 0
    height: 20px
  &:before
    content: 'Загрузить'
    position: absolute
    color: #db046b
    top: -2px
    left: 3px
    font-size: 16px
    cursor: pointer
    font-family: 'PFDinTextPro'
    font-weight: 400
    &:hover:before
      color: rgba(219, 4, 107, 0.7)
.filebutton_ajax_send_image
  overflow: hidden
  position: relative
  background-color: #ccc
  pointer-events: auto
  display: inline
  margin-left: 0
  cursor: pointer
  top: 0
  width: 138px
  height: 138px
  display: block
  & img
    margin-bottom: 7px
    width: 100%
    height: 100%
#myfile
  z-index: 999
  line-height: 0
  font-size: 50px
  position: absolute
  top: -2px
  left: -700px
  opacity: 0
  filter: alpha(opacity = 0)
  -ms-filter: "alpha(opacity=0)"
  cursor: pointer
  _cursor: hand
  margin: 0
  padding: 0
.fourthtab
  .single_photo__img
    width: 215px
    height: 215px
