.model-contacts
  &__top-blocks
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    margin-top: 30px
  &__manager
    width: 100%

    display: flex
    justify-content: space-between
    flex-wrap: wrap
    padding: 35px 35px 44px 35px
    border-radius: 5px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    flex-basis: 36%
    &-img
      max-width: 138px
      max-height: 138px
      top: 5px
      position: relative
      left: 4px
      & img
        width: 100%
        height: 100%
        border-radius: 50%
    &-info
      display: flex
      flex-wrap: wrap
      flex-basis: 50%
      justify-content: space-between
      padding-left: 4px
      &-name
        font-family: Montserrat
        font-size: 16px
        font-weight: 600
        font-style: normal
        font-stretch: normal
        line-height: normal
        letter-spacing: normal
        color: #30173d
        flex-basis: 100%
        margin-bottom: 16px
      &-messenger
        flex-basis: 100%
        font-family: PFDinTextPro
        font-size: 16px
        font-weight: normal
        font-style: normal
        font-stretch: normal
        line-height: normal
        letter-spacing: normal
        color: #30173d
        & span
          font-size: 13px
          display: block
          color: rgba(47, 47, 47, 0.5)
          position: relative
          top: -4px
    & .model-contacts__title
      flex-basis: 49%
      margin-top: 6px
      position: relative
      margin-bottom: 19px
  &__title
    font-family: Montserrat
    font-size: 20px
    font-weight: 700
    font-style: normal
    font-stretch: normal
    line-height: normal
    letter-spacing: normal
    color: #30173d
    margin-bottom: 24px
  &__forum
    width: 100%
    flex-basis: 63%

    border-radius: 5px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    padding: 40px
    font-family: Montserrat
    font-size: 16px
    font-weight: 400
    font-style: normal
    font-stretch: normal
    line-height: 1.38
    letter-spacing: normal
    color: #30173d
    &-text
      max-width: 100%
      & a
        font-family: Montserrat
        font-weight: 400
  &__our-departaments
    border-radius: 5px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    margin-top: 30px
    padding: 40px
    width: 100%
    margin-bottom: 125px
    &-top
      margin-bottom: 16px
      display: flex
      justify-content: space-between
    &-status
      font-family: Montserrat
      font-size: 16px
      font-weight: 400
      font-style: normal
      font-stretch: normal
      line-height: 1.38
      letter-spacing: normal
      color: #30173d
      position: relative
      top: 4px
      & a
        font-family: Montserrat
        font-weight: 400
    &-main
      display: flex
      justify-content: space-between
  &__departaments
    flex-basis: 30%
  &__departament
    width: 100%
    border-radius: 5px
    border: solid 1px #e4e4e4
    padding: 15px 21px
    margin-bottom: 8px
    height: 50px
    font-family: Montserrat
    font-size: 16px
    font-weight: 600
    & .help-block-example
      width: 100%
      display: flex
      justify-content: space-between
      &__img
        margin-right: -12px
        width: 24px
        height: 24px
        top: -3px
  &__question
    display: flex
    flex-direction: column
    align-items: center
    gap: 30px
    border-radius: 5px
    border: solid 1px #e4e4e4
    flex-basis: 69%
    padding: 19px 21px 11px 24px
  &__select
    max-width: 328px
    margin: 24px auto 18px
    position: relative
    left: 7px
    & .jq-selectbox__select
      width: 328px
  &__textarea
    width: 100%
    margin: 0!important
    height: auto!important
    position: relative
  &__btn
    width: 136px
    margin: 0 auto
    position: relative
    top: -12px
  &__send-message
    letter-spacing: 1px
    width: 204px
    margin-right: -4px
.select-departament-label
    position: absolute
    top: -21px
    font-size: 12px
    color: rgba(47,47,47,.5)!important
    left: 15px
.model-contacts__departament
  .help-block-example
    &:hover .popup-help
      left: 288px
