#modelHome
  & > .login-page
    height: auto!important
    gap: 30px
    display: grid
    margin-bottom: 30px

.main-page
  &__stock
    width: calc(1170px / 3 * 2 - 10px)
    background: #fff
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    display: inline-block
    position: relative
    padding: 0 5px 5px

  &__title
    font-size: 16px
    font-family: Montserrat
    font-weight: 600
    color: #30173d

  &__head
    height: 68px
    flex-basis: 100%
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid #e4e4e4
    display: flex
    padding: 0 20px

  &__finance
    background: #fff
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    display: inline-block
    position: relative

    & .main-page__title
      margin-bottom: 11px
      margin-left: 5px
      padding-top: 20px

    &-pay-projected
      display: flex
      justify-content: space-between

      & span
        font-size: 20px

      & > div
        position: relative

      & .help-block-example__span
        color: #30173d
        font-size: 16px

  &__line
    display: flex
    justify-content: space-between
    padding: 10px 18px
    border-bottom: 1px solid #e4e4e4
    font-family: PFDinTextPro
    font-weight: 400
    color: #30173d
    font-size: 16px

    & span
      font-size: 18px

  &__middle
    padding: 0
    display: grid!important
    grid-template-columns: 1fr 1fr 1fr
    gap: 30px
    justify-content: space-between
    position: relative

    &::before
      display: none!important
    &::after
      display: none!important

    & .main-page__head
      height: 74px

    & .main-page__title
      left: 5px
      position: relative
      top: 6px

    & .help-block-example__span
      font-family: Montserrat
      font-weight: 600
      color: #30173d

  &__webinar
    border-radius: 5px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    background: #ffffff
    flex-basis: 100%
    padding-bottom: 8px

    & .help-block-example__span
      color: #30173d
      font-size: 16px
      font-family: Montserrat
      font-weight: 600

    &-title
      font-family: PFDinTextPro
      font-weight: 500
      font-size: 14px
      margin-left: 25px
      border-bottom: 1px solid #e4e4e4
      padding-bottom: 12px
      letter-spacing: 1px

      & span
        color: #30173d

    &-nearest
      margin-top: 40px
      color: #30173d

      & .main-page__webinar-title
        font-weight: 400
        border-radius: 5px
        font-size: 16px
        letter-spacing: 0
        border: 1px solid #e4e4e4
        padding: 10px
        margin-right: 25px

        & span
           float: right
           font-size: 18px

    &-my
      margin-top: 23px

      & .main-page__line span
         float: right
         margin-right: 20px

    &--soon
      padding-left: 25px
      padding-top: 15px
      font-family: "Druk Wide Super"

  &__news
    width: calc(1170px / 3 - 20px)
    margin: 0!important
    border-radius: 5px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    background: #ffffff
    flex-basis: 100%
    padding: 0

  &__top
    margin: 0!important
    border-radius: 5px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    background: #ffffff
    flex-basis: 100%
    padding-bottom: 13px

    & .main-page__head
      margin-bottom: 7px

    &-line
      border-bottom: 1px solid #e4e4e4
      font-family: PFDinTextPro
      color: #30173d
      font-size: 16px
      font-weight: 400
      display: flex
      justify-content: space-between
      padding: 10px 18px
      align-items: center

      & > div
        display: flex
        align-items: center

      & span
        font-size: 18px

  &__block-scale
    width: 100%
    margin: 0 auto!important
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr
    display: grid
    gap: 4px
    border-radius: 5px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    background-color: #ffffff
    padding: 5px

    &-inner
      font-family: Montserrat
      font-size: 20px
      font-weight: 400
      color: #30173d
      border: 1px solid #e4e4e4
      padding: 16px 20px 14px
      border-radius: 5px

      & span
        font-size: 16px
        display: block
        font-family: PFDinTextPro
        position: relative

  &__scale
    background: #e4e4e4
    border-radius: 2.5px
    height: 5px
    max-width: 570px
    width: 100%
    position: relative
    margin: 38px 43px 0

    &-pink
      position: absolute
      height: 5px
      top: 0
      left: 0
      background: #db046b
      border-radius: 2.5px
  &__bonus-modal, &__news-modal
    display: none
    width: 555px
    margin-left: -270px
    left: 50%
    top: 50%
    transform: translateY(-50%)
    background: #ffffff
    box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
    max-height: 538px
    min-height: 20px
    position: fixed
    overflow: hidden
    z-index: 50

    .bonus-content, .news-content
      padding: 10px
      max-height: calc(100vh - 200px)
      height: 100%
      overflow-x: hidden
      overflow-y: scroll
      h3
        font-size: 16px !important
        margin: 10px
      img
        width: calc(100% + 20px)!important
        height: auto!important
        object-fit: cover
        margin: -10px -10px 0 -10px
      .news-text
        padding: 0

    .close-modal
      position: absolute
      width: 24px
      height: 24px
      background-color: rgba(47, 47, 47, 0.5)
      background: url(../img/close-grey.svg) no-repeat
      right: 20px
      top: 20px
      content: ''
      background-size: 100%
      cursor: pointer

      &:hover
        background: url(../img/00-close.svg) no-repeat
        background-size: 100%

  &__message
    width: 282px
    height: 80px
    display: flex
    position: absolute
    background: #fff
    border-radius: 5px
    bottom: 278px
    z-index: 22
    right: 30px
    color: #30173d
    font-size: 14px
    font-family: PFDinTextPro
    font-weight: 400
    padding: 10px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    padding-right: 40px

    & span
      color: rgba(47, 47, 47, 0.3)
      display: block

    & img
      border-radius: 5px
      width: 60px
      height: 60px
      margin-right: 10px

.close-message
  position: absolute
  width: 16px
  height: 16px
  background: url(../img/close-grey.svg) no-repeat
  background-size: 100%
  top: 12px
  right: 12px
  cursor: pointer

  &:hover
    background: url(../img/00-close.svg) no-repeat
    background-size: 100%

.first-letter-name
  color: #fff
  font-family: Montserrat
  font-size: 20px
  font-weight: 600
  width: 48px
  height: 48px
  border-radius: 50%
  justify-content: center
  display: flex
  align-items: center
  margin-right: 15px
  margin-bottom: 4px
  margin-top: 5px

  &.color1
    background: #7642ff

  &.color2
    background: #f4bc00

  &.color3
    background: #db046b

  &.color4
    background: #0ccd9d

  &.color5
    background: #42a5ff

#mainPageSlider
  margin-top: 5px

.slick
  position: relative

  & img
    border-radius: 5px

.slick-info
  position: absolute
  left: 15px
  bottom: 15px
  color: #ffffff
  font-size: 18px
  font-family: Montserrat
  font-weight: 500

  & span
    display: block
    margin-bottom: 11px

  & button
    padding: 0 20px

.slick-prev
  position: absolute
  top: -56px
  right: 43px
  background: url(../img/arrow-left.svg) no-repeat #fff
  width: 35px
  height: 35px
  border-top: 1px solid #e4e4e4
  border-left: 1px solid #e4e4e4
  border-bottom: 1px solid #e4e4e4
  border-right: none
  border-radius: 20px 0px 0px 20px
  font-size: 0
  background-position: 3px 4px
  outline: none

  &:hover
    background: url(../img/arrow-left-light.svg) no-repeat #fff
    background-position: 3px 4px

.slick-next
  position: absolute
  top: -56px
  right: 12px
  background: url(../img/arrow-right.svg) no-repeat #fff
  width: 35px
  height: 35px
  border-top: 1px solid #e4e4e4
  border-right: 1px solid #e4e4e4
  border-bottom: 1px solid #e4e4e4
  border-left: none
  border-radius: 0px 20px 20px 0px
  font-size: 0
  outline: none
  background-position: 6px 2px

  &:hover
    background: url(../img/arrow-right-light.svg) no-repeat #fff
    background-position: 6px 2px

.slick-dots
  list-style: none
  position: absolute
  top: -54px
  right: 97px

  & li
    display: inline-block
    margin-left: 20px

    & button
      border-radius: 50%
      font-size: 0
      background: #fff
      width: 13.5px
      height: 13.5px
      border: 1px solid #e4e4e4
      outline: none
      padding: 0

    &.slick-active
      & button
        background: #db046b
        border: 1px solid transparent

.slick-news > div
  border-bottom: 1px solid #e4e4e4
  padding: 10px 18px
  font-family: PFDinTextPro
  font-size: 18px
  font-weight: 400
  color: #30173d
  display: flex
  align-items: center
  cursor: pointer

  &:last-child
    border-bottom: 0

  & img
    margin-right: 18px
    width: 60px
    height: 60px
    border-radius: 3px

  & span
    line-height: 17px

.slick-news-modal
  display: none

.slick-news-modal-title
  font-family: Montserrat
  font-size: 20px
  font-weight: 700
  color: #30173d
  margin-bottom: 40px

.slick-news-modal-text
  font-family: PFDinTextPro
  font-size: 16px
  font-weight: 400
  color: #30173d

  & span
    font-weight: 600
    margin-bottom: 20px
    display: block

.slick-slide
  outline: none

.home-top
  display: grid
  grid-template-columns: 2fr 1fr
  padding: 0
  gap: 30px
  &::before
    display: none!important
  &::after
    display: none!important


.balance
  display: flex
  flex-direction: column
  margin: 17px 25px
  padding: 10px
  font-family: PFDinTextPro
  font-weight: 400
  color: #30173d
  font-size: 16px
  border-radius: 10px
  border: 1px solid #e4e4e4
  margin: 10px!important

  .urgent-payment-home
    margin: 0!important
    top: 0

.main-page__finance-pay-date-second
  font-size: 16px !important

.main-page__finance-pay-date-first
  font-size: 16px !important

.main-page__finance-pay-date-third
  font-size: 16px !important

.model-home
  &__scale
    max-width: 200px

  &__lvl
    background: #30173d
    width: 15px
    height: 15px
    border-radius: 50%
    color: #fff
    font-weight: 400 !important
    font-size: 13px !important
    display: flex !important
    justify-content: center
    align-items: center
    margin-left: 10px
    top: 5px

    &-next
      background: #f4bc00
      width: 15px
      height: 15px
      border-radius: 50%
      color: #fff
      font-weight: 400 !important
      font-size: 13px !important
      display: flex !important
      justify-content: center
      align-items: center
      margin-left: 10px
      top: 5px

  &__lvl-block
    min-width: 270px

    &-next
      min-width: 270px

      span
        display: flex

    span
      display: flex

.to-next-lvl
  min-width: 270px

.model-home__bonus
  min-width: 145px

.lvl-up-money
  display: flex
  justify-content: space-between
  align-items: center

  .help-block-example__img
    margin-right: 0

.show-parse-window
  max-width: 510px
  width: 100%

  &__texarea-wrapper
    position: absolute
    margin-top: 30px

    textarea
      height: 130px !important
      width: 260px !important

  .modal-content
    padding-left: 35px !important
    padding-right: 35px !important

    .modal-csv-btns
      margin: 0 0 30px
      display: flex
      justify-content: center

.slick.slick-slide
  height: 254px !important

  img
    height: 254px
    width: calc(100% - 10px)
    object-fit: cover

.bonus-slider__bg
  width: calc(100% - 10px)
  height: 100%
  background: linear-gradient(to bottom, rgba(48, 23, 61, 0), #30173d)
  position: absolute
  top: 0
  left: 0

.error_message_test
  position: absolute
  color: #db046b
  font-size: 12px
  margin-top: 7px


.bonus-modal-title
  margin-bottom: 24px
  color: #30173D

.bonus-list
  display: grid
  gap: 24px
  padding: 0
  margin-bottom: 24px

.bonus-item-header
  font-family: "Druk Wide Super"
  font-weight: 1000
  font-size: 20px
  line-height: 28px

.bonus-item
  color: #30173D
  padding: 12px
  border: 1px solid #30173D
  border-radius: 12px
  margin: 0
  display: grid
  gap: 8px

.bonus-item-description
  font-weight: 400
  font-size: 20px
  line-height: 28px

.bonus-item-status
  color: #DB046B
  font-weight: 700
  font-size: 20px
  line-height: 28px