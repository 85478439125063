#register-form
  margin-top: 10px
  .form-group
    height: auto!important
  .public_offer_wrap
    width: auto!important
    flex-wrap: wrap
    column-gap: 8px

.first_model_data
  width: 100%
  display: flex
  flex-direction: column

.field-registerform-username
  margin-top: -5px

.field-registerform-publicoffer, .field-registerform-privacy
  margin: 0!important

.field-registerform-publicoffer label, .field-registerform-privacy label
  font-size: 16px
  color: #30173d
  font-family: 'PFDinTextPro'
  font-weight: 400
  margin: 0

.public_offer, .privacy_cond
  float: right
  color: #db046b
  font-family: 'PFDinTextPro'
  font-weight: 400
  cursor: pointer
  font-size: 16px

  &_wrap
    display: -webkit-flex
    display: -moz-flex
    display: -ms-flex
    display: -o-flex
    display: flex
    justify-content: flex-start
    position: relative
    top: 5px
    margin-top: 4px
    margin-bottom: -13px
    padding-bottom: 20px !important
    width: 485px

    .form-group
      height: auto!important

.checkbox_condition
  padding: 0
  .jq-checkbox
    margin: 0!important
    top: 0!important
  label
    position: static

.register-button-main
  width: 476px

.public_offer_modal,
.privacy_cond_modal
  display: none
  width: 556px
  height: 556px
  text-align: right
  z-index: 5

.register-button-main
  margin-top: 35px

.register-form
  height: 600px !important
  margin-top: 35px

.hide
  display: none
