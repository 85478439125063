.superuser-finance__period-select
  border: solid 1px #e4e4e4
  border-radius: 5px
  padding: 13px 20px
  font-family: PFDinTextPro
  font-size: 16px
  font-weight: 400
  color: #30173d
  margin-right: 30px
  position: relative
  height: 81px
  margin-top: -3px
.superuser-finance__period
  &-select
    display: flex
    width: 360px
    padding: 19px 20px 13px
  & .title
    font-weight: 700
    margin-bottom: 14px
.superuser-finance__period-select .jq-selectbox__select
  padding: 0 18px 0 0px
.superuser-finance__period-select-date li, .superuser-finance__period-select-date-second li
  &:nth-child(4)
    display: block
  &:nth-child(5)
    display: none
.superuser-finance__period-select-year
  width: 60px
.superuser-finance__period-select-date, .second-period
  width: 120px
.superuser-finance
  &__table-info
    margin-top: 30px
    width: 100%
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    &::-webkit-scrollbar
      width: 5px
      background: rgba(228, 228, 228, 0.5)
    &::-webkit-scrollbar-thumb
      background: rgba(47, 47, 47, 0.15)
      border-radius: 5px
    &::-webkit-scrollbar-track
      background: rgba(228, 228, 228, 0.5)
  &__table-table
    position: relative
    background: #fff
    color: #30173d
    font-family: Montserrat
    font-weight: 400
    font-size: 14px
    width: 100%
    & tbody
      position: relative
      margin-top: 18px
      display: block
    & thead tr th
      position: sticky
      top: 0
      background: white
      z-index: 10
    td
      width: 110px
      text-align: right
      height: 50px
      padding-top: 4px
    th
      font-family: 'Montserrat'
      font-weight: 600
      font-size: 16px
      width: 110px
      text-align: right
      height: 51px
      outline: none
    tr
      &.active
        background-color: #ffffff
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
        & td:first-child:before
          position: absolute
          width: 20px
          height: 20px
          background: url(/web/public/img/check.svg) #db046b
          background-position: center center
          content: ''
          border-radius: 5px
          left: 2px
          margin-top: 0px
          background-size: 100%
      &:hover
        cursor: pointer
      td:first-child,
      th:first-child
        width: 292px
        text-align: left
        padding-left: 25px
      td:nth-child(2), th:nth-child(2)
        text-align: left
        width: 95px
      td:nth-child(3), th:nth-child(3)
        width: 97px
      td:nth-child(4), th:nth-child(4)
        width: 113px
      td:nth-child(6), th:nth-child(6)
        width: 193px
      th:last-child
        width: 101px
        padding-right: 25px
      td:last-child
        width: 130px
        padding-right: 25px
    & tbody
      & tr
        & td
          border-top: 1px solid #e4e4e4
.superuser-finance
  &__select-tr
    display: table-footer-group!important
    td
      position: sticky
      bottom: 0
      background: white
      border-bottom: none
  &__top
    display: flex
    justify-content: space-between
    height: 140px
    padding: 30px 0
    &-right
      width: 410px
      display: flex
      flex-wrap: wrap
      height: 91px
      margin-top: -4px
      justify-content: space-between
      & .btn-witdh-grey-border
        width: 98%
    & .title p
      font-size: 20px
      font-family: 'Montserrat'
      font-weight: 700
  &__period
    min-width: 236px
    position: relative
    top: 14px
  &__csv
    width: 60px
  &__course
    width: 137px
  &__lvl-pay
    width: 205px
  &__add-pay
    width: 181px
    & button
      width: 97%!important
  &__period-date
    display: block
    color: #30173d
    font-size: 16px
    top: -7px
    font-family: 'Montserrat'
    font-weight: 600
    position: relative
  &__period-interval
    position: relative
    width: 169px
    left: -11px
    top: 15px
    label
      margin-top: 27px
      left: 43px
      color: #30173d
  &__period-select
    width: 480px
    justify-content: space-between
    margin-right: 0px
    padding: 18px 20px 13px
    a
      margin-top: 17px
    &-first
      display: flex
      flex-wrap: wrap
      width: 295px
      top: 19px
      position: relative
      & > div
        margin-right: 10px
        position: relative
    &-second
      display: none
      width: 100%
      margin-top: 14px
      & > div
        margin-right: 10px
        position: relative
  &__constructor
    & .btn-witdh-grey-border
      width: 134px
      margin-bottom: 8px
  &__plan
    width: 75px
    & button
      width: 97%!important
  &__frozen
    width: 145px
    & button
      width: 97%!important
  &__table-info
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    overflow-x: auto
    border-radius: 5px
    margin-top: -1px
    padding-bottom: 29px
    table
      box-shadow: none
      border-radius: 0
      thead
        tr:first-child
          display: table-header-group
    &::-webkit-scrollbar
      height: 7px
      background: rgba(228, 228, 228, 0.5)
    &::-webkit-scrollbar-thumb
      background: rgba(47, 47, 47, 0.15)
      border-radius: 5px
    &::-webkit-scrollbar-track
      background: rgba(228, 228, 228, 0.5)
  &__constuctor-checkbox
    position: relative
    margin-bottom: 9px
    label
      font-family: PFDinTextPro
      font-weight: 400
      font-size: 16px
      color: #30173d
      left: 45px
      top: 0
  &__constructor-block
    display: none
    position: absolute
    top: 0
    left: -201px
    width: 195px
    background: #fff
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    padding: 24px
    z-index: 4
  &__constructor
    position: relative
  &__table-doc
    display: none
    &.not-load
      color: rgba(47, 47, 47, 0.5)
    &.load
      color: #0ccd9d
  &__quantity-line
    position: absolute!important
    right: 8px
    bottom: 13px
    width: 99px
.show-csv
  &.modal-box
    width: 360px
    height: 238px
    margin-left: -198px
    display: none
    margin-top: 135px
    & .modal-content
      padding: 35px 40px
    & .superuser-finance__period-date
      top: -24px
    & .modal-plan-title
      margin-bottom: 61px
.modal-csv-btns
  display: flex
  justify-content: space-between
  & .btn
    width: 130px
    margin: 0
.show-modal.modal-box
  display: none
.superuser-finance__select-line
  position: absolute
  height: 35px
  background: #ffffff
  bottom: 0px
  right: 20px
  max-width: 1135px
  display: block
  z-index: 2
  width: 100%
  border-radius: 5px
.year-label, .period-label
  font-size: 12px
  color: rgba(47,47,47,.5)!important
.show-course
  width: 360px
  height: 262px
  margin-left: -198px
  display: none
  margin-top: 135px
  & .modal-content
    padding: 35px 40px
  & .modal-course
    &-content
      top: -24px
      position: relative
      display: flex
      justify-content: space-between
      color: #30173d
      font-size: 16px
      font-weight: 600
      font-family: Montserrat
      margin-bottom: 20px
      & .form-group
        width: 128px
    &-btns
      width: 100%
      position: relative
      bottom: 31px
    &-now
      position: relative
      top: 8px
  & .modal-plan-title
    margin-bottom: 50px
.show-pay-add
  width: 360px
  height: 338px
  margin-left: -198px
  display: none
  margin-top: 135px
  & .modal-content
    padding: 35px 40px
  & .modal-pay-add
    &-content
      position: relative
      display: flex
      justify-content: space-between
      flex-direction: column
      color: #30173d
      font-size: 16px
      font-weight: 600
      font-family: Montserrat
      margin-bottom: 28px
      & .form-group
        width: 100%
    &-btns
      width: 100%
      position: relative
      bottom: 15px
    & .modal-plan-title
      margin-bottom: 35px
.show-pay-add-name
  margin-bottom: 20px
.total-line
  & tr
    display: table-footer-group!important
    &:hover
      cursor: default
    & th
      bottom: 0
      font-weight: 400
      height: 50px
      font-size: 14px
      border-top: 1px solid #e4e4e4
      &:first-child
        font-weight: 600
        font-size: 16px
.table-head
  & th
    cursor: pointer
  & tr
    display: table-header-group!important
    &:hover
      cursor: default
.show-pay-add
  display: none
.modal-box.lvl-pay
  width: 750px
  height: auto
  margin-left: -375px
  display: none
  margin-top: 134px
  & .modal-content
    padding: 35px 0
  .modal-plan-title
    margin-bottom: 7px
    padding: 0 40px
.lvl-pay__table
  width: 100%
  height: auto
  tr
    outline: none
    position: relative
    &.active
      background-color: #ffffff
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
      & td:first-child:before
        position: absolute
        width: 20px
        height: 20px
        background: url(/web/public/img/check.svg) #db046b
        background-position: center center
        content: ''
        border-radius: 5px
        left: 7px
        margin-top: 0px
        background-size: 100%
    &:hover
      cursor: pointer
    th
      text-align: right
      padding: 19px 0
      font-weight: 600
      color: #30173d
      font-size: 16px
      border-bottom: 1px solid #e4e4e4
      font-family: Montserrat
      min-width: 95px
      &:first-child
        text-align: left
        padding: 19px 0 19px 40px
      &:last-child
        padding: 19px 40px 19px 0px
    td
      text-align: right
      padding: 16px 0
      font-weight: 400
      color: #30173d
      font-size: 14px
      border-bottom: 1px solid #e4e4e4
      font-family: Montserrat
      min-width: 95px
      height: 53px
      &:first-child
        text-align: left
        padding: 16px 0 16px 40px
      &:last-child
        padding: 16px 40px 16px 0px
.modal-lvl-pay-btns
  display: flex
  justify-content: space-between
  margin-top: 42px
  padding: 0 40px
  & .btn
    width: 150px
.modal-lvl-pay-delete.active
  background-image: linear-gradient(rgba(219,4,107,.8),rgba(219,4,107,.6)),linear-gradient(to bottom,rgba(219,4,107,.6),rgba(219,4,107,.7))
  outline: none!important
  transition: 0.3s!important
.show-plan
  .close-modal
    top: 20px!important
    right: 20px!important
.superuser-finance__table-table
  .total-line
    tr
      th:last-child
        width: 125px

.superuser-finance__period-select-date
  & .jq-selectbox__dropdown
    & ul
      max-height: 300px
      height: 100%
      overflow-y: scroll

.superuser-finance__period-select-date.opened, .superuser-finance__period-select-year.opened
  z-index: 28!important
@-moz-document url-prefix()
  .superuser-finance__select-line
    bottom: 18px
    right: 34px
    width: 1117px
.finance_model
   color: #db046b
   font-size: 10px
