.sherlock-chat#sherlockChat
  display: flex
  flex-direction: column
  height: 75%!important

.sherlock-chat .chat
  display: flex
  flex-direction: column
  height: 100%
  border: 5px solid #DB046B!important

.sherlock-chat .operator
  height: 53px
  background-color: #DB046B!important
  cursor: move

.sherlock-chat .messageScrollBar__thumb-vertical
  background-color: #DB046B!important

.sherlock-chat .sherlock-app
  height: 100%
  width: 328px
  display: flex
  flex-direction: column

.sherlock-chat .smile__icon
  display: none

.sherlock-chat .widget-cross
  width: 30px
  height: 30px
  position: absolute
  left: 27px
  top: 8px

.sherlock-chat .widget_channels
  width: 100px
  height: 100px
  position: fixed
  bottom: 145px
  right: -22px
  z-index: 2147483646

.welcome-message
  background: #ffffff

.welcome-message hr
  display: none

@media (max-width: 830px)
  .sherlock-chat .channels__icons
    text-align: center
