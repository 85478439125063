.model-study
  &__tabs-main
    border-radius: 5px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    background-color: #ffffff
    height: 730px
    margin-top: 32px
    margin-bottom: 68px
    display: flex
  &__tabs
    overflow: auto
    width: 100%
  &__tabs-menu
    border-radius: 5px
    background-color: rgba(228, 228, 228, 0.24)
    border-right: 1px solid #e4e4e4
    width: 263px
    height: 730px
    position: relative
    & ul
      display: flex
      flex-direction: column
      border-bottom: none
      width: 263px
      li
        padding: 4px 5px
        border-left: 2px solid transparent
        transition: 0.3s
        margin-right: 2px
        &:hover
          background: #ffffff
          border-left: 2px solid #db046b
          transition: 0.3s
          & a
            color: #db046b
            transition: 0.3s
            background: #fff
        &.ui-tabs-active
          background: #ffffff
          border-left: 2px solid #db046b
          transition: 0.3s
          & a
            color: #db046b
            transition: 0.3s
            background: #fff
      a
        border: none
        color: #30173d
        font-family: Montserrat
        font-size: 16px
        font-weight: 600
        transition: 0.3s
  &__lesson
    padding: 48px 56px
    font-family: Montserrat
    font-size: 16px
    font-weight: 500
    color: rgba(48, 23, 61, 0.8)
    &-title
      font-family: Montserrat
      font-size: 36px
      font-weight: 700
      color: #30173d
      margin: 0 0 56px
    &-subtitle
      color: rgba(48, 23, 61, 0.8)
      font-family: Montserrat
      font-size: 24px
      font-weight: 600
    &-text
      margin-bottom: 48px
      margin-top: 24px
  &__tab-test
    position: absolute!important
    bottom: 20px
    left: 0px
    font-family: Montserrat
    color: #30173d
    font-size: 16px
    font-weight: 500
    text-align: center
    padding-left: 20px!important
    outline: none
    cursor: default
    a
      color: #db046b!important
      font-size: 14px!important
      font-family: PFDinTextPro!important
      font-weight: 500!important
      width: 222px
      margin: 25px auto 0
      border: 1px solid #e4e4e4!important
      padding: 7px 15px 10px!important
      border-radius: 5px!important
      letter-spacing: 1px
    &:hover
      background: transparent!important
      border-left: 2px solid transparent!important
    & a:hover
      background: rgba(118, 66, 255, 0.06)!important
      color: #db046b
    &.ui-tabs-active
      background: transparent!important
      border-left: 2px solid transparent!important
      &:hover
        & a
          background: rgba(118, 66, 255, 0.06)!important
          color: #db046b
  &__test
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    &-text
      color: rgba(48, 23, 61, 0.8)
      line-height: 1.58
      font-family: Montserrat
      font-size: 24px
      font-weight: 600
      text-align: center
      margin-bottom: 30px
    & button
      width: 133px
      margin: 20px auto 0
      font-weight: 500



.progress_width
  width: 570px
  height: 5px
  margin: 0 auto
  color: #fff
  background: #e4e4e4
  border-radius: 2.5px

#progressBar
  width: 0
  height: 5px
  background-color: #db046b
  border-radius: 5px

.test-question-title
  color: rgba(48, 23, 61, 0.8)
  font-family: Montserrat
  font-size: 24px
  font-weight: 600
  line-height: 1.58
  text-align: center
  width: 90%
  display: block
  margin-bottom: 40px
  margin-top: 40px

#modelTest
  display:  none

#lessonTest
  padding: 20px
  align-items: center

.time_info
  text-align: center
  color: rgba(48, 23, 61, 0.8)
  font-family: Montserrat
  font-size: 24px
  font-weight: 600
  width: 70px
  height: 70px
  border-radius: 35px
  margin-right: 21px
  border: 1px solid #e4e4e4
  display: flex
  align-items: center
  justify-content: center
  top: -31px
  left: -99px
  position: absolute
.test-option
  color: rgba(48, 23, 61, 0.8)
  font-family: Montserrat
  font-size: 18px
  font-weight: 500
  line-height: 1.33
  padding-left: 13px
  max-width: 90%
.test-option__block
  width: 555px
  height: 75px
  border-radius: 5px
  border: 1px solid #e4e4e4
  margin-bottom: 15px
  display: flex
  align-items: center
  padding: 0 13px
  cursor: pointer
  &.active-answer
    background: rgba(118, 66, 255, 0.06)
.test-timer
  display: flex
  position: relative