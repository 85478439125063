.btn
  &:focus, &:active:focus
    border-color: transparent
    outline: none
  &:hover
    background-color: #fff !important
  background-color: #fff !important

.btn-primary
  font-family: 'PFDinTextPro'
  font-weight: 600
  font-size: 16px
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  color: #ffffff
  cursor: pointer
  border-radius: 5px
  border: solid 1px transparent
  background-image: -webkit-gradient(linear, left top, left bottom, from(#db046b), to(#db046b)), -webkit-gradient(linear, left top, left bottom, from(#db046b), to(#ab0052))
  background-image: linear-gradient(#db046b, #db046b), linear-gradient(to bottom, #db046b, #ab0052)
  background-origin: border-box
  text-transform: uppercase
  height: 70px

.sattelit-button
  color: #db046b
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff)), -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ab0052))
  background-image: linear-gradient(#fff, #fff), linear-gradient(to bottom, #fff, #ab0052)
  box-shadow: 0 0 15px -5px #db046b
  text-transform: none

.btn-secondary
  font-family: 'PFDinTextPro'
  border-radius: 5px
  font-weight: 600
  background-color: #ffffff
  cursor: pointer
  border: solid 1px #db046b
  font-size: 16px
  display: flex
  align-items: center
  justify-content: center
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: normal
  text-align: center
  color: #db046b
  height: 70px
  width: 223px
  margin-top: 25px

.btn-tertiary
  width: 193px
  height: 50px
  border-radius: 5px
  display: flex
  align-items: center
  justify-content: center
  border: solid 1px transparent
  background-image: linear-gradient(#db046b, #db046b), linear-gradient(to bottom, #db046b, #ab0052)
  font-family: 'PFDinTextPro'
  font-weight: 600
  font-size: 16px
  text-align: center
  color: #ffffff!important
  text-transform: uppercase
  transition: 0.3s
  height: 50px
  padding: 0

.btn-primary:active:hover, .btn-tertiary:active:hover, .btn-primary:hover, .btn-tertiary:hover
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(219, 4, 107, 0.8)), to(rgba(219, 4, 107, 0.6))), -webkit-gradient(linear, left top, left bottom, from(rgba(219, 4, 107, 0.8)), to(rgba(219, 4, 107, 0.6)))
  background-image: linear-gradient(rgba(219, 4, 107, 0.8), rgba(219, 4, 107, 0.6)), linear-gradient(to bottom, rgba(219, 4, 107, 0.6), rgba(219, 4, 107, 0.7))
  border-color: transparent
  transition: 0.3s
  border-color: rgba(219, 4, 107, 0.7)
  color: #fff
  box-shadow: none

.btn-secondary
  &:hover
    border-radius: 5px
    background-color: #ffffff
    border: solid 1px rgba(219, 4, 107, 0.7)
    color: rgba(219, 4, 107, 0.7)
    border-color: rgba(219, 4, 107, 0.7) !important
    box-shadow: none
    font-family: 'PFDinTextPro'
    font-weight: 700
  &:active
    border-radius: 5px
    background-color: #ffffff
    border: solid 1px rgba(219, 4, 107, 0.7)
    color: rgba(219, 4, 107, 0.7)
    border-color: rgba(219, 4, 107, 0.7) !important
    box-shadow: none
    font-family: 'PFDinTextPro'
    font-weight: 700
    &:focus
      border-radius: 5px
      background-color: #ffffff
      border: solid 1px rgba(219, 4, 107, 0.7)
      color: rgba(219, 4, 107, 0.7)
      border-color: rgba(219, 4, 107, 0.7) !important
      box-shadow: none
      font-family: 'PFDinTextPro'
      font-weight: 700
  &:focus
    border-radius: 5px
    background-color: #ffffff
    border: solid 1px rgba(219, 4, 107, 0.7)
    color: rgba(219, 4, 107, 0.7)
    border-color: rgba(219, 4, 107, 0.7) !important
    box-shadow: none
    font-family: 'PFDinTextPro'
    font-weight: 700

.btn-primary:disabled, .btn-tertiary:disabled
  background: rgba(228, 228, 228, 0.5) !important
  color: rgba(47, 47, 47, 0.3)

.btn-secondary:disabled
  border: solid 1px #e4e4e4
  color: rgba(47, 47, 47, 0.3)

.btn-primary
  &:focus, &.focus
    outline: none
    border-color: transparent

.primary:active:focus, .btn:active:focus
  outline: none
  border-color: transparent
.btn-tertiary.active
  background-image: linear-gradient(rgba(219,4,107,.7),rgba(219,4,107,.5)),linear-gradient(to bottom,rgba(219,4,107,.5),rgba(219,4,107,.6))
  outline: none!important
  transition: 0.3s!important
a
  color: #db046b
  font-size: 16px
  transition: 0.3s
  font-family: PFDinTextPro
  font-weight: 400
  cursor: pointer
  &:hover
    color: rgba(219, 4, 107, 0.7)
    transition: 0.3s
    text-decoration: none
    outline: none
  &:focus
    outline: none
    text-decoration: none
    color: #db046b
  &[disabled]
    color: rgba(47, 47, 47, 0.3)

.delete-icon, ._delete-icon
  position: absolute
  right: 0
  top: 26px
  cursor: pointer

.delete-icon svg:hover g
  fill: #2f2f2f

.icon-notification
  width: 40px
  height: 40px
  border-radius: 20px
  background-color: #ffffff
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)
  svg:hover g
    fill: #2f2f2f
  &:hover
    box-shadow: 0 4px 8px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)
  &.disabled
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14)
    cursor: default
    svg g
      fill: rgb(193, 193, 193)

.btn-witdh-grey-border
  height: 36px
  border: solid 1px #e4e4e4
  color: #db046b
  font-family: PFDinTextPro
  font-size: 14px
  font-weight: 600
  text-transform: uppercase
  text-align: center
  letter-spacing: 1px
  transition: 0.3s
  background: #fff
  border-radius: 5px
  &:hover
    background-color: rgba(118, 66, 255, 0.06)
    transition: 0.3s
    outline: none
    border: solid 1px #e4e4e4
  &:active
    background-color: rgba(118, 66, 255, 0.06)
    transition: 0.3s
    outline: none
    border: solid 1px #e4e4e4
    &:focus
      background-color: rgba(118, 66, 255, 0.06)
      transition: 0.3s
      outline: none
      border: solid 1px #e4e4e4
  &:focus
    background-color: rgba(118, 66, 255, 0.06)
    transition: 0.3s
    outline: none
    border: solid 1px #e4e4e4
  &:disabled
    color: rgba(47, 47, 47, 0.3)
.toggle-btns
  width: 95px
  height: 50px
  border-radius: 25px
  background-color: #ffffff
  position: absolute
  left: 50%
  display: flex
  margin-left: -47px
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)
.approve
  border-right: 1px solid #e4e4e4
  border-radius: 25px 0 0 25px
  height: 50px
  width: 47px
  cursor: pointer
  &-svg
    left: 8px
    top: 6px
    position: relative
  &:hover .approve-svg g
    fill: rgba(12,205,157,0.5)
.no-approve
  border-radius: 0 25px 25px 0
  height: 50px
  width: 47px
  cursor: pointer
  &-svg
    left: 7px
    top: 10px
    position: relative
  &:hover .no-approve-svg g
    fill: rgba(219, 4, 107, 0.7)
