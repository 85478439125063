.model-affiliate-instruction
  margin: 0 auto
  max-width: 500px
  text-align: center

.model-affiliate-instruction__title
  font-size: 16px
  font-weight: bold
  cursor: pointer
  border-color: #e23688
  border-radius: 20px
  border-width: 2px
  border-style: solid
  text-align: center

.model-affiliate-instruction__description
  margin-top: 10px
  max-width: 500px
  text-align: center
  font-size: 14px
  font-weight: bolder
  line-height: 1.5