.header__messages-block
  width: 262px
  border-radius: 5px
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
  background-color: #ffffff
  position: absolute
  top: 43px
  left: -60px
  display: none
  padding: 10px
  &-line
    width: 100%
    display: flex
    background: #fff
    color: #30173d
    font-size: 14px
    font-family: PFDinTextPro
    font-weight: 400
    padding-right: 20px
    border-bottom: 1px solid #e4e4e4
    margin-top: 5px
    &:last-child
      border-bottom: none
    & span
      color: rgba(47, 47, 47, 0.3)
      display: block
    & img
      border-radius: 5px
      width: 60px
      height: 60px
      margin-right: 10px
.header-mail-icon:hover
  .header__messages-block
    display: block