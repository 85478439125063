body
  margin: 0
  padding: 0
  font-family: "Montserrat", sans-serif
  color: rgba(17, 15, 38, 0.8)

body.menu-open
  overflow: hidden
  position: fixed

.menu-mobile
  display: none

.header,
.menu,
.site-menu,
.container--button-and-date,
.footer,
.container--articles,
.container--header,
.container--footer
  display: flex
  flex-direction: row


.header,
.footer
  justify-content: space-between
  padding: 15px 0

.header
  box-shadow: 0 2px 6px -1px rgba(17, 15, 38, 0.12), 0 0 1px 0 rgba(17, 15, 38, 0.06)

.footer
  box-shadow: 0 -2px 6px -1px rgba(17, 15, 38, 0.12), 0 0 1px 0 rgba(17, 15, 38, 0.06)

.container--header,
.container--footer
  width: 1140px
  margin: 0 auto

.container--header
  & .copyright
    display: none

button:focus
  outline-width: 0

.logo,
.site-menu,
.user-menu
  padding: 0
  margin: 0


.site-menu__item,
.logo
  display: inline-block
  margin: auto 0

.logo
  font-weight: bold
  font-size: 24px

.logo
  & a
    color: rgba(17, 15, 38, 0.8)
    text-decoration: none

.menu
  margin: auto 0 auto auto


.header:after
  content: ""


.site-menu
  position: relative
  justify-content: space-between
  margin-right: 50px
  width: 170px
  list-style: none
  font-size: 16px


.site-menu__item:hover::after,
.site-menu__item:focus::after
  content: ""
  position: absolute
  width: 24px
  height: 24px
  background-position: center
  background-repeat: no-repeat


.site-menu__link
  text-decoration: none
  font-weight: 500
  color: rgba(17, 15, 38, 0.8)


.site-menu__link:hover
  color: rgba(17, 15, 38, 0.6)


.user-menu-button
  display: inline-block
  padding: 15px
  width: 96px
  height: 50px
  font-size: 16px
  font-weight: bold
  color: #ff2f88
  text-align: center
  border: none
  border-radius: 25px
  box-shadow: 0 7px 8px -4px rgba(255, 47, 136, 0.12), 0 10px 30px 0 rgba(255, 47, 136, 0.12), 0 2px 5px -1px rgba(43, 9, 80, 0.12), 0 0 1px 0 rgba(255, 47, 136, 0.03)
  background-color: #ffffff
  cursor: pointer

a.user-menu-button,
a.user-menu-button:hover,
a.user-menu-button:focus
  color: #ff2f88
  text-decoration: none

.main-content
  margin: 0 auto
  width: 1145px


.articles
  position: relative


.article,
.webinar
  display: flex
  flex-direction: column
  position: relative
  box-sizing: border-box
  padding: 24px
  width: 260px
  height: 464px
  border-radius: 20px
  box-shadow: 0 8px 8px -4px rgba(17, 15, 38, 0.12), 0 2px 6px -1px rgba(17, 15, 38, 0.12), 0 0 1px 0 rgba(17, 15, 38, 0.06)

.article--single
  height: 100%
  padding: 0 0 24px 0 !important

.article--first
  box-sizing: border-box
  margin: 30px 0 35px 0
  padding: 48px 56px 56px 56px
  width: 1140px
  max-height: 485px
  box-shadow: 0 8px 8px -4px rgba(17, 15, 38, 0.12), 0 8px 32px 0 rgba(17, 15, 38, 0.12), 0 2px 6px -1px rgba(17, 15, 38, 0.12), 0 0 1px 0 rgba(17, 15, 38, 0.06)
  background-image: url("../img/group.jpg")
  background-size: cover
  background-position-x: center

.container--first-article
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 378px
  height: 517px


.article__title,
.webinar__title
  padding: 0
  margin: 178px 0 15px 0
  font-size: 20px
  font-weight: 600
  line-height: 26px
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  overflow-y: hidden
  min-height: 75px

.article__title.article__title--analytics.single
  line-height: 40px
  -moz-hyphens: auto
  -webkit-hyphens: auto
  -ms-hyphens: auto

.article__title--first
  margin: 0 0 28px 0
  font-size: 48px
  font-weight: 800
  line-height: normal
  color: #ffffff


.article__description,
.webinar__description
  padding: 0
  margin: 0
  font-size: 14px
  font-weight: 500
  line-height: 20px
  color: rgba(17, 15, 38, 0.6)


.article__description.horizont
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  overflow-y: hidden
  max-height: 60px

.article__description.short
  display: -webkit-box
  -webkit-line-clamp: 4
  -webkit-box-orient: vertical
  overflow-y: hidden

.article__description--first
  font-size: 16px
  line-height: 24px
  color: #ffffff
  display: -webkit-box
  -webkit-line-clamp: 5
  -webkit-box-orient: vertical
  overflow-y: hidden

.article__img,
.webinar__img
  order: -1
  position: absolute
  left: 4px
  top: 4px
  border-radius: 20px
  width: 254px
  height: 170px
  object-fit: cover


.container--button-and-date
  margin: auto 0 0 0
  justify-content: space-between

.container--button-and-date.container--button-analytics-articles.horisont
  margin-top: 24px

.article__date,
.webinar__date
  margin: auto 0
  font-weight: 500
  font-size: 14px
  color: rgba(17, 15, 38, 0.6)


.article__button
  order: -1
  padding: 15px 21px
  width: 102px
  height: 50px
  border-radius: 25px
  border: solid 1px rgba(17, 15, 38, 0.12)
  font-size: 16px
  font-weight: bold
  color: #ff2f88
  background-color: #ffffff

.article__button:hover
  border: solid 1px rgba(17, 15, 38, 0.24)
  text-decoration: none
  color: #ff2f88

.article__button--first
  order: 3
  margin: 20px 0 0 0
  color: #ffffff
  border-radius: 35px
  box-shadow: 0 7px 8px -4px rgba(221, 38, 134, 0.5), 0 10px 30px 0 rgba(221, 38, 134, 0.5), 0 2px 5px -1px rgba(43, 9, 80, 0.5),  0 0 1px 0 rgba(221, 38, 134, 0.5)
  background-image: linear-gradient(to right, #ff2f88, #692d8c)
  border: solid 0 rgba(17,15,38,.12) !important

.article__button--first:visited
  color: #fff
  text-decoration: none !important

.article__button--first:focus
  text-decoration: none

.article__button--first:hover
  border: solid 0 rgba(17,15,38,.12) !important

.article__button--first.reg-single,
.article__button--first.button-login
  width: 228px

.article__button--first:hover
  color: #fff

.article__button--first.single
  margin-left: 20px
  width: 102px
  color: #ff2f88
  background-color: #fff
  background-image: none
  box-shadow: 0 7px 8px -4px rgba(255,47,136,.12), 0 10px 30px 0 rgba(255,47,136,.12), 0 2px 5px -1px rgba(43,9,80,.12), 0 0 1px 0 rgba(255,47,136,.03)

.container--button
  width: 1140px
  display: flex
  justify-content: center


.button--more,
.button--more-articles
  margin: 40px auto 0 auto
  width: 166px
  height: 50px
  font-size: 16px
  font-weight: bold
  color: #ff2f88
  border: none
  border-radius: 25px
  box-shadow: 0 7px 8px -4px rgba(255, 47, 136, 0.12),   0 10px 30px 0 rgba(255, 47, 136, 0.12), 0 2px 5px -1px rgba(43, 9, 80, 0.12),   0 0 1px 0 rgba(255, 47, 136, 0.03)
  background-color: #ffffff


.button--more a,
.button--more-articles a
  text-decoration: none
  color: #ff2f88


.articles__button:hover,
.webinars__button:hover,
.user-menu-button:hover,
.article__button--first:hover
  box-shadow: 0 10px 10px -4px rgba(255, 47, 136, 0.12),    0 15px 30px 0 rgba(255, 47, 136, 0.12),    0 5px 5px -1px rgba(17, 15, 38, 0.12), 0 1px 1px 0 rgba(64, 120, 239, 0.03)



.container--slider,
.container--articles
  width: 100%
  overflow-x: hidden

.container--articles
  padding: 5px

.container--slider
  padding: 5px 5px 40px 5px

.slider,
.slider--articles
  display: flex
  flex-direction: row


.slider--articles
  flex-wrap: wrap


.container--more-articles
  display: none
  flex-direction: row
  margin-top: 30px


.article
  min-height: 450px
  flex-shrink: 0


.article:not(:last-of-type)
  margin-right: 30px


.container--slider
  display: flex
  flex-direction: row
  justify-content: space-between


.footer
  padding: 57px 0
  margin-top: 40px

.logo--footer
  margin-right: 150x

.copyright
  margin: auto 0 auto auto


.footer .site-menu
  margin: auto 0


/* ANALYTICS */

.analytics-content
  position: relative
  display: flex
  flex-direction: column
  width: 1140px
  margin: 0 auto


.pagination
  padding: 30px 0


.pagination__list
  display: flex
  flex-direction: row
  padding: 0
  margin: 0
  list-style: none
  flex-wrap: wrap

.pagination__item
  font-size: 16px
  font-weight: 500
  color: rgba(17, 15, 38, 0.6)
  cursor: pointer


.pagination__item a,
.side-menu__item a
  text-decoration: none
  color: rgba(17,15,38,.8)

.pagination__item a
  color: rgba(17, 15, 38, 0.6)


.pagination__item--current a
  color: rgba(17, 15, 38, 0.8)


.pagination__item:not(:last-of-type)
  position: relative
  margin-right: 24px


.pagination__item:not(:last-of-type)::after
  content: ""
  position: absolute
  top: 5px
  right: -18px
  height: 12px
  width: 12px
  background-image: url("../img/filled.svg")
  background-position: center
  background-repeat: no-repeat


.articles--analytics
  width: 800px


.article.article--analytics
  margin: 0
  padding: 40px 40px 32px 0
  width: 100%
  height: 0


.article.article--analytics
  min-height: 262px

.article--analytics:not(:last-of-type)
  margin-bottom: 30px

.article.article--single
  min-height: 564px
  border-radius: 0
  box-shadow: none


.articles--analytics.single::after
  content: ""
  position: absolute
  height: 1px
  background-color: rgba(17, 15, 38, 0.12)


.container--article-analytics-description
  margin-left: 294px


.article__title--analytics
  margin: 0 0 auto 0
  max-height: 75px
  min-height: 0

.article__title--analytics.single
  max-height: 100%
  font-size: 36px
  font-weight: 700
  margin: auto 0 24px 0

.article__title.article__title--analytics.horisont
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow-y: hidden
  min-height: 50px


.container--button-analytics .button--more
  margin: 48px auto


.article__button--analytics
  box-sizing: border-box
  padding: 15px 25px
  width: 105px
  height: 50px
  font-size: 14px

.container--article-analytics-description
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 100%

.container--article-analytics-description.single
  margin-top: 500px
  margin-left: 10px

.container--button-analytics
  display: flex
  justify-content: center
  width: 100%


.container--more-articles.container--more-articles-analytics
  display: none
  flex-direction: column


.button--side-menu,
.button--side-menu-close
  display: none
  position: absolute
  right: 16px
  width: 50px
  height: 50px
  border-radius: 25px
  border: none
  box-shadow: 0 7px 8px -4px rgba(255, 47, 136, 0.12),    0 10px 30px 0 rgba(255, 47, 136, 0.12), 0 2px 5px -1px rgba(43, 9, 80, 0.12),    0 0 1px 0 rgba(255, 47, 136, 0.03)
  background-color: #ffffff


#side-menu
  right: 0%
  top: 159px
  width: 263px
  border-radius: 20px
  box-shadow: 0 8px 8px -4px rgba(17, 15, 38, 0.12),    0 2px 4px -1px rgba(17, 15, 38, 0.12), 0 0 1px 0 rgba(17, 15, 38, 0.06)
  background-color: #ffffff


.side-menu__list
  width: 100%
  margin: 0
  padding: 0
  list-style: none


.side-menu__item:not(:last-of-type)
  border-bottom: 1px solid rgba(17, 15, 38, 0.12)


.side-menu__item
  display: inline-block
  width: 100%
  padding: 24px
  box-sizing: border-box
  font-size: 18px
  font-weight: 600
  color: rgba(17, 15, 38, 0.8)
  cursor: pointer

.side-menu__item--current
  width: 100%
  color: #ff2f88
  border-left: 2px solid #ff2f88
  background-color: rgba(255, 47, 136, 0.06)


.button-up
  position: absolute
  bottom: 90px
  right: -56px
  width: 50px
  height: 50px
  border-radius: 25px
  border: none
  box-shadow: 0 8px 8px -4px rgba(17, 15, 38, 0.12),    0 2px 4px -1px rgba(17, 15, 38, 0.12), 0 0 1px 0 rgba(17, 15, 38, 0.06)
  background-color: #ffffff
  z-index: 1000
  cursor: pointer


.article__img--analytics
  width: 254px
  height: 97%
  object-fit: cover

.article__img--single
  width: calc(100% - 8px)
  height: 460px

.text
  position: relative

.unregistered
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: hidden
  background: rgb(255,255,255)
  flex-direction: column
  justify-content: flex-end
  background: linear-gradient(180deg, rgba(255,255,255,0) 10%, rgba(255,255,255,1) 100%)


.unregistered__text
  font-weight: bold
  margin-top: 35px

.unregistered.visible
  display: flex


@media (max-width: 1300px)
  .webinars__button--prev
    left: -27px


  .webinars__button--next
    right: -27px


/* ANALYTICS LARGE*/


@media (max-width: 1200px)
  body,
  .main-content,
  .articles,
  .article--first,
  .footer
    width: 100%


  .header,
  .footer
    box-sizing: border-box


  .header
    padding: 15px 16px


  .footer
    padding: 57px 16px


  .main-content,
  .articles,
  .webinars
    box-sizing: border-box


  .slider--articles::-webkit-scrollbar,
  .slider::-webkit-scrollbar
    display: none


  .articles,
  .webinars
    padding: 0 16px

    overflow: hidden


  .webinars
    padding-right: 0



  .container--button
    display: none


  .slider--articles
    flex-wrap: nowrap


  .slider,
  .slider--articles
    scrollbar-width: none

    padding: 5px

    overflow-x: scroll
    overscroll-behavior-x: none


  .container--more-articles
    margin-top: 0

    display: flex


  .container--more-articles .article:first-of-type
    margin-left: 30px


  .articles::after
    width: calc(100% - 34px)
    top: 1294px


  .webinars__button
    display: none


  /*ANALYTICS-TABLE*/

  body,
  .analytics-content,
  .articles--analytics
    width: 100%


  .analytics-content
    box-sizing: border-box

    padding: 0 16px


  #side-menu
    display: none
    top: 64px

  #side-menu.open-menu
    display: flex
    position: absolute
    top: 40px
    right: 20px
    transform: none
    z-index: 1000


  .button--side-menu,
  .button--side-menu-close
    display: block
    z-index: 2000

  .button--side-menu-close
    top: -26px

  .button--side-menu
    position: fixed
    top: 86px

  .button-up
    bottom: 16px
    right: 16px


  .container--more-articles-analytics .article:first-of-type
    margin: 30px 0 30px 0



@media (max-width: 642px)
  .menu-mobile
    position: absolute
    right: 12px
    bottom: 15px
    display: block
    cursor: pointer
    border: none
    background-color: transparent
    z-index: 100000

  div.site-menu-mobile
    margin: 48px -15px 0 -15px
    position: absolute
    width: 100%
    z-index: 3000
    background-color: rgb(255, 255, 255)

  ul.site-menu-mobile
    margin-left: -40px

  .site-menu__item-mobile
    display: block
    margin: auto 0
    height: 48px

  .site-menu__item-mobile
    border-top: 1px solid rgba(17,15,38,.12)

  .site-menu__item-mobile:last-of-type
    border-bottom: 1px solid rgba(17,15,38,.12)
    text-align: center
    height: 82px

  .site-menu__item-mobile:last-of-type .user-menu
    margin: 16px 0

  .site-menu__item-mobile:last-of-type .user-menu-button
    width: 80%

  .site-menu__item-mobile:not(:last-of-type):before
    content: ""
    display: inline-block
    height: 100%
    vertical-align: middle
    margin-left: 16px


  .header
    position: relative
    padding: 0px
    height: 48px

  .logo
    font-size: 16px
    margin: auto 0


  .menu
    display: none


  .site-menu__item:hover::after,
  .site-menu__item:focus::after
    right: -38px


  .articles,
  .webinars
    padding: 16px


  .articles
    box-sizing: border-box


  .article--first
    min-height: 570px

    padding: 24px
    margin: 0 0 20px 0

    background-image: url("../img/group_2.jpg")
    background-position: center


  .article:not(:last-of-type),
  .webinar:not(:last-of-type)
    margin-right: 30px


  .article__title--first
    font-size: 36px
    margin-bottom: auto
    overflow-wrap: break-word


  .article__description--first
    margin: auto 0 0 0

  .container--first-article
    width: 100%
    font-size: 14px


  .article__button--first
    width: 100%
    text-align: center

  .article__img
    background-position: right


  .articles::after
    top: 1226px



.article-title-h1,
.article-title-h1 span
  font-size: 36px
  font-weight: 700
  line-height: 26px
  font-family: Montserrat, sans-serif !important


.article-subtitle-h1,
.article-subtitle-h1 span
  font-weight: 600
  font-size: 24px
  font-family: Montserrat, sans-serif !important
  color: rgba(17,15,38,.8)

.article-description-text,
.article-description-text span
  font-size: 16px
  line-height: 20px
  font-family: Montserrat, sans-serif !important


ul.article-ul
  list-style: none
  margin-left: -20px

ul.article-ul li::before
  content: "•"
  color: #ff2f88
  display: inline-block
  width: 15px
  margin-left: -15px
  font-size: 30px


  .webinars__title
    font-size: 36px
    overflow-wrap: break-word
    margin-bottom: 36px


  .webinar
    flex-shrink: 0


  .footer
    padding: 0


  .container--footer
    flex-direction: column


  .copyright
    margin: 0


  .container--footer .menu
    display: flex

    margin: 0


  .container--footer .site-menu
    flex-direction: column


  .logo--footer,
  .copyright,
  .container--footer .site-menu__item
    box-sizing: border-box

    padding: 14px 0 15px 16px

    width: 100%

    overflow: hidden

    border-top: 1px solid rgba(17, 15, 38, 0.12)


  .open,
  .container--header.open,
  .container--header.open .menu,
  .container--header.open .site-menu
    position: absolute
    top: 25px

    display: flex
    flex-direction: column

    margin: 0

    width: 100%

    z-index: 1000

    background-color: #ffffff


  .container--header.open .site-menu
    top: 47px


  .container--header.open .copyright
    display: inline-block

    position: absolute
    top: 23px

    width: 100%

    z-index: 1000

    background-color: #ffffff


  .container--header.open .menu-mobile
    right: 5px
    bottom: -8px


  .container--header.open .logo
    position: absolute
    top: -25px


  .logo,
  .copyright,
  .site-menu__item,
  .user-menu
    box-sizing: border-box
    padding: 14px 0 15px 16px
    width: 100%
    overflow: hidden
    background-color: #ffffff


  .site-menu
    width: 100%


  .copyright,
  .site-menu__item,
  .user-menu
    border-top: 1px solid rgba(17, 15, 38, 0.12)


  .user-menu
    position: absolute
    top: 194px
    height: 82px
    box-shadow: 0 8px 8px -4px rgba(17, 15, 38, 0.12),      0 2px 4px -1px rgba(17, 15, 38, 0.12), 0 0 1px 0 rgba(17, 15, 38, 0.24)
    z-index: 1000


  .user-menu .user-menu-button
    position: absolute
    top: 15px
    width: 92%
    padding: 0
    text-align: center
    z-index: 1000


  .container--first-article
    width: 100%


  .container--more-articles .article:first-of-type
    margin-left: 30px


  .container--slider,
  .container--articles
    padding: 0


  .webinars
    padding-right: 0


  /* ANALYTICS-MOBILE */

  .article.article--analytics
    width: 100%
    height: auto

    padding: 24px


  .container--article-analytics-description
    margin: 254px 0 0 0
    height: inherit


  .container--more-articles-analytics
    display: none


  .container--button-analytics-articles .article__date
    order: -1


  .container--button-analytics
    justify-content: flex-start


  .container--button-analytics .button--more
    margin: 48px auto


  .article__img--analytics
    width: calc(100% - 8px)
    height: 254px
    -o-object-fit: cover
      object-fit: cover


  #side-menu
    display: none


  .article__title--analytics
    margin: 0 0 8px 0


  .article--analytics .article__description
    margin-bottom: auto

  .button--side-menu,
  .button--side-menu-close
    display: block

@media (max-width: 550px)
  .article__img.article__img--analytics
    width: 98%
    height: 50%
    min-width: 254px
    min-height: 170px

  .article.article--analytics
    width: 98%
    height: 564px
    padding: 24px
    min-width: 260px

  .container--article-analytics-description
    margin: 288px 0 15px 0

  .container--button-and-date
    flex-direction: row-reverse

  .article__title--analytics
    margin: 0 0 auto 0

@media (max-width: 300px)
  .article__img.article__img--analytics
    width: 254px
    height: 170px


@media (max-width: 340px)
  .article__img.article__img--analytics
    height: 40%

  .article.article--analytics
    height: 464px

  .container--article-analytics-description
    margin: 178px 0 15px 0

@media (max-width: 700px)
  .article__title--analytics.single
    overflow-wrap: break-word


@media (min-width: 643px)
  div.site-menu-mobile
    display: none
