.superuser-singlemodel__single
  &-info
    margin-top: -4px
    width: 100%
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    margin-bottom: 30px
    &-table
      position: relative
      background: #fff
      border-radius: 5px
      color: #30173d
      font-family: Montserrat
      font-weight: 400
      font-size: 14px
      width: 100%
      &-td
        width: 52px
        text-align: center
        height: 50px
        border-bottom: 1px solid #e4e4e4
      &-th
        font-family: 'Montserrat'
        font-weight: 600
        font-size: 16px
        width: 52px
        text-align: center
        height: 70px
        border-bottom: 1px solid #e4e4e4
      tr
        & .superuser-singlemodel__single-info-table-td:first-child,
        & .superuser-singlemodel__single-info-table-th:first-child
          width: 259px
          text-align: left
          padding-left: 25px
        & .superuser-singlemodel__single-info-table-td:last-child,
        & .superuser-singlemodel__single-info-table-th:last-child
          width: 101px
          text-align: right
          padding-right: 25px
      &-bold
        font-weight: 600
    &-top
      padding: 25px 0 20px
      text-align: center
      font-family: Montserrat
      color: #30173d
      position: relative
    &-title
      font-size: 20px
      font-weight: 700
    &-back
      position: absolute
      left: 24px
      top: 21px
      width: 70px
      display: flex
      justify-content: space-between
      &-svg
        transform: rotate(90deg)
        position: relative
        top: 7px
      &:hover .superuser-singlemodel__single-info-back-svg
        & g
          fill: rgba(219, 4, 107, 0.7)
    &-bottom
      display: flex
      justify-content: flex-end
      flex-wrap: wrap
      padding: 16px 25px
      & > div
        width: 317px
    &-pay, &-total
      display: flex
      width: 317px
      justify-content: space-between
      font-family: Montserrat
      color: #30173d
      font-size: 14px
      &-span
        font-weight: 600
        font-size: 16px
    &-pay
      margin-bottom: 30px
    &-sort
      cursor: pointer
      float: right
      margin-right: -25px
      position: relative
      top: 1px
      &:hover g
        fill: #2f2f2f
  &-constuctor-checkbox
    position: relative
    margin-bottom: 9px
    label
      font-family: PFDinTextPro
      font-weight: 400
      font-size: 16px
      color: #30173d
      left: 45px
      top: 0
  &-constructor-block
    display: none
    position: absolute
    top: 0
    left: -201px
    width: 195px
    background: #fff
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    border-radius: 5px
    padding: 24px
    z-index: 4
  &-btns-right
    margin-left: 10px
    margin-top: -3px
  &-title
    margin-bottom: 17px!important
  &-ticket-btn
    width: 134px
    margin-bottom: 10px
    position: relative
    &:before
      content: '+'
      position: absolute
      color: #db046b
      font-size: 30px
      font-weight: 400
      font-family: PFDinTextPro
      left: 9px
      top: -6px
  &-chat-btn
    width: 134px
  &-top-right
    margin-top: -2px
  &-period
    margin-right: 51px
    margin-left: 33px
  &-work
    border-radius: 5px
    background-color: #ffffff
    margin-bottom: 100px
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
    & .nav
      width: 100%
      display: flex
      justify-content: space-between
    &-block-photo
      width: 242px
      height: 224px
      border-radius: 5px
      border: solid 1px #e4e4e4
      overflow: hidden
      background: #fff
      padding: 5px
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      position: relative
      &-svg
        position: relative
        top: 6px

    &-photo
      width: 100%
      height: 155px
      overflow: hidden
      img
        width: 100%
        height: auto
      & .approve, & .no-approve
        & a
          width: 100%
          height: 100%
      & .toggle-btns
        top: 99px
  &-tab
    text-transform: uppercase
    margin-bottom: 0!important
    width: 25%
    text-align: center
    cursor: pointer
    &:hover, &.ui-tabs-active
      border-bottom: 2px solid #db046b
      .superuser-singlemodel__single-tab-a
        color: #db046b
    &-a
      color: rgba(47, 47, 47, 0.5)
      font-family: PFDinTextPro
      font-size: 16px
      font-weight: 700
      border: none!important
      padding: 16px 15px 11px!important
      &:hover, &:focus
        color: #db046b!important
        background: #fff!important
    &-content
      display: flex
      justify-content: space-between
      padding: 40px
      flex-wrap: wrap
  &-download-photo
    &-img
      position: relative
      top: 1px
      width: 20px
      height: 20px
      background: url(/web/public/img/download.png)
      background-size: 100%
      margin-right: 5px
    &:hover .superuser-singlemodel__single-download-photo-img
      background: url(/web/public/img/download-light.png)
      background-size: 100%


  &-comment
    width: 544px
    margin: 30px auto
    height: 105px!important
    &-lasttab
      padding: 40px
      & #commentForModel
        height: auto
        min-height: 105px
        width: 514px
        margin: 0 auto -4px
      & label
        width: 514px
        left: 50%
        margin-left: -257px
      &-save
        width: 242px
        margin: 0 auto
  &-approve-block-btn
    width: 100%
  &-approve-btn
    width: 242px
    margin: 0 auto
  &-download-photo
    margin-right: 7px
    margin-top: 9px
    display: flex
  &-delete-img
    width: 32px
    height: 32px
    border-radius: 50%
    position: absolute
    background: #fff
    right: 10px
    top: 10px
    cursor: pointer
    &:hover .superuser-singlemodel__single-delete-img-svg g
      fill: rgba(47,47,47,1)
    &-svg
      position: relative
      left: 4px
      top: 3px
  &-chat
    bottom: 230px
    right: 18px
    position: absolute
    height: 40px
    width: 40px
    & .chat-messenger-block
      top: -415px
      position: relative
      right: 289px
      z-index: 6
      background: #fff
.make-big-photo
  margin-left: 7px
  margin-top: 9px
  display: flex
  &-img
    position: relative
    top: 1px
    width: 20px
    height: 20px
    margin-right: 5px
    background: url(/web/public/img/full-screen.png)
    background-size: 100%
  &:hover .make-big-photo-img
    background: url(/web/public/img/full-screen-light.png)
    background-size: 100%
.disabled
  pointer-events: none
  cursor: default
.approve.disabled, .np-approve.disabled
  cursor: default
  pointer-events: none
.approve.active, .no-approve.active
  g
    fill: rgba(47,47,47,0.3)
.work-with-sites
  display: flex
  justify-content: space-between
  font-family: PFDinTextPro
  font-weight: 400
  color: #30173d
  font-size: 16px
  flex-direction: column
  &__left
    display: flex
    justify-content: space-between
    width: 40%
  &__middle
    display: flex
    flex-direction: column
    width: 26%
    margin-left: 2%
  &__right
    display: flex
    flex-direction: column
    width: 23%
    justify-content: space-between
  &__data
    display: flex
    flex-direction: column
    & .form-group
      width: 328px
      margin-top: 7px
      margin-bottom: -7px
  &__checkbox
    display: flex
    position: relative
    margin-bottom: 19px
    &-label
      margin: 0 9px
  &__download-date
    color: rgba(47, 47, 47, 0.3)
    position: relative
    top: 2px
  &__save
    width: 234px
    position: relative
    top: -25px
    right: -10px
  &__lk
    font-weight: 500
    position: relative
    left: 0px
    text-align: right
    top: -5px
    &-status
      margin-left: 9px
      font-weight: 400
    &-noactive
      margin-left: 28px
    &-activation
      margin-top: 8px
.work-with-site
  border-bottom: 1px solid #e4e4e4
  padding: 40px 40px 14px
  display: flex
  justify-content: space-between
  width: 100%
  &:last-child
    border: none
.fancybox-overlay-fixed
  position: absolue!important
.superuser-singlemodel
  &__top
    display: flex
    justify-content: space-between
    height: 140px
    padding: 30px 0
    & .title p
      font-size: 20px
      font-family: 'Montserrat'
      font-weight: 700
  &__period
    min-width: 300px
    position: relative
    top: 11px
  &__period-date
    display: block
    color: #30173d
    font-size: 16px
    top: -7px
    font-family: 'Montserrat'
    font-weight: 600
    position: relative
  &__period-interval
    position: relative
    width: 110px
    left: -11px
    top: 15px
    label
      margin-top: 27px
  &__period-select
    width: 480px
    justify-content: space-between
    margin-right: 165px
    padding: 18px 20px 13px
    a
      margin-top: 17px
    &-first
      display: flex
      flex-wrap: wrap
      width: 207px
      top: 19px
      position: relative
      & > div
        margin-right: 10px
    &-second
      display: none
      width: 100%
      margin-top: 14px
      & > div
        margin-right: 10px
  &__constructor
    & .btn-witdh-grey-border
      width: 134px
      margin-bottom: 8px
  &__plan
    & .btn-witdh-grey-border
      width: 134px
.year-label, .period-label
  position: initial!important
  top: 0!important
  left: 0!important
  margin: 0!important
  font-size: 12px
  color: rgba(47, 47, 47, 0.5) !important
