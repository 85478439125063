.work-program-index
  .btn-primary
    max-width: 200px
    width: 100%
    margin-bottom: 30px
.work-program-form
  margin-top: 30px
.field-workprogram-description
  margin-top: 19px
  height: 100%!important
  .control-label
    top: -32px
.form-group-btns
  margin-bottom: 30px
  .btn-primary
    max-width: 200px
    width: 100%