.new-models-page .manager-main
  &__table
    td:nth-child(5), th:nth-child(5)
      min-width: 160px
    td:nth-child(6), th:nth-child(6)
      min-width: 122px
    td:nth-child(7), th:nth-child(7)
      min-width: 170px
    td:nth-child(8), th:nth-child(8)
      width: 158px!important

.new-models-page
  min-width: 1013px
  overflow-x: auto
  margin-bottom: 142px
  display: flex
  flex-direction: column
  align-items: center

.new-models-page .submit-new-model-comment-btn
  width: 100%
  margin-top: 5px
  padding-top: 1px
  padding-bottom: 1px
  color: green

.new-models-table-th
  display: flex
  min-height: 100px

.new-models-report-table__content-row
  border-bottom: 1px solid #e4e4e4

.new-models-report-table__sort-wrapper
  display: flex
  flex-direction: column
  justify-content: space-between
  min-height: 70px

.coaching-start-date-delete-btn
  width: 100px
  color: #db046b

.new-models-report-table__title-tr
  display: flex