.confirm-age
  margin-bottom: 20px
  width: 100%
  &__help-block
    margin-left: 34px
    margin-top: 10px
    &-img
      margin-right: 10px
      width: 18px
.field-doctypeform-doc_type .form-label
  position: relative
  top: -46px
  font-size: 12px
  color: rgba(47, 47, 47, 0.5) !important
.doc_type_input_wrap
  position: relative
.doc_type_wrap
  display: flex
  justify-content: flex-start
  flex-wrap: wrap
  align-items: center
  margin-top: -21px
.why-need, .it-safely
  margin-bottom: 10px
  display: flex
  align-items: center
  position: relative
.why-need
  margin-top: 15px
.doc_type_input_wrap a img
  margin-right: 10px
  width: 18px
.confirm-age__help-block
  text-align: start
.doc_type_wrap
  .doc_type_input_wrap
    width: 100%
    .field-modeldata-doc_type
      top: 0
      .jq-selectbox__dropdown
        width: 100%
        left: 0
      .jq-selectbox__select
        top: -10px
  .button_wrap
    top: 355px
    .register-button
      width: 223px
      margin-left: 27px
      margin-top: 19px
.back-wrap__marg
  margin-top: 12px
.country_info
  opacity: 0
  height: 0
.doc-photo
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  position: relative
  top: -94px
  margin-bottom: 27px
  .title
    width: 100%

.single_photo_wrap.js-photo-quantity-four
  width: 138px !important
  height: 205px !important
.fourth_main_single_photo_wrap
  width: 100%
.fourth_single_photo_wrap
  width: 215px !important
  height: 275px !important
  .filebutton_ajax_send_image
    width: 215px !important
    height: 215px
    img
      width: 215px
      height: 215px
.doc-photo .single_photo_wrap
  &.js-photo-quantity-four img
    width: 138px
    height: 138px
  position: relative
  width: 138px
  height: 218px
  margin-top: 8px
.doc-photo .single_photo_wrap.js-photo-quantity-four
  .filebutton_ajax_send_image img, .filebutton_ajax_send_image
    min-width: 138px
    height: 138px
.doc-photo
  .photo_desk, img
    left: 0
  .jq-file
    width: 70px
    border-radius: 4px
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
    opacity: 0
    height: 19px
    z-index: 2
  .single_photo_wrap a
    display: block
  .form-group
    top: 0
    height: 20px !important
  .download-photo:before
    left: 0
#modal_show_photo
  position: fixed
  top: 10%
  left: 50%
  margin-left: -276px
.button_wrap
  &.js-photo-quantity-four
    top: 285px
  &.js-photo-quantity-pair-six
    top: 695px
  &.js-photo-quantity-pair-eight
    top: 553px
.register-button
  margin-top: 10px
.field-doctypeform-doc_type .form-control li:nth-child(4)
  display: block
.fourthtab .modal-snapshot
  position: fixed
  top: 15%
  left: 50%
  z-index: 5
  height: 600px
  width: 700px
  margin: 0
  margin-left: -350px !important
  display: none
.fourthtab .modal-snapshot p
  width: 95%
#webcam-photo
  heigth: 491.25px
  width: 655px
.user-wrap
  flex-wrap: wrap
  align-items: unset
  height: auto
.doc-photo .single_photo_wrap
  margin-top: 32px
.doc-photo .single_main_photo_wrap
  margin-top: 0

.additionalPhotoFirstModel
  display: none
