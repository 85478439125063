.modal-box,
.modal-box-add-stream,
.modal-box-add-for-registrar,
.modal-box-add-for-quality-control,
.modal-box-computer-assistance,
.modal-box-coaching-assign
  position: fixed
  min-height: 160px
  background-color: white
  -webkit-box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  box-shadow: 0 30px 100px 0 rgba(0, 0, 0, 0.7)
  z-index: 20
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

  .modal-content
    padding: 35px 20px 0px 20px
    border-radius: 0px
    height: 100%
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    display: -webkit-flex
    text-align: left
    align-items: center
    justify-content: center
    flex-wrap: wrap

    .modal-label
      font-family: Montserrat
      font-weight: 400
      font-size: 20px
      width: 100%
      text-align: center
      padding: 6px 3px
      letter-spacing: 0px
      position: relative
      left: -3px
      line-height: 25px

    .btn-modal
      -ms-flex-item-align: center
      align-self: center
      width: 92px
      margin-bottom: 37px
      font-family: 'PFDinTextPro'
      font-weight: 700

    .cross
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      position: absolute
      -webkit-box-pack: center
      -ms-flex-pack: center
      justify-content: center
      top: 20px
      right: 20px
      width: 16px
      height: 16px
      opacity: 0.3
      cursor: pointer

      &:hover
        opacity: 1

      &:before, &:after
        position: absolute
        content: ' '
        height: 16px
        width: 2px
        background-color: #333

      &:before
        -webkit-transform: rotate(45deg)
        transform: rotate(45deg)

      &:after
        -webkit-transform: rotate(-45deg)
        transform: rotate(-45deg)

.modal-big-text
  height: 80%
  overflow-y: scroll
  padding: 0 32px 0 17px !important
  font-size: 16px
  font-family: PFDinTextPro
  font-weight: 400
  text-align: justify

.modal-title
  text-align: left
  font-weight: 600
  display: block
  margin: 0
