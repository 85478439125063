/*input

.form-control
  width: 100%
  border: none
  border-bottom: 1px solid #30173d
  box-shadow: none
  color: #30173d
  border-radius: 0px
  position: relative
  outline: none
  padding: 0
  font-family: 'PFDinTextPro'
  font-size: 16px
  font-weight: 400
  &.active-label + label
    top: -46px
    font-size: 13px
    color: rgba(47, 47, 47, 0.5)

.form-label
  position: absolute
  top: 0
  left: 15px
  font-family: 'PFDinTextPro'
  font-weight: 400

.form-control
  &:focus
    box-shadow: none !important
    border-bottom: 2px solid #db046b
    border-color: #db046b
    outline: none
  &:hover ~ .form-label
    color: #30173d
  &:focus ~ .form-label, &:valid ~ label
    top: -21px
    font-size: 13px
    color: rgba(47, 47, 47, 0.5)
  &[readonly]
    color: rgba(47, 47, 47, 0.3)
    border-bottom: 1px solid #e4e4e4 !important
    cursor: default
    background: transparent
    &:focus
      border-bottom: 1px solid #e4e4e4
.form-group
  height: 100px!important
  margin-bottom: 0
  margin-top: 10px
  position: relative
.has-error
  .help-block
    color: #db046b
    margin-top: 2px
    font-size: 13px
  .form-control:focus
    border-color: #db046b

.active_label
  font-size: 13px
  top: -46px
.has-success .form-control
  border: none
  border-bottom: 1px solid #30173d
  box-shadow: none
  background-color: #fff !important

.has-error .form-control
  box-shadow: none
label
  color: rgba(47, 47, 47, 0.5)
  font-size: 16px
  font-weight: 400
  position: absolute
  pointer-events: none
  top: -25px
  transition: 0.2s ease all
  -moz-transition: 0.2s ease all
  -webkit-transition: 0.2s ease all
  margin-left: -15px
input:-webkit-autofill
  -webkit-text-fill-color: #ссс
  -webkit-box-shadow: 0 0 0px 1000px transparent inset
  transition: background-color 5000s ease-in-out 0s
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%)
  &:hover, &:focus input:-webkit-autofill
    -webkit-text-fill-color: #ссс
    -webkit-box-shadow: 0 0 0px 1000px transparent inset
    transition: background-color 5000s ease-in-out 0s
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%)
.style-label
  input + label
    color: rgba(47,47,47,.5)
    font-size: 16px!important
    font-weight: 400
    position: absolute
    pointer-events: none
    top: 7px!important
    transition: .2s ease all!important
    -moz-transition: .2s ease all!important
    -webkit-transition: .2s ease all!important
    margin-left: 0!important
  input:focus + label, input.active-label + label
    top: -13px!important
    font-size: 13px!important
textarea:-webkit-autofill
  -webkit-text-fill-color: #ссс
  -webkit-box-shadow: 0 0 0px 1000px transparent inset
  transition: background-color 5000s ease-in-out 0s
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%)
  &:hover textarea:-webkit-autofill:focus
    -webkit-text-fill-color: #ссс
    -webkit-box-shadow: 0 0 0px 1000px transparent inset
    transition: background-color 5000s ease-in-out 0s
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%)

select:-webkit-autofill
  -webkit-text-fill-color: #ссс
  -webkit-box-shadow: 0 0 0px 1000px transparent inset
  transition: background-color 5000s ease-in-out 0s
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%)
  &:hover, &:focus
    -webkit-text-fill-color: #ссс
    -webkit-box-shadow: 0 0 0px 1000px transparent inset
    transition: background-color 5000s ease-in-out 0s
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%)

/*select

.jq-selectbox
  vertical-align: middle
  cursor: pointer
  height: 23px

.jq-selectbox__select
  height: 23px
  padding: 0 45px 0 0px
  font: 16px 'PFDinTextPro', sans-serif
  color: #30173d
  font-weight: 400

.jq-selectbox
  &.focused .jq-selectbox__select
    border: 1px solid transparent
  &.disabled .jq-selectbox__select
    box-shadow: none
    color: #888

.jq-selectbox__select-text
  display: block
  width: 100%

.jq-selectbox .placeholder
  color: #888

.jq-selectbox__trigger
  position: absolute
  top: -9px
  right: -3px
  width: 34px
  height: 100%

.jq-selectbox__trigger-arrow
  position: absolute
  top: 6px
  right: 0
  width: 24px
  height: 24px
  background: url(../img/arrow-down.svg) no-repeat
  background-size: cover

.jq-selectbox
  &:hover .jq-selectbox__trigger-arrow
    border-top-color: #000
  &.disabled .jq-selectbox__trigger-arrow
    border-top-color: #AAA

.jq-selectbox__dropdown
  box-sizing: border-box
  width: 100%
  margin: 2px 0 0
  padding: 0
  background: #FFF
  font: 16px 'PFDinTextPro', sans-serif
  font-weight: 400
  color: #30173d
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)

.field-firsttabform-country .form-label, .field-firsttabform-how_know .form-label
  position: relative
  top: -46px
  font-size: 12px
  color: rgba(47, 47, 47, 0.5) !important

.jq-selectbox
  border-bottom: 1px solid #30173d !important
  &.dropdown, &.dropup
    border-bottom: 2px solid #db046b !important

.jq-selectbox__search
  margin: 5px

.jq-selectbox
  &.dropdown .jq-selectbox__trigger-arrow, &.dropup .jq-selectbox__trigger-arrow
    transform: rotate(180deg)
    background: url(../img/arrow-down-dark.svg) no-repeat
    background-size: cover

.jq-selectbox__search input
  box-sizing: border-box
  width: 100%
  margin: 0
  padding: 5px 27px 6px 8px
  border: 1px solid #CCC
  border-radius: 3px
  outline: none
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==') no-repeat 100% 50%
  box-shadow: inset 1px 1px #F1F1F1
  color: #333

.jq-selectbox__not-found
  margin: 5px
  padding: 5px 8px 6px
  background: #F0F0F0
  font-size: 13px

.jq-selectbox
  ul
    margin: 0
    padding: 0
  li
    min-height: 18px
    padding: 8px 10px 9px
    color: #231F20
    &.selected, &:hover
      background-color: #e4e4e4
      color: #30173d
    &.disabled
      color: #AAA
      &:hover
        background: none
    &.optgroup
      font-weight: 700
      &:hover
        background: none
        color: #231F20
        cursor: default
    &.option
      padding-left: 25px

/*textarea

textarea.form-control
  border: solid 1px #30173d
  color: #30173d
  border-radius: 5px
  font-size: 16px
  font-family: PFDinTextPro
  font-weight: 400
  height: 105px !important
  padding: 35px 0 0 17px
  line-height: 1em
  &:focus
    border: 2px solid #db046b
.textarea-wrap
  height: 105px !important

.has-error
  textarea.form-control
    border: solid 2px #30173d
  .form-label
    color: #db046b !important
.has-success textarea.form-control
  border: solid 1px #30173d!important
  &:focus
    border: 2px solid #db046b!important
/*text

.title
  font-family: "Montserrat"
  font-weight: 600
  font-size: 16px
  position: relative
  display: block
.textare-label
  left: 16px

.style-label input.not-empty-input + label
  top: -15px!important
  font-size: 13px!important
  color: rgba(47,47,47,.5)!important
  font-family: PFDinTextPro

@-moz-document url-prefix()
  .form-group
    height: 72px!important
@supports (-ms-ime-align:auto)
  .form-group
    height: 72px!important
