@font-face
  font-family: 'Druk Wide Super'
  src: url("../fonts/Druk/Druk Wide-Super-Web.eot")
  src: url("../fonts/Druk/Druk Wide-Super-Web.eot#iefix") format("embedded-opentype"), url("../fonts/Druk/Druk Wide-Super-Web.woff2") format("woff2"), url("../fonts/Druk/Druk Wide-Super-Web.woff") format("woff"), url("Druk Wide-Super-Web.ttf") format("truetype")
  font-weight: normal
  font-style: normal


@font-face
  font-family: 'PFDinTextCondPro'
  src: url("../fonts/PFDinTextCondPro/PFDinTextCondPro-Regular.ttf") format("truetype")
  font-weight: 400
  font-style: normal


@font-face
  font-family: 'PFDinTextCompPro'
  src: url("../fonts/PFDinTextCompPro/PFDinTextCompPro-Medium.ttf")
  src: url("../fonts/PFDinTextCompPro/PFDinTextCompPro-Medium.ttf") format("truetype"), url("../fonts/PFDinTextCompPro/PFDinTextCompPro-Medium.otf") format("opentype"), url("../fonts/PFDinTextCompPro/PFDinTextCompPro-Medium.woff") format("woff")
  font-weight: 500
  font-style: normal


/* imports

@font-face
  font-family: 'Druk Wide Super'
  src: url("../fonts/Druk/DrukWide-Super-Web.eot")
  src: url("../fonts/Druk/DrukWide-Super-Web.eot#iefix") format("embedded-opentype"), url("../fonts/Druk/Druk Wide-Super-Web.woff2") format("woff2"), url("../fonts/Druk/Druk Wide-Super-Web.woff") format("woff"), url("Druk Wide-Super-Web.ttf") format("truetype")
  font-weight: normal
  font-style: normal


@font-face
  font-family: 'PFDinTextPro'
  src: url("../fonts/PFDinTextPro/PFDinTextPro-Light.ttf")
  src: url("../fonts/PFDinTextPro/PFDinTextPro-Light.woff") format("woff")
  font-weight: 300
  font-style: normal


@font-face
  font-family: 'pfdintextproarial'
  src: url("../fonts/PFDinTextPro/PFDinTextPro-Light.ttf")
  src: url("../fonts/PFDinTextPro/PFDinTextPro-Light.woff") format("woff")
  font-weight: 300
  font-style: normal

@font-face
  font-family: 'Montserrat'
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype")
  font-weight: 400
  font-style: normal


@font-face
  font-family: 'PFDinTextPro'
  src: url("../fonts/PFDinTextPro/PFDinTextPro-Regular.ttf")
  src: url("../fonts/PFDinTextPro/PFDinTextPro-Regular.otf") format("truetype")
  font-weight: 400
  font-style: normal


@font-face
  font-family: 'PFDinTextPro'
  src: url("../fonts/PFDinTextPro/PFDinTextPro-Bold.ttf")
  src: url("../fonts/PFDinTextPro/PFDinTextPro-Bold.woff") format("woff")
  font-weight: 700
  font-style: normal


@font-face
  font-family: 'PFDinTextPro'
  src: url("../fonts/PFDinTextPro/PFDinTextPro-Medium.ttf")
  font-weight: 500
  font-style: normal


@font-face
  font-family: 'Montserrat'
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype")
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Montserrat'
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype")
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Montserrat'
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype")
  font-weight: 600
  font-style: normal


@font-face
  font-family: 'Montserrat'
  src: url('../fonts/Montserrat/Montserrat-Bold.ttf') format("truetype")
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'LucidaGrande'
  src: url('../fonts/LucidaGrandeBold.ttf') format("truetype")
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'lucidagrandearial'
  src: url('../fonts/LucidaGrandeBold.ttf') format("truetype")
  font-weight: 700
  font-style: normal