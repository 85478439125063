.coaching-page .container
  width: 100%
  margin: 0 10px

.coaching-table .submitCommentBtn
  width: 100%
  margin-top: 5px
  padding-top: 1px
  padding-bottom: 1px
  color: green

.coaching-table.manager-main__table tr th:first-child
  min-width: 216px

.coaching-table.manager-main
  &__table
    td
      width: 100px
      min-width: 130px
      font-weight: 700
    th
      width: 100px
      min-width: 130px
      padding: 3px 3px
    td:nth-child(3), th:nth-child(3)
      min-width: 102px
    td:nth-child(4), th:nth-child(4)
      min-width: 104px
    td:nth-child(5), th:nth-child(5)
      min-width: 132px
    td:nth-child(6), th:nth-child(6)
      min-width: 146px
    td:nth-child(7), th:nth-child(7)
      min-width: 120px
    td:nth-child(8), th:nth-child(8)
      width: 146px !important
    td:nth-child(9), th:nth-child(9)
      width: 117px !important
      min-width: 117px
    td:nth-child(10), th:nth-child(10)
      width: 112px !important
      min-width: 112px
    td:nth-child(13), th:nth-child(13)
      min-width: 130px
    td:nth-child(11), th:nth-child(11)
      min-width: 170px

/* Стили для фильтра периодов в coaching */
.superuser-sites__period-select
  border: solid 1px #e4e4e4
  border-radius: 5px
  padding: 13px 20px
  font-family: PFDinTextPro
  font-size: 16px
  font-weight: 400
  color: #30173d
  margin-right: 30px
  position: relative
  height: 81px
  margin-top: -3px
.superuser-sites__period
  &-select
    display: flex
    width: 360px
    padding: 19px 20px 13px
  & .title
    font-weight: 700
    margin-bottom: 14px
.superuser-sites__period-select .jq-selectbox__select
  padding: 0 18px 0 0
.superuser-sites__period-select-date li, .superuser-sites__period-select-date-second li
  &:nth-child(4)
    display: block
  &:nth-child(5)
    display: none
.superuser-sites__period-select-year
  width: 60px
.superuser-sites__period-select-date, .second-period
  width: 120px

.show-frozen-models
  margin-left: 20px
  width: 200px