.worksheet
    padding-bottom: 47px !important
    margin-right: 72px !important
    .lbl-head
        letter-spacing: -3px !important
        padding-bottom: 26px !important
    .model-data-wrap__first
        width: 100% !important
    .model-data-wrap__second
        width: 100%
        margin-left: 6%
    .form-group
        height: 71px !important
        display: flex
        flex-direction: column
        justify-content: center
    .field-questionnaireform-fm_hair_color, .field-questionnaireform-fm_hair_length, .field-questionnaireform-fm_eye_color,
    .field-questionnaireform-sm_hair_color, .field-questionnaireform-sm_hair_length, .field-questionnaireform-sm_eye_color
        padding-top: 11px
    .jq-selectbox__select-text
        margin-top: -3px
    .jq-selectbox__trigger-arrow
        margin-top: 3px
        margin-right: 2px
    .title
        font-size: 16px
        font-family: 'Montserrat'
        font-weight: 600
        padding-bottom: 5px
        margin-top: 9px
    .selector-label
        position: absolute
        top: -15px
        left: 30px
        font-size: 13px
        font-family: 'PFDinTextPro'
    .figure-type-wrap
        margin-top: 27px
        &__radios
            display: flex
            justify-content: space-between
            flex-wrap: wrap
            &-second
                justify-content: space-between
        .jq-radio
            width: 96px
            height: 180px
            background: transparent
            border-radius: 5px
            margin-bottom: 10px
            &.checked
                .jq-radio__div
                    display: none
    .language-info-wrap
        .lng-lvl-hide
            display: none
        .title
            margin: 0 !important
            padding: 0 !important
        .row-select, .lng-container__row
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            min-height:61px !important
            box-sizing: border-box
            margin-top: 10px
            width: 442px
            margin-bottom: 5px
        .lng-container
            margin-top: 30px
            .lng-container__row
                position: relative
            .delete-lng
                top: 3px
                position: absolute
                right: -37px
                cursor: pointer
                svg:hover g
                    fill: #2f2f2f
        .form-control
            width: 442px
        .block-select-lng, .block-select-lng__selector, .block-select-lng__dropdown
            width: 223px !important
        .block-select-lng-lvl, .block-select-lng-lvl__selector, .block-select-lng-lvl__dropdown
            width: 189px !important
        .col-lg-12, .jq-selectbox
            height: 26px !important
        .form-group
            height: 71px !important
        .block-select
            &__selector
                border-bottom: 1px solid #2f2f2f
                color: #30173d
                font-family: 'PFDinTextPro'
                font-weight: 400
                font-size: 16px
                display: flex
                align-items: center
                cursor: pointer
                justify-content: space-between
                margin-left: 3px
                margin-top: 5px
            &__selector.active
                border-bottom: 2px solid #db046b
            &__selector.active svg
                transform: rotate(180deg)
            &__dropdown
                background: #fff
                box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)
                color: #30173d
                font-size: 16px
                font-family: 'PFDinTextPro'
                font-weight: 400
                z-index: 6
                width: 445px
                position: absolute
            &__menu
                list-style: none
                padding-left: 0
            &__menu-item
                padding: 6px 10px
                cursor: pointer
                transition: 0.3s
                &:hover
                    background: #e4e4e4
            transition: 0.3s
    .language-info-wrap
        margin-top: 31px
    .span-gray
        color: #2f2f2f
        opacity: 0.5
    .last-info-wrap
        margin-top: 27px
        label
            top: -93px !important
            left: 16px
        .form-group
            height: 125px !important
    .nick_wrap
        margin-top: 12px
        box-sizing: border-box
        svg
            height: 18px
            width: 18px
            display: inline-block

    .nick_wrap .title-wrap
        p
            padding: 0
            margin: 0
            margin-right: 5px
        display: flex
        align-items: center
        margin-bottom: 5px !important
    .help-block-example
        top: 3px
        margin-left: 5px
        .popup-help
            left: 35px
    .last-info-wrap
        .form-group
                textarea
                    margin: -30px 0 0
        p
            padding-bottom: 11px
        .last-info-wrap, .language-info-wrap
            margin-top: 31px
    .btn-primary
        width: 100%
        margin-top: -20px
        margin-bottom: 5px
    .dop-photo-title
        display: flex
        align-items: center
        margin-bottom: 10px
        .title
            margin: 0
            padding: 0
            padding-right: 5px

#radiousual-styler
    background: url(../img/figure2.jpg)
    overflow: visible
    &:hover:before
        display: block
    &:before
        position: absolute
        content: 'Обычная'
        display: none
        top: 185px
        width: 92px
        height: 35px
        text-align: center
        line-height: 30px
        font-size: 16px
        font-family: Montserrat
        font-weight: 600
        color: #db046b
        border: #db046b 3px solid
        border-radius: 5px
#radiosport-styler
    background: url(../img/figure3.jpg)
    overflow: visible
    &:hover:before
        display: block
    &:before
        position: absolute
        content: 'Спортивная'
        display: none
        top: 185px
        height: 35px
        text-align: center
        line-height: 30px
        font-size: 16px
        font-family: Montserrat
        font-weight: 600
        color: #db046b
        border: #db046b 3px solid
        border-radius: 5px

#radiofat-styler
    background: url(../img/figure4.jpg)
    overflow: visible
    &:hover:before
        display: block
    &:before
        position: absolute
        content: 'В теле'
        display: none
        top: 185px
        width: 92px
        height: 35px
        text-align: center
        line-height: 30px
        border: #db046b 3px solid
        border-radius: 5px
        font-size: 16px
        font-family: Montserrat
        font-weight: 600
        color: #db046b
#radiothin-styler
    background: url(../img/figure1.jpg)
    overflow: visible
    &:hover:before
        display: block
    &:before
        position: absolute
        content: 'Стройная'
        font-size: 16px
        font-family: Montserrat
        font-weight: 600
        color: #db046b
        display: none
        top: 185px
        width: 92px
        height: 35px
        text-align: center
        line-height: 30px
        border: #db046b 3px solid
        border-radius: 5px
